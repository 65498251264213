import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageLayout from 'components/PageLayout';
import Content from './component/index';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5.5em',
      padding: '1.5em',
    },
  },
}));

const PrivacyPolicy = () => {
  const styles = useStyles();
  return (
    <PageLayout>
      <Grid container className={styles.root}>
        <Content />
      </Grid>
    </PageLayout>
  );
};

export default PrivacyPolicy;
