import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataCollectionBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        4. Bagaimana Data Peribadi anda dikumpul
      </Typography>
      <Typography paragraph className={styles.content}>
        Sanofi boleh mendapatkan Data Peribadi dari maklumat yang anda bekalkan
        atau dibenarkan untuk dikumpul untuk tujuan pendaftaran di Laman web ini
        dan penyertaan anda dalam Program dan / atau dari komunikasi anda dengan
        kami.
      </Typography>
      <Typography paragraph className={styles.content}>
        Laman web ini mengandungi menu Bantuan ‘Support’ yang membolehkan anda
        mengemukakan pertanyaan, meminta bimbingan teknikal, atau memberikan
        komen melalui e-mel. Peti mel ini akan dikendalikan oleh mClinica, iaitu
        pihak yang akan menjawab soalan anda dan meneliti komen anda.
      </Typography>
      <Typography paragraph className={styles.content}>
        Selain daripada Data Peribadi yang dibekalkan oleh anda atau dibenarkan
        untuk pengumpulan seperti yang dinyatakan dalam Laman web ini, Sanofi
        juga akan Memproses Data Peribadi anda dari pelbagai sumber termasuk:
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ul>
          <li className={styles.list}>
            Data yang dikumpulkan daripada interaksi anda dengan Laman dan
            perkhidmatan melalui teknologi tertentu, seperti ‘cookies’.
          </li>
          <li className={styles.list}>
            Data dari sumber umum yang tersedia boleh dikumpul mengikuti
            Undang-Undang yang Berkenaan
          </li>
          <li className={styles.list}>
            Data yang diperoleh secara sah dari pihak ketiga yang diberi kuasa
            untuk Memproses Data Peribadi anda sebagaimana yang diizinkan oleh
            Anda atau mengikuti Undang-Undang yang Berkenaan
          </li>
        </ul>
      </Typography>
    </Grid>
  );
};

export default DataCollectionBlockBahasa;
