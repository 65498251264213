import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessage } from 'selectors/resources';
import { removeMessage } from 'store/resourcesSlice';

const AlertDialog = ({ open }) => {
  const dispatch = useDispatch();
  const message = useSelector(selectMessage);
  const handleClose = () => {
    dispatch(removeMessage());
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">In-Control</DialogTitle>
        <DialogContent style={{ minWidth: '16em' }}>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK, Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default AlertDialog;
