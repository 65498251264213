import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import menu1 from 'assets/png/about-menu-1.png';
import menu2 from 'assets/png/about-menu-2.png';
import menu3 from 'assets/png/about-menu-3.png';
import menu4 from 'assets/png/about-menu-4.png';
import background from 'assets/png/stethoscope.png';
import MenuItem from './MenuItem';
import PopOver from './PopOver';

const useStyles = makeStyles(() => ({
  selectionContainer: {
    paddingLeft: '1em',
    paddingRight: '1em',
    maxWidth: '80em',
    marginTop: '4em',
  },
  backgroundContainer: {
    position: 'absolute',
    width: '100vw',
  },
  backgroundImage: {
    paddingTop: '0.25em',
    height: '30em',
    width: 'auto',
  },
}));

const Content = () => {
  const styles = useStyles();
  const [popoverState, setShowPopover] = useState(false);
  const [menuItem, setMenuItem] = useState(0);

  const menuClickHandler = (menuItem) => {
    setMenuItem(menuItem);
    setShowPopover(true);
  };
  return (
    <React.Fragment>
      <Grid container className={styles.backgroundContainer}>
        <Grid container justify="center">
          <Grid item>
            <img src={background} alt="" className={styles.backgroundImage} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.selectionContainer} justify="center">
        <Grid item>
          <MenuItem
            text={
              <span>
                <p>
                  WHAT IS
                  <br />
                  IN-CONTROL?
                </p>
              </span>
            }
            img={menu1}
            clickHandler={() => {
              menuClickHandler(1);
            }}
          />
        </Grid>
        <Grid item>
          <MenuItem
            text={
              <span>
                <p>
                  WHO IS
                  <br />
                  ELIGIBLE?
                </p>
              </span>
            }
            img={menu2}
            rtl
            clickHandler={() => {
              menuClickHandler(2);
            }}
          />
        </Grid>
        <Grid item>
          <MenuItem
            text={
              <span>
                <p>
                  WHAT ARE
                  <br />
                  PATIENTS ENTITLED*?
                </p>
              </span>
            }
            img={menu3}
            bottom
            clickHandler={() => {
              menuClickHandler(3);
            }}
          />
        </Grid>
        <Grid item>
          <MenuItem
            text={
              <span>
                <p>
                  HOW CAN PATIENTS
                  <br />
                  STAY ACTIVE IN
                  <br />
                  THE PROGRAM?
                </p>
              </span>
            }
            img={menu4}
            rtl
            clickHandler={() => {
              menuClickHandler(4);
            }}
          />
        </Grid>
      </Grid>
      <PopOver
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        menuItem={menuItem}
      />
    </React.Fragment>
  );
};

export default Content;
