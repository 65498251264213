import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import { generateValidationSchema } from 'common/helpers';
import * as Yup from 'yup';

const selectResourcesDomain = (state) => state.resources;

export const selectHCPFields = createSelector(
  selectResourcesDomain,
  (state) => {
    const config = get(state, 'config', {});

    if (!isEmpty(config)) {
      const userType = config.users.userTypes.find(
        (type) => type.userType === 'physician'
      );
      return get(userType, 'userFieldConfigurations', []);
    }

    return [];
  }
);

export const selectPatientFields = createSelector(
  selectResourcesDomain,
  (state) => {
    const config = get(state, 'config', {});

    if (!isEmpty(config)) {
      const userType = config.users.userTypes.find(
        (type) => type.userType === 'patient'
      );
      const field = userType.userFieldConfigurations.filter((config) => {
        return config.metadata.preregisterField;
      });
      return field;
    }

    return [];
  }
);

export const selectPreRegistrationField = (fieldName) =>
  createSelector(selectResourcesDomain, (state) => {
    const config = get(state, 'config', {});

    if (!isEmpty(config)) {
      const userType = config.users.userTypes.find(
        (type) => type.userType === 'patient'
      );
      const field = userType.userFieldConfigurations.filter((config) => {
        return config.metadata.preregisterField && config.name === fieldName;
      });
      return field[0];
    }
    return [];
  });

export const selectHCPFieldValidationSchema = createSelector(
  selectHCPFields,
  (state) => {
    return generateValidationSchema(state, {
      agreementCheckTermsAndCondition: Yup.bool().oneOf([true], ' '),
      agreementCheckPrivacyNotice: Yup.bool().oneOf([true], ' '),
      agreementCheckPhysicianConsent: Yup.bool().oneOf([true], ' '),
    });
  }
);

export const selectPatientFieldValidationSchema = createSelector(
  selectPatientFields,
  (state) => {
    return generateValidationSchema(state, {});
  }
);
