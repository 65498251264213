import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
    minWidth: '28em',
    margin: '1.25em',
  },
  menu: {
    background: 'linear-gradient(270deg, #EE3C82 0%, #6F2B90 100%)',
    marginTop: '0.25em',
    marginBottom: '0.25em',
    height: '8em',
  },
  image: {
    height: '5em',
    width: 'auto',
  },
  text: {
    fontSize: '1.1rem',
    color: 'white',
    fontWeight: 700,
  },
  textAlignNormal: {
    textAlign: 'start',
  },
  textAlignRtl: {
    textAlign: 'end',
  },
}));

const MenuItem = ({ text, rtl, img, bottom, clickHandler }) => {
  const styles = useStyles();
  return (
    <Button className={styles.container} disableRipple onClick={clickHandler}>
      <Grid
        container
        direction={rtl ? 'row-reverse' : 'row'}
        className={styles.menu}
      >
        <Grid
          item
          container
          xs={5}
          sm={5}
          justify="center"
          alignItems={bottom ? 'flex-end' : 'center'}
        >
          <img src={img} alt="" className={styles.image} />
        </Grid>
        <Grid
          container
          item
          xs={7}
          sm={7}
          justify={rtl ? 'flex-end' : undefined}
          alignItems="center"
        >
          <Typography
            className={[
              styles.text,
              rtl ? styles.textAlignRtl : styles.textAlignNormal,
            ].join(' ')}
            component="span"
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

MenuItem.defaultProps = {
  rtl: false,
  bottom: false,
};

MenuItem.propTypes = {
  text: PropTypes.node.isRequired,
  img: PropTypes.string.isRequired,
  rtl: PropTypes.bool,
  bottom: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
};
export default MenuItem;
