import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const ThirdPartyBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        11. Third Party Sites and Services
      </Typography>
      <Typography paragraph className={styles.content}>
        The Site may contain links to websites, other apps and other online
        services operated by third parties that are not under our control. We
        are not responsible for the collection, use, and disclosure of your
        Personal Data on those websites and other online services by those third
        parties. We encourage you to review the privacy policies of each website
        and other online services you visit.
      </Typography>
    </Grid>
  );
};

export default ThirdPartyBlock;
