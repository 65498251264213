import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formatErrorMessage } from 'common/helpers';
import { setMessage } from './resourcesSlice';

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (formikFields, { extra, dispatch, rejectWithValue }) => {
    const { connectClient, endpoints } = extra;
    const payload = {
      ...formikFields,
      userType: 'physician',
    };
    try {
      const { data } = await connectClient.post(
        endpoints.USER_REGISTRATION,
        payload
      );
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

export const getProfile = createAsyncThunk(
  'user/getProfile',
  async (_, { extra, rejectWithValue, dispatch }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.get(endpoints.GET_USER);
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  async (payload, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.post(
        endpoints.UPDATE_PASSWORD,
        payload
      );
      dispatch(setMessage(data.message));
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

export const verifyUser = createAsyncThunk(
  'user/verifyUser',
  async (payload, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.post(
        endpoints.EMAIL_VERIFICATION,
        payload
      );
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

export const resendVerification = createAsyncThunk(
  'user/resendVerification',
  async (_, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.post(
        endpoints.RESEND_EMAIL_VERIFICATION
      );
      dispatch(setMessage(data.message));
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: {},
    pending: false,
  },
  reducers: {},
  extraReducers: {
    [registerUser.fulfilled]: (state, { payload }) => {
      state.profile = payload.result;
      state.pending = false;
    },
    [registerUser.pending]: (state) => {
      state.pending = true;
    },
    [registerUser.rejected]: (state) => {
      state.pending = false;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.profile = payload.result;
      state.pending = false;
    },
    [getProfile.pending]: (state) => {
      state.pending = true;
    },
    [getProfile.rejected]: (state) => {
      state.pending = false;
    },
    [updatePassword.fulfilled]: (state) => {
      state.pending = false;
    },
    [updatePassword.pending]: (state) => {
      state.pending = true;
    },
    [updatePassword.rejected]: (state) => {
      state.pending = false;
    },
    [verifyUser.fulfilled]: (state) => {
      state.pending = false;
    },
    [verifyUser.pending]: (state) => {
      state.pending = true;
    },
    [verifyUser.rejected]: (state) => {
      state.pending = false;
    },
    [resendVerification.fulfilled]: (state) => {
      state.pending = false;
    },
    [resendVerification.pending]: (state) => {
      state.pending = true;
    },
    [resendVerification.rejected]: (state) => {
      state.pending = false;
    },
  },
});

export default userSlice.reducer;
