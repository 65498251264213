import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Drawer, Hidden, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles, useTheme } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles';
import AddPatient from 'components/AddPatient';
import SearchBar from 'components/SearchBar';
import ElevationScroll from 'components/ElevationScroll';
import StatusFilter from 'components/StatusFilter';
import DrawerContent from './components/DrawerContent';

export const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerContainer: {
    backgroundColor: '#AB4494',
    width: '100%',
    height: '100vh',
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    color: 'white',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '5em',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 'auto',
    width: 300,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const HomeDrawer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  const styles = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className={styles.drawerContainer}>
      <DrawerContent
        setMobileOpen={setMobileOpen}
        value={value}
        setValue={setValue}
      />
    </div>
  );

  return (
    <div>
      <ElevationScroll>
        <AppBar position="fixed" color="primary" className={styles.appBar}>
          <Hidden lgUp>
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={styles.menuButton}
              >
                <MenuIcon />
              </IconButton>
              {isHomePage ? (
                <React.Fragment>
                  <SearchBar />
                  <Hidden xsDown>
                    <StatusFilter noElevation />
                  </Hidden>
                  <Hidden smUp>
                    <AddPatient />
                  </Hidden>
                </React.Fragment>
              ) : null}
            </Toolbar>
          </Hidden>
        </AppBar>
      </ElevationScroll>
      <nav className={styles.drawer}>
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: styles.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: styles.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default HomeDrawer;
