import cookieStoragePlugin from 'cookie-storage-v2';
import { ConsoleLogObserver, AuthService } from 'ionic-appauth';
import { CapacitorBrowser } from 'ionic-appauth/lib/capacitor';

import { endpoints } from 'common/constants';
import { isNull } from 'lodash';
import { AxiosRequestor } from './axios.requestor';

const TOKEN_KEY = {
  ACCESS_TOKEN: `hcp-dashboard-${process.env.REACT_APP_CONNECT_PROGRAM_ID}-access-token`,
  REFRESH_TOKEN: `hcp-dashboard-${process.env.REACT_APP_CONNECT_PROGRAM_ID}-refresh-token`,
};

const storage = {
  removeItem(key) {
    return cookieStoragePlugin.removeItem(key);
  },
  getItem(key) {
    const value = cookieStoragePlugin.getItem(key);
    if (!value) return null;
    try {
      const parsed = JSON.parse(JSON.stringify(value));
      return typeof parsed === 'string' ? value : JSON.stringify(value);
    } catch (e) {
      return value;
    }
  },
  setItem(key, value) {
    cookieStoragePlugin.setItem(key, value, {
      expires: {
        months: 1,
      },
      secure: true,
    });
  },
  async clear() {
    cookieStoragePlugin.clear();
  },
};
export class Auth {
  static buildAuthInstance() {
    const authService = new AuthService(
      new CapacitorBrowser(),
      storage,
      new AxiosRequestor()
    );

    authService.authConfig = {
      client_id: process.env.REACT_APP_CONNECT_KEYCLOAK_CLIENT_ID,
      server_host: endpoints.KEYCLOAK_URI,
      redirect_url: `${window.location.origin}/login-redirect`,
      end_session_redirect_url: `${window.location.origin}/end-redirect`,
      scopes: ['offline_access'],
      pkce: true,
    };

    authService.addActionObserver(new ConsoleLogObserver());
    return authService;
  }

  static get instance() {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
    }

    return this.authService;
  }

  static get isAuthenticated() {
    return Auth.instance.storage.isAuthenticated || !isNull(this.accessToken);
  }

  static get accessToken() {
    return storage.getItem(TOKEN_KEY.ACCESS_TOKEN);
  }

  static get refreshToken() {
    return storage.getItem(TOKEN_KEY.REFRESH_TOKEN);
  }

  static storeAccessToken(token) {
    storage.setItem(TOKEN_KEY.ACCESS_TOKEN, token);
  }

  static storeRefreshToken(token) {
    storage.setItem(TOKEN_KEY.REFRESH_TOKEN, token);
  }

  static clearStorage() {
    storage.removeItem(TOKEN_KEY.ACCESS_TOKEN);
    storage.removeItem(TOKEN_KEY.REFRESH_TOKEN);
  }
}
