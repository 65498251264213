import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataCollectionBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        4. How will your Personal Data be collected
      </Typography>
      <Typography paragraph className={styles.content}>
        Sanofi may obtain the Personal Data from the information you have
        provided or allowed to be collected for registration in this Site and
        your participation in the Program and/or from your communication with
        us.
      </Typography>
      <Typography paragraph className={styles.content}>
        The Site contains a Support menu that will allow you to submit
        questions, request technical guidance, or provide comments to an email
        address. This mailbox will be managed by mClinica, who will answer your
        questions and take your comments.
      </Typography>
      <Typography paragraph className={styles.content}>
        In addition to the Personal Data you have provided or allowed to be
        collected as set out in this Site, Sanofi may Process your Personal Data
        from different sources including:
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ul>
          <li className={styles.list}>
            Data collected following your interactions with the Site and
            services through certain technologies, such as cookies.
          </li>
          <li className={styles.list}>
            Data from publicly available sources collected in accordance with
            Applicable Laws.
          </li>
          <li className={styles.list}>
            Data that obtained legally from third parties who are authorized to
            Process your Personal Data as authorized by you or in accordance
            with Applicable Laws.
          </li>
        </ul>
      </Typography>
    </Grid>
  );
};

export default DataCollectionBlock;
