import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientAnswers } from 'selectors/patient';
import { setPatientAnswers } from 'store/patientSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1em',
  },
  radioContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'min-content',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '1em',
    },
  },
  radioButton: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '10em',
    },
  },
  number: {
    fontFamily: 'sans-serif',
    paddingLeft: '1em',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
  },
}));

const Question = ({ name, number, question }) => {
  const styles = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const patientAnswers = useSelector(selectPatientAnswers);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(patientAnswers[name]);
  }, [patientAnswers, name]);

  const onChangeHandler = (e) => {
    const answer = { [name]: e.target.value };
    dispatch(setPatientAnswers(answer));
    setValue(e.target.value);
  };

  return (
    <Grid
      item
      container
      direction={mdDown ? 'column' : 'row'}
      alignItems="center"
      className={styles.container}
    >
      <Grid item container direction="column" xs sm md lg={5} xl={6}>
        <ol className={styles.number}>
          <li value={number}>
            <Typography>{question}</Typography>
          </li>
        </ol>
      </Grid>
      <Grid item container lg={7} xl={6}>
        <RadioGroup
          row
          className={styles.radioContainer}
          name={name}
          value={value}
          onChange={(e) => onChangeHandler(e)}
        >
          <Grid item container justify={mdDown ? undefined : 'center'} lg xs>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={mdDown ? 'Strongly Disagree' : ''}
              className={styles.radioButton}
            />
          </Grid>
          <Grid item container justify={mdDown ? undefined : 'center'} lg xs>
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={mdDown ? 'Disagree' : ''}
              className={styles.radioButton}
            />
          </Grid>
          <Grid item container justify={mdDown ? undefined : 'center'} lg xs>
            <FormControlLabel
              value="3"
              control={<Radio />}
              label={mdDown ? 'Neutral' : ''}
              className={styles.radioButton}
            />
          </Grid>
          <Grid item container justify={mdDown ? undefined : 'center'} lg xs>
            <FormControlLabel
              value="4"
              control={<Radio />}
              label={mdDown ? 'Agree' : ''}
              className={styles.radioButton}
            />
          </Grid>
          <Grid item container justify={mdDown ? undefined : 'center'} lg xs>
            <FormControlLabel
              value="5"
              control={<Radio />}
              label={mdDown ? 'Strongly Agree' : ''}
              className={styles.radioButton}
            />
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

Question.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default Question;
