import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setRegistrationValues } from 'store/resourcesSlice';
import { Field, Form, Formik } from 'formik';
import ConnectButton from 'components/Button';
import {
  ConnectTextField,
  ConnectMobileNumberField,
  ConnectNumberField,
  ConnectSelectField,
} from 'components/Fields';

const ConnectForm = ({
  validationSchema,
  initialValues,
  fields,
  onSubmitHandler,
  children,
  customSubmitText,
  enabledSubmitButton,
  saveRegistration,
  disableReinitialize,
}) => {
  const dispatch = useDispatch();
  const formikRef = useCallback(
    (node) => {
      if (node !== null && saveRegistration) {
        dispatch(setRegistrationValues(node.values));
      }
    },
    [dispatch, saveRegistration]
  );

  return (
    <Formik
      enableReinitialize={!disableReinitialize}
      innerRef={formikRef}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
    >
      {({ setFieldValue, isValid, dirty, isSubmitting, values }) => {
        let disableSubmitButton = !isValid || isSubmitting || !dirty;
        if (Object.keys(values).length > 4 && isValid && saveRegistration) {
          disableSubmitButton = false;
        }
        if (enabledSubmitButton) {
          disableSubmitButton = false;
        }
        return (
          <Form>
            {fields &&
              fields.map((field) => {
                let fieldType = {
                  inputType: 'text',
                  component: ConnectTextField,
                };

                switch (field.type) {
                  case 'text_input':
                  case 'email_input':
                    fieldType = {
                      inputType: 'text',
                      component: ConnectTextField,
                      setFieldValue,
                      upperCase:
                        field.fieldTypeOptions &&
                        field.fieldTypeOptions.shouldUppercase,
                      ...(field.disabled ? { disabled: true } : {}),
                    };
                    break;
                  case 'select_dropdown_input':
                    fieldType = {
                      component: ConnectSelectField,
                      options:
                        field.field_type_options &&
                        field.field_type_options.select_dropdown_options,
                    };
                    break;
                  case 'secure_input':
                    fieldType = {
                      inputType: 'password',
                      component: ConnectTextField,
                      setFieldValue,
                      upperCase:
                        field.field_type_options &&
                        field.field_type_options.should_uppercase,
                    };
                    break;
                  case 'number_input':
                    fieldType = {
                      inputType: 'number',
                      component: ConnectNumberField,
                      setFieldValue,
                      upperCase:
                        field.fieldTypeOptions &&
                        field.fieldTypeOptions.characterLimit,
                    };
                    break;
                  case 'mobile_number_input':
                    fieldType = {
                      component: ConnectMobileNumberField,
                      setFieldValue,
                      mobilePrefixes:
                        field.field_type_options &&
                        field.field_type_options.mobile_prefixes,
                    };
                    break;
                  default:
                    break;
                }
                return (
                  field.isVisible && (
                    <Field
                      key={field.name}
                      name={field.name}
                      fieldOptions={{
                        ...field,
                        initialValue: initialValues[field.name],
                      }}
                      {...fieldType}
                    />
                  )
                );
              })}

            {children}

            {(fields || children) && (
              <ConnectButton
                type="submit"
                disabled={disableSubmitButton}
                style={{ color: 'white', marginTop: '20px' }}
                title={customSubmitText}
                onClick={() => onSubmitHandler}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

ConnectForm.propTypes = {
  validationSchema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  customSubmitText: PropTypes.string,
  enabledSubmitButton: PropTypes.bool,
  saveRegistration: PropTypes.bool,
  disableReinitialize: PropTypes.bool,
};

ConnectForm.defaultProps = {
  customSubmitText: 'Register',
  enabledSubmitButton: true,
  saveRegistration: false,
  disableReinitialize: false,
};

export default ConnectForm;
