import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const RightsAndChoicesBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        8. Your Rights and Choices
      </Typography>
      <Typography paragraph className={styles.content}>
        You have the right to withdraw your consent for the processing of your
        Personal Data or to request for access or correction of or limit the
        processing of the Personal Data or to make inquiries or complaints in
        respect of the Personal Data by contacting mClinica as described in
        section 12 below. In respect of your right to access and/or correct your
        Personal Data, Sanofi has the right to refuse your requests to access
        and/or make any correction to your Personal Data for the reasons
        permitted under law.
      </Typography>
    </Grid>
  );
};

export default RightsAndChoicesBlock;
