import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import { generateValidationSchema } from 'common/helpers';

const selectResourcesDomain = (state) => state.settings;

export const selectSettingsField = createSelector(
  selectResourcesDomain,
  (state) => {
    const config = get(state, 'settings', {});
    if (!isEmpty(config)) {
      return config;
    }
    return [];
  }
);

export const selectSettingsFieldValidationSchema = createSelector(
  selectSettingsField,
  (state) => {
    return generateValidationSchema(state, {});
  }
);
