import { Box, Grid, Typography } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import PinInput from 'react-pin-input';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    label: {
      marginTop: spacing(4),
      marginBottom: spacing(2),
      textAlign: 'center',
    },
  })
);

const CodeInputField = ({ fieldOptions, field, setFieldValue }) => {
  const style = useStyles();

  const { name, label, disabled, placeholder } = fieldOptions;

  useEffect(() => {
    setFieldValue(get(field, 'name', ''), null);
  }, [setFieldValue, field]);

  const handleChange = (value) => {
    setFieldValue(get(field, 'name', ''), value);
  };

  return (
    <Grid container spacing={4} key={name}>
      <Grid item sm={12} xs={12}>
        <Box className={style.label}>{label || placeholder}</Box>

        <PinInput
          length={6}
          initialValue={field.value || ''}
          onChange={handleChange}
          onBlur={field.onBlur}
          disabled={disabled}
          type="alphanumeric"
          regexCriteria={/^[a-zA-Z]+$/}
          style={{
            display: 'table',
            margin: '0 auto',
          }}
          inputStyle={{
            width: '30px',
            border: 'none',
            borderBottom: '1px solid #EE3C82',
            backgroundColor: '#F4E6EC',
          }}
        />

        <ErrorMessage
          name={field.name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                align="center"
                gutterBottom
                color="error"
              >
                {message}
              </Typography>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

CodeInputField.propTypes = {
  fieldOptions: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    fieldTypeOptions: PropTypes.object,
  }).isRequired,
  field: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CodeInputField;
