import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import PopOverItem1 from './PopOverItem1';
import PopOverItem2 from './PopOverItem2';
import PopOverItem3 from './PopOverItem3';
import PopOverItem4 from './PopOverItem4';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    background: '#6F2B90',
    maxWidth: '30em',
  },
  title: {
    color: 'white',
    fontWeight: 700,
    fontSize: '1.2rem',
  },
  titleContainer: {
    padding: '1em',
  },
  content: {
    color: 'white',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
}));

const renderContent = (param) => {
  switch (param) {
    case 1:
      return <PopOverItem1 />;
    case 2:
      return <PopOverItem2 />;
    case 3:
      return <PopOverItem3 />;
    case 4:
      return <PopOverItem4 />;
    default:
      return null;
  }
};

const PopOver = ({ popoverState, setShowPopover, menuItem }) => {
  const styles = useStyles();
  return (
    <Dialog open={popoverState}>
      <DialogContent className={styles.root}>
        <Grid container direction="column">
          <Grid item container justify="flex-end">
            <Button
              onClick={() => {
                setShowPopover(false);
              }}
            >
              <CloseIcon style={{ fill: 'white' }} />
            </Button>
          </Grid>
          {renderContent(menuItem)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

PopOver.propTypes = {
  popoverState: PropTypes.bool.isRequired,
  setShowPopover: PropTypes.func.isRequired,
  menuItem: PropTypes.number.isRequired,
};

export default PopOver;
