import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'service/auth.service';
import { formatErrorMessage } from 'common/helpers';
import { setMessage } from './resourcesSlice';

export const login = createAsyncThunk(
  'auth/login',
  async (_, { extra, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      if (Auth.isAuthenticated) {
        await authClient.post(`${endpoints.LOGOUT}`);
      }
      return Auth.instance.signIn();
    } catch {
      return rejectWithValue(false);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      if (Auth.isAuthenticated) {
        await authClient.post(endpoints.LOGOUT);
      }
      Auth.clearStorage();
      await Auth.instance.signOut();
      return true;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    pending: false,
  },
  reducers: {},
  extraReducers: {
    [login.fulfilled]: (state) => {
      state.pending = false;
    },
    [login.pending]: (state) => {
      state.pending = true;
    },
    [logout.fulfilled]: (state) => {
      state.pending = false;
    },
    [logout.pending]: (state) => {
      state.pending = true;
    },
  },
});

export default authSlice.reducer;
