import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const RevisionNoticeBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        14. Kemaskini Notis
      </Typography>
      <Typography paragraph className={styles.content}>
        Notis ini terakhir dikemas kini pada 19 Mac 2021. Notis ini boleh diubah
        atau dipinda dari semasa ke semasa tanpa pemberitahuan sebelumnya. Notis
        yang dikemas kini adalah tersedia di Laman web ini. Anda boleh
        menentukan sama ada notis ini telah diubah dengan merujuk kepada tarikh
        terakhir Notis ini dikemas kini. Kami menggalakkan anda untuk menyemak
        Notis ini secara berkala. Dengan terus menyertai Program ini, terus
        menggunakan Laman ini dan / atau terus membekalkan Data Peribadi anda
        atau membenarkan pengumpulan Data Peribadi anda di bawah Program,
        berikutan dengan pengemaskinian Notis ini, yang tersebut adalah dianggap
        sebagai penerimaan anda terhadap pengemaskinian.
      </Typography>
    </Grid>
  );
};

export default RevisionNoticeBlockBahasa;
