import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchConfig = createAsyncThunk(
  'resource/fetchConfig',
  async (_, { extra, rejectWithValue }) => {
    const { connectClient, endpoints } = extra;
    try {
      const { data } = await connectClient.get(endpoints.FETCH_CONFIG);
      return data;
    } catch (err) {
      const { response } = err;
      return rejectWithValue(response.data);
    }
  }
);

const resourcesSlice = createSlice({
  name: 'resources',
  initialState: {
    config: {},
    pending: false,
    showMessage: false,
    message: '',
    registrationValues: {
      agreementCheckTermsAndCondition: false,
      agreementCheckPrivacyNotice: false,
      agreementCheckPhysicianConsent: false,
      enableBpSharing: false,
    },
  },
  reducers: {
    setPending: (state, { payload }) => {
      state.pending = payload;
    },
    removeMessage: (state) => {
      state.showMessage = false;
      state.message = '';
    },
    setMessage: (state, { payload }) => {
      state.showMessage = true;
      state.message = payload;
    },
    setRegistrationValues: (state, { payload }) => {
      state.registrationValues = payload;
    },
  },
  extraReducers: {
    [fetchConfig.pending]: (state) => {
      state.showMessage = false;
      state.pending = true;
    },
    [fetchConfig.fulfilled]: (state, { payload }) => {
      state.config = payload;
      state.pending = false;
    },
    [fetchConfig.rejected]: (state, { payload }) => {
      state.showMessage = true;
      state.pending = false;
      state.message = payload.message;
    },
  },
});

export const {
  setPending,
  removeMessage,
  setMessage,
  setRegistrationValues,
} = resourcesSlice.actions;

export default resourcesSlice.reducer;
