import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedPatient } from 'selectors/patient';
import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import PageLayout from 'components/PageLayout';
import logo from 'assets/png/new-brand-logo.png';
import arrowBack from 'assets/svg/ic-arrow-back.svg';
import ElevationScroll from 'components/ElevationScroll';
import ScreeningBlock from './components/ScreeningBlock';
import ResultsBlock from './components/ResultsBlock';

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5em',
    },
  },
  back: {
    marginTop: '1em',
    marginLeft: '3em',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  logo: {
    marginTop: '1em',
    marginRight: '3em',
    marginBottom: '1em',
    height: '8em',
    width: 'auto',
  },
  container: {
    paddingTop: '14.5em',
    paddingBottom: '4em',
    paddingLeft: '6em',
    paddingRight: '6em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4em',
      paddingBottom: '4em',
      paddingLeft: '2em',
      paddingRight: '2em',
    },
  },
}));

const PatientScreening = () => {
  const styles = useStyles();
  const history = useHistory();
  const selectedPatient = useSelector(selectSelectedPatient);

  const [currentScreen, setCurrentScreen] = useState('screening');
  const handleBack = () => {
    if (currentScreen === 'results') {
      setCurrentScreen('screening');
      return;
    }
    history.goBack();
  };

  return (
    <PageLayout>
      <Grid container direction="column">
        <ElevationScroll>
          <AppBar position="fixed" color="primary" className={styles.appBar}>
            <Grid container direction="column">
              <Grid item container direction="row" justify="space-between">
                <Grid item className={styles.back}>
                  <Grid container direction="row" alignItems="center">
                    <IconButton aria-label="back" onClick={handleBack}>
                      <img src={arrowBack} alt="Arrow Back" />
                    </IconButton>
                    <Typography>Back</Typography>
                  </Grid>
                </Grid>
                <Hidden smDown>
                  <Grid item>
                    <img src={logo} alt="Logo" className={styles.logo} />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </AppBar>
        </ElevationScroll>
        <Grid item container className={styles.container}>
          {currentScreen === 'screening' ? (
            <ScreeningBlock
              setCurrentScreen={setCurrentScreen}
              selectedPatient={selectedPatient}
            />
          ) : (
            <ResultsBlock selectedPatient={selectedPatient} />
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default PatientScreening;
