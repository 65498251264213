import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getValidToken } from 'common/helpers';
import { isEmpty } from 'lodash';
import { Auth } from './auth.service';

const API_BASE_URL = process.env.REACT_APP_CONNECT_API_BASE_URL;

export default axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const connectClient = applyCaseMiddleware(
  axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

connectClient.interceptors.response.use((response) => {
  return response;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
  getValidToken().then(() => {
    const newBearer = Auth.accessToken;
    failedRequest.response.config.headers.Authorization = `Bearer ${newBearer}`;
    return Promise.resolve();
  });

export const authClient = applyCaseMiddleware(
  axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${Auth.accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

authClient.interceptors.request.use(async (request) => {
  let token = Auth.accessToken;
  if (isEmpty(token)) {
    token = await getValidToken();
  }

  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

createAuthRefreshInterceptor(authClient, refreshAuthLogic);
