import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const IntroductionBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        HEALTHCARE PROFESSIONAL’S PERSONAL DATA PROTECTION NOTICE (“NOTICE”)
      </Typography>
      <Typography paragraph className={styles.title}>
        1. Introduction
      </Typography>
      <Typography paragraph className={styles.content}>
        The healthcare professional (“HCP”) In-Control patient program dashboard
        accessible through the website insert webpage address (the “Site”) is
        made available to healthcare professionals in connection with the
        In-Control Patient Access Program (“Program”) sponsored by
        Sanofi-Aventis (Malaysia) Sdn Bhd (“Sanofi”, “we” “our” or “us”) and
        administered on our behalf by our authorized service provider, mClinica
        Pte. Ltd. (“mClinica”).
      </Typography>
      <Typography paragraph className={styles.content}>
        This Notice is to inform you how and why your Personal Data (as defined
        below) is collected, used, stored, disclosed, transferred and otherwise
        handled (collectively “Process”) during the course of your participation
        in this Program through this Site in accordance with the Personal Data
        Protection Act 2010 of Malaysia and all laws and regulations applicable
        to the protection of your Personal Data (“Applicable Laws”) and this
        Notice. This Notice is incorporated into, and is subject to, the Terms
        of Use. “Personal Data” means any information relating to an individual,
        which enables the identification of such individual, whether directly or
        indirectly, as defined by Applicable Laws.
      </Typography>
      <Typography paragraph className={styles.content}>
        By clicking the button below marked “[I agree]”, you confirm (i) that
        you are at least 18 years of age and have reached the age of majority
        under all applicable laws in your country, and (ii) that you have read,
        understood and agreed to the terms and conditions as provided in this
        Notice and you consent to the Processing of your Personal Data which has
        been or will be received or collected in the manner and for the purposes
        described in this Notice. If you do not agree with any provision of this
        Notice, please do not use the Site and remove it from the (mobile)
        device on which you have downloaded it.
      </Typography>
      <Typography paragraph className={styles.content}>
        By accessing and/or registering for access to this Application,
        providing your Personal Data and/or allowing collection of your Personal
        Data for the Patient Program, including through this Site, you confirm
        (i) represent and warrant to Sanofi and agree that you (i) are either
        (a) a qualified and licensed healthcare professional in Malaysia
        (“Healthcare Professional” or “HCP”) or (b) are otherwise authorized by
        Sanofi to use the Site, and you (ii) will use the Site only in a manner
        consistent with your professional, ethical, regulatory, statutory and
        other legal requirements; and you confirm that you have read, understood
        and agreed to the terms and conditions as provided herein and you
        consent to the Processing of your Personal Data which has been or will
        be received or collected by Sanofi in the manner and for the purposes
        described in this Notice. If you do so on behalf of a legal entity, you
        represent and warrant that you have the authority to accept this Notice
        on behalf of that legal entity, and you represent and warrant that all
        users of the Site at such legal entity shall comply with the terms and
        conditions of this Notice.
      </Typography>
      <Typography paragraph className={styles.content}>
        In the event of any conflict, inconsistency or ambiguity between the
        English text of this Notice and other language translation thereof, the
        English text shall prevail. Unless otherwise defined, the definitions in
        the Terms of Use will apply to this Notice. Unless otherwise defined
        herein, capitalised terms used in this Notice shall have the same
        meanings as those defined in the Terms of Use.
      </Typography>
    </Grid>
  );
};

export default IntroductionBlock;
