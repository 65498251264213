import React, { useEffect, useCallback } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { fetchConfig, setMessage } from 'store/resourcesSlice';
import { getProfile } from 'store/userSlice';
import { logout } from 'store/authSlice';
import { isNull } from 'lodash';
import { saveToken } from 'common/helpers';
import { Auth } from 'service/auth.service';
import { AuthActions } from 'ionic-appauth';
import { LoginRedirect, EndRedirect } from 'components/Redirect';
import { userRestrictedErrorMessage } from 'common/constants';
import PrivateRoute from 'components/PrivateRoute';
import HomeDrawer, { drawerWidth } from 'components/HomeDrawer';
import useDrawer from 'common/hooks/useDrawer';

// PAGES
import Login from 'pages/Login';
import PatientScreening from 'pages/PatientScreening';
import Verification from 'pages/Verification';
import Home from 'pages/Home';
import About from 'pages/About';
import UserGuide from 'pages/UserGuide';
import TermsAndConditions from 'pages/TermsAndConditions';
import TermsAndConditionsPublic from 'pages/TermsAndConditionsPublic';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Settings from 'pages/Settings';
import ProgramInformation from 'pages/ProgramInformation';
import PrivacyNoticePublic from 'pages/PrivacyNoticePublic';
import PhysicianConsentPublic from 'pages/PhysicianConsentPublic';
import PhysicianConsent from 'pages/PhysicianConsent';

import './index.css';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const App = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const showDrawer = useDrawer();
  const location = useLocation();

  const login = useCallback(
    () =>
      dispatch(getProfile()).then((data) => {
        const profile = data.payload.result;
        if (profile.userType !== 'physician') {
          history.replace('/login');
          dispatch(logout()).then(() => {
            dispatch(setMessage(userRestrictedErrorMessage));
          });
          return;
        }
        if (isNull(profile?.emailVerifiedAt)) {
          if (history.location.pathname !== '/verification') {
            history.replace('/verification');
          }
        } else if (history.location.pathname !== '/home') {
          history.push('/home');
        }
      }),
    [dispatch, history]
  );

  useEffect(() => {
    Auth.instance.authorizationCallback(
      location.origin + location.pathname + location.search
    );

    const observer = Auth.instance.addActionListener(
      ({ action, tokenResponse }) => {
        if (action === AuthActions.SignOutSuccess) {
          if (history.location.pathname !== '/login') {
            history.push('/login');
          }
        } else if (action === AuthActions.SignInSuccess) {
          saveToken(tokenResponse);
          login();
        }
        if (
          location.pathname === '/login' &&
          action === AuthActions.LoadTokenFromStorageSuccess
        ) {
          if (Auth.isAuthenticated) {
            login();
          }
        }
      }
    );
    if (location.pathname === '/login') {
      Auth.instance.loadTokenFromStorage();
    }
    return () => {
      Auth.instance.removeActionObserver(observer);
    };
  }, [
    dispatch,
    history,
    location.origin,
    location.pathname,
    location.search,
    login,
  ]);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  return (
    <React.Fragment>
      {showDrawer ? <HomeDrawer /> : null}
      <div className={showDrawer ? styles.container : ''}>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/end-redirect" component={EndRedirect} exact />
          <Route path="/login-redirect" component={LoginRedirect} exact />
          <Route path="/verification" component={Verification} exact />
          <Route
            path="/public/terms-and-conditions"
            component={TermsAndConditionsPublic}
            exact
          />
          <Route
            path="/public/privacy-notice"
            component={PrivacyNoticePublic}
            exact
          />
          <Route
            path="/public/physician-consent-form"
            component={PhysicianConsentPublic}
            exact
          />
          <Route
            path="/program-information"
            component={ProgramInformation}
            exact
          />
          <PrivateRoute
            path="/terms-and-conditions"
            component={TermsAndConditions}
            exact
          />
          <PrivateRoute path="/home" component={Home} exact />
          <PrivateRoute path="/about" component={About} exact />
          <PrivateRoute path="/user-guide" component={UserGuide} exact />
          <PrivateRoute
            path="/patient-screening"
            component={PatientScreening}
            exact
          />
          <PrivateRoute
            path="/privacy-policy"
            component={PrivacyPolicy}
            exact
          />
          <PrivateRoute path="/settings" component={Settings} exact />
          <PrivateRoute
            path="/physician-consent-form"
            component={PhysicianConsent}
            exact
          />
          <Redirect exact from="/" to="/login" />
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default App;
