import { Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';

const MaterialButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: 'white',
  borderRadius: 5,
  fontWeight: 700,
  height: 50,
}));

const ConnectButton = ({ title, onClick, variant, ...rest }) => {
  return (
    <MaterialButton
      variant={variant}
      size="large"
      onClick={onClick}
      fullWidth
      disableElevation
      {...rest}
      data-testid="connectkit-button"
    >
      {title}
    </MaterialButton>
  );
};

ConnectButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

ConnectButton.defaultProps = {
  variant: 'contained',
};

export default ConnectButton;
