// @flow
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ErrorMessage } from 'formik';
import { removeEmojis } from 'common/helpers';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    formControl: {
      width: '100%',
      fontSize: 14,
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
      fontStyle: 'italic',
    },
    notchedOutline: {
      borderColor: palette.primary.main,
    },
    labelFit: {
      fontSize: 11,
      paddingRight: 20,
      transform: 'translate(14px, 18px) scale(1)',
    },
  })
);

const ConnectTextField = ({
  fieldOptions,
  field,
  inputType,
  setFieldValue,
}) => {
  const {
    name,
    label,
    type,
    hint,
    initialValue,
    placeholder,
    isRequired,
    disabled,
    fieldTypeOptions,
  } = fieldOptions;

  const style = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [textValue, setTextValue] = useState(initialValue);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangeField = (data) => {
    const value = removeEmojis(data.target.value.trimStart());
    setTextValue(value);
    setFieldValue(field.name, value);
  };

  useEffect(() => {
    if (initialValue) {
      setFieldValue(field.name, initialValue);
    }
  }, [setFieldValue, field.name, initialValue]);

  return (
    <Grid container spacing={4} key={name}>
      <Grid item sm={12} xs={12}>
        {inputType === 'password' ? (
          <FormControl className={style.formControl}>
            <InputLabel htmlFor={name} color="primary">
              {label
                ? `${label}${isRequired ? ' *' : ' (optional)'}`
                : placeholder}
            </InputLabel>
            <Input
              id={name}
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              color="primary"
              value={field.value ? textValue : ''}
              onChange={onChangeField}
              onBlur={field.onBlur}
              onInput={field.onBlur}
              style={{ notchedOutline: style.notchedOutline }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {hint && (
              <Typography variant="caption" className={style.helperText}>
                {hint}
              </Typography>
            )}
          </FormControl>
        ) : (
          <TextField
            type={type}
            label={
              label
                ? `${label}${isRequired ? ' *' : ' (optional)'}`
                : placeholder
            }
            color="primary"
            value={field.value ? textValue : ''}
            onChange={onChangeField}
            onBlur={field.onBlur}
            name={field.name}
            disabled={disabled}
            autoComplete="off"
            onInput={
              get(fieldTypeOptions, 'shouldUppercase', false)
                ? (e) => {
                    const start = e.target.selectionStart;
                    const end = e.target.selectionEnd;
                    e.target.value = e.target.value.toUpperCase();
                    e.target.setSelectionRange(start, end);
                  }
                : null
            }
            InputProps={{
              style: { notchedOutline: style.notchedOutline },
              autoComplete: 'off',
              maxLength: 50,
            }}
            InputLabelProps={{
              className:
                label && label.length > 50 ? style.labelFit : 'inherit',
            }}
            helperText={hint}
            labelwidth={label ? label.length * 10 : placeholder.length * 10}
            fullWidth
          />
        )}

        <ErrorMessage
          name={name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="primary"
              >
                {message}
              </Typography>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

ConnectTextField.propTypes = {
  fieldOptions: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldTypeOptions: PropTypes.object,
  }).isRequired,
  field: PropTypes.object.isRequired,
  inputType: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default ConnectTextField;
