import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataSharingBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        6. Who will Personal Data be shared with
      </Typography>
      <Typography paragraph className={styles.content}>
        For the Purposes described above, your Personal Data may be disclosed to
        the parties (within or outside Malaysia) as listed below, including but
        not limited to:
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ul>
          <li className={styles.list}>
            Suppliers, distributors, service providers, agents or contractors
            engaged by Sanofi or acting on Sanofi&apos;s behalf;
          </li>
          <li className={styles.list}>External advisors;</li>
          <li className={styles.list}>
            Relevant government regulators, statutory boards or authorities or
            law enforcement agencies or industry regulators or any person to
            whom we are compelled or required to do so under law, to comply with
            any laws, rules, guidelines and regulations or schemes imposed by
            any governmental authority;
          </li>
          <li className={styles.list}>
            Any other party to whom you authorise us in writing to disclose your
            Personal Data to.
          </li>
          <li className={styles.list}>
            The Site allows you to receive your Patients’ Personal Data, for
            instance their BP recordings.
          </li>
        </ul>
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        Third Party Analytics
      </Typography>
      <Typography paragraph className={styles.content}>
        When the Site is downloaded and used, we may automatically collect
        information on the usage of the Site by its users. For instance, what
        kind of functionalities are used and how long users spend on each page
        in the Site. We use this information to analyze the usage of the Site
        and identify opportunities for further development and optimization of
        the Site. The (third party) analytical tool (e.g. Google Analytics,
        Crashlytics) that we use to collect and analyze this kind of information
        does so without collecting and processing any information that can
        identify the identity of the users of the Site.
      </Typography>
    </Grid>
  );
};

export default DataSharingBlock;
