import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const AboutApplicationBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        2. About this Site
      </Typography>
      <Typography paragraph className={styles.content}>
        The Site is intended to give you a tool to enroll the eligible patients
        into the Program and monitor the activities of your patients who are
        participating in the Program (“Patients”) in connection with the Program
        including to receive your Patients’ BP readings.
      </Typography>
    </Grid>
  );
};

export default AboutApplicationBlock;
