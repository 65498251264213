import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '1.2em',
    paddingRight: '1.2em',
    paddingTop: '1em',
    paddingbottom: '1em',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.1rem',
  },
  subtitle: {
    fontWeight: 700,
    fontSize: '0.9rem',
  },
  content: {
    fontWeight: 300,
    fontSize: '0.9rem',
  },
  list: {
    marginBottom: '1em',
  },
}));
