import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'formik';
import { ConnectCheckboxField } from 'components/Fields';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  agreementText: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 8,
    lineHeight: 1.2,
    fontSize: '0.9rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
    textTransform: 'none',
    textAlign: 'left',
  },
  agreementButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const RegistrationCheckbox = ({ name, label, to, required }) => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <Field
      name={name}
      component={ConnectCheckboxField}
      label={
        <Button
          className={styles.agreementButton}
          onClick={() => {
            history.push(to);
          }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          <Typography variant="subtitle1" className={styles.agreementText}>
            I agree to the <u style={{ color: '#14B6FF' }}>{label}</u> of the
            In-Control Program
          </Typography>
        </Button>
      }
      required={required}
    />
  );
};

RegistrationCheckbox.defaultProps = {
  required: false,
};

RegistrationCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default RegistrationCheckbox;
