import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectNextButtonEnabled } from 'selectors/patient';
import { Button, Fade, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { screening, links } from 'common/constants';
import Question from './Question';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
  },
  patient: {
    fontSize: '0.8rem',
    color: theme.palette.primary.main,
  },
  name: {
    fontWeight: 700,
    fontSize: '1.1rem',
  },
  answer: {
    fontWeight: 700,
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  button: {
    marginTop: '2em',
    marginBottom: '3em',
    paddingRight: '2em',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  moriskyReference: {
    fontWeight: '500',
    fontSize: '0.9rem',
  },
}));

const ScreeningBlock = ({ setCurrentScreen, selectedPatient }) => {
  const styles = useStyles();
  const nextButtonEnabled = useSelector(selectNextButtonEnabled);

  const onSubmitHandler = () => {
    setCurrentScreen('results');
  };

  return (
    <Fade in>
      <Grid item container className={styles.root}>
        <Grid item container direction="row" alignItems="center">
          <Grid item container direction="column" xs sm md lg={5} xl={6}>
            <Typography className={styles.patient}>PATIENT</Typography>
            <Typography className={styles.name}>
              {selectedPatient.firstName} {selectedPatient.lastName}
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item container lg={7} xl={6}>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>
                  Strongly Disagree
                </Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>Disagree</Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>Neutral</Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>Agree</Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>
                  Strongly Agree
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item container>
          <Typography className={styles.patient} style={{ marginTop: '2em' }}>
            SECTION A: ADHERENCE
          </Typography>

          <Question
            name={screening[0].name}
            number={screening[0].number}
            question={screening[0].question}
          />

          <Question
            name={screening[1].name}
            number={screening[1].number}
            question={screening[1].question}
          />

          <Typography className={styles.patient} style={{ marginTop: '2em' }}>
            SECTION B: KNOWLEDGE AND BELIEF
          </Typography>

          <Question
            name={screening[2].name}
            number={screening[2].number}
            question={screening[2].question}
          />

          <Question
            name={screening[3].name}
            number={screening[3].number}
            question={screening[3].question}
          />

          <Question
            name={screening[4].name}
            number={screening[4].number}
            question={screening[4].question}
          />

          <Question
            name={screening[5].name}
            number={screening[5].number}
            question={screening[5].question}
          />

          <Grid item container justify="flex-end" className={styles.button}>
            <Button
              variant="contained"
              color="secondary"
              style={{ textTransform: 'none', color: 'white' }}
              type="submit"
              onClick={onSubmitHandler}
              disabled={!nextButtonEnabled}
            >
              NEXT
            </Button>
          </Grid>
          <Grid item>
            <Typography className={styles.moriskyReference}>
              {links.moriskyLink} &nbsp;
              <a
                rel="noreferrer"
                href={links.moriskyUrl}
                target="_blank"
                className={styles.moriskyReference}
              >
                {links.moriskyUrl}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

ScreeningBlock.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
  selectedPatient: PropTypes.object.isRequired,
};

export default ScreeningBlock;
