import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getPreRegisteredUsers, resetState } from 'store/patientSlice';
import { getProfile } from 'store/userSlice';
import { selectPatientList, selectPatientPending } from 'selectors/patient';
import {
  Grid,
  Hidden,
  Typography,
  Card,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { isNull } from 'lodash';

import Loader from 'components/Loader';
import SearchBar from 'components/SearchBar';
import StatusFilter from 'components/StatusFilter';
import AddPatient from 'components/AddPatient';
import PageLayout from 'components/PageLayout';
import PatientTable from './components/PatientTable';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5.5em',
      padding: '1.5em',
    },
  },
  tableContainer: {
    padding: '2em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
      paddingTop: 0,
      marginBottom: '2em',
    },
  },
  tableCard: {
    width: '100%',
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Home = () => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const patientList = useSelector(selectPatientList);
  const patientPending = useSelector(selectPatientPending);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    dispatch(getPreRegisteredUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetState());
    dispatch(getProfile()).then((data) => {
      const profile = data.payload.result;
      if (isNull(profile?.emailVerifiedAt)) {
        history.replace('/verification');
      } else {
        setVerified(true);
      }
    });
  }, [dispatch, history]);

  return (
    <PageLayout isLoaderOpen={patientPending}>
      {verified ? (
        <Grid container>
          <Grid container className={styles.root}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Typography variant="h6">Enrolled Patient List</Typography>
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <StatusFilter contained />
              </Grid>
            </Hidden>
            <Grid item container direction="column" xs={12} sm={6} md lg xl>
              <Grid item container justify="flex-end">
                <Hidden mdDown>
                  <Grid item>
                    <Grid container direction="row">
                      <SearchBar contained />
                      <StatusFilter />
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
              <Grid item container direction="column" md lg xl>
                <Grid item container justify="flex-end">
                  <Hidden xsDown>
                    <AddPatient />
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container className={styles.tableContainer}>
            {smDown ? (
              <PatientTable rows={patientList} />
            ) : (
              <Card className={styles.tableCard} variant="outlined">
                <PatientTable rows={patientList} />
              </Card>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loader open />
      )}
    </PageLayout>
  );
};
export default Home;
