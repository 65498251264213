import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { ErrorMessage } from 'formik';

const ConnectCheckboxField = ({ field, label, disabled, helperText }) => {
  return (
    <Container>
      <Grid container spacing={4} key={field.name}>
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value || false}
              onChange={field.onChange}
              onBlur={field.onBlur}
              name={field.name}
              color="secondary"
              disabled={disabled}
            />
          }
          label={label}
        />

        {helperText && (
          <Typography variant="caption" display="block" gutterBottom>
            {helperText}
          </Typography>
        )}

        <ErrorMessage
          name={field.name}
          render={(message) => {
            return (
              <Typography variant="caption" display="block" color="error">
                {message}
              </Typography>
            );
          }}
        />
      </Grid>
    </Container>
  );
};

ConnectCheckboxField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

ConnectCheckboxField.defaultProps = {
  helperText: '',
  disabled: false,
};

export default ConnectCheckboxField;
