import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loader = ({ open }) => {
  const styles = useStyles();
  return (
    <Backdrop className={styles.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Loader.defaultProps = {
  open: true,
};

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Loader;
