import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  titleContainer: {
    padding: '1em',
  },
  content: {
    color: 'white',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
}));
