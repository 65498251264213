import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  tab: {
    width: '100vw',
    paddingLeft: '2em',
  },
  tabIcon: {
    verticalAlign: 'bottom',
    marginRight: '1em',
    paddingBottom: '0.2em',
  },
  tabText: {
    color: 'white',
    textTransform: 'none',
    fontWeight: 700,
  },
}));

const StyledTabLabel = ({ label, icon }) => {
  const styles = useStyles();
  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      className={styles.tab}
    >
      <Grid item>
        <img src={icon} className={styles.tabIcon} alt="logo" />
      </Grid>
      <Typography variant="subtitle2" className={styles.tabText}>
        {label}
      </Typography>
    </Grid>
  );
};

StyledTabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default StyledTabLabel;
