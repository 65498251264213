import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from 'store/userSlice';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  selectSettingsField,
  selectSettingsFieldValidationSchema as schema,
} from 'selectors/settings';
import { ConnectForm } from 'components/Form';
import { selectProfilePending } from 'selectors/user';
import PageLayout from 'components/PageLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5.5em',
      padding: '1.5em',
    },
  },
  conatiner: {
    height: '70vh',
    [theme.breakpoints.down('md')]: {
      height: 'min-content',
      marginTop: '2em',
    },
  },
  formContainer: {
    width: '25em',
  },
}));

const Settings = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const settingsField = useSelector(selectSettingsField);
  const validationSchema = useSelector(schema);
  const isProfilePending = useSelector(selectProfilePending);

  const handleUpdatePassword = (values, actions) => {
    actions.setSubmitting(true);
    dispatch(updatePassword(values)).then((data) => {
      if (data.payload) {
        actions.resetForm();
      }
      actions.setSubmitting(false);
    });
  };

  return (
    <PageLayout isLoaderOpen={isProfilePending}>
      <Grid container className={styles.root}>
        <Grid item>
          <Typography variant="h6">Settings</Typography>
        </Grid>
        <Grid container justify="center" className={styles.conatiner}>
          <Grid
            container
            direction="column"
            className={styles.formContainer}
            justify="center"
          >
            <ConnectForm
              initialValues={{}}
              onSubmitHandler={handleUpdatePassword}
              fields={settingsField}
              validationSchema={validationSchema}
              customSubmitText="EDIT PASSWORD"
              enabledSubmitButton={false}
            >
              <div style={{ height: '2.5em' }} />
            </ConnectForm>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Settings;
