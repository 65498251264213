import React from 'react';
import Loader from 'components/Loader';

export const LoginRedirect = () => {
  return <Loader />;
};

export const EndRedirect = () => {
  return <Loader />;
};
