import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'service/auth.service';

const PrivateRoute = ({ component, ...rest }) => {
  function renderFn(props) {
    if (Auth.isAuthenticated) {
      return React.createElement(component, props);
    }
    return <Redirect to="/login" />;
  }
  return <Route {...rest} render={renderFn} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
