import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const PersonalDataBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        3. Apakah Data Peribadi yang boleh diproses
      </Typography>
      <Typography paragraph className={styles.content}>
        Bergantung pada interaksi antara anda dengan kami, kami mungkin
        Memproses kategori Data Peribadi berikut:
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ol type="a">
          <li className={styles.list}>
            Maklumat pengenalan dan butir-butir hubungan: sebelum anda mulai
            menggunakan Laman web ini, anda akan diminta untuk mengisi alamat
            e-mel, nama, nama klinik / hospital, nombor akaun DKSH, menetapkan
            kata laluan dan maklumat Produk yang dibeli oleh pihak anda
          </li>
          <li className={styles.list}>
            Mesej: sebarang maklumat atau data yang mungkin anda hantar kepada
            mClinica dan / atau doktor yang merawati anda semasa anda membuat
            sebarang permintaan, pertanyaan dan / atau surat-menyurat dengan
            menggunakan Laman web ini, melalui e-mel, telefon, faks, pos atau
            cara-cara lain.
          </li>
        </ol>
      </Typography>
      <Typography paragraph className={styles.content}>
        Sekiranya dinyatakan, adalah wajib untuk anda membekalkan Data Peribadi
        anda kepada kami untuk membolehkan kami memproses data anda berkaitan
        dengan Tujuan tertentu (seperti mana yang dinyatakan di bawah).
        Sekiranya anda tidak bersetuju untuk membekalkan Data Peribadi yang
        wajib, kami mungkin tidak dapat berinteraksi dengan anda, termasuk
        berkomunikasi dengan anda atau memberikan maklumat yang anda perlukan.
        Sekiranya anda memadamkan atau tidak membenarkan penggunaan ‘cookies’,
        beberapa bahagian di Laman ini mungkin tidak dapat berfungsi dengan
        sempurna atau tidak dapat diakses. Penolakan untuk membekalkan Data
        Peribadi yang wajib juga akan membatalkan kelayakan anda untuk menyertai
        Program dan / atau menjejaskan kemampuan anda menikmati dan / atau
        mengambil bahagian dengan sepenuhnya, antara lain, aktiviti-aktiviti
        yang dianjurkan di Laman web ini.
      </Typography>
    </Grid>
  );
};

export default PersonalDataBlock;
