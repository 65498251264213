import { createSelector } from 'reselect';
import { isStatusFiltered } from 'common/helpers';
import { isEmpty } from 'lodash';

const selectPatientDomain = (state) => state.patient;

export const selectSelectedPatient = createSelector(
  selectPatientDomain,
  (state) => state.selectedPatient
);

export const selectPatientList = createSelector(
  selectPatientDomain,
  (state) => {
    const filter = state.searchFilter.toLowerCase();
    const list = state.patientList;
    if (filter.length > 0 || !isEmpty(state.statusFilter)) {
      return list.filter((data) => {
        const name = `${data.firstName} ${data.lastName}`;
        const patientId = data.id.substr(0, 5);
        if (
          name.toLowerCase().includes(filter) ||
          data.email.toLowerCase().includes(filter) ||
          data.mobileNumber.toLowerCase().includes(filter) ||
          patientId.includes(filter)
        ) {
          if (!isEmpty(state.statusFilter)) {
            return isStatusFiltered(data, state.statusFilter);
          }
          return data;
        }
        return null;
      });
    }
    return list;
  }
);

export const selectPatientAnswers = createSelector(
  selectPatientDomain,
  (state) => state.patientAnswers
);

export const selectPatientScore = createSelector(
  selectPatientDomain,
  (state) => state.patientScore
);

export const selectAdheranceLabel = createSelector(
  selectPatientDomain,
  (state) => state.adheranceLabel
);

export const selectSubmitPatientEnabled = createSelector(
  selectPatientDomain,
  (state) => state.submitPatientEnabled
);

export const selectNextButtonEnabled = createSelector(
  selectPatientDomain,
  (state) => state.nextButtonEnabled
);

export const selectSearchFilter = createSelector(
  selectPatientDomain,
  (state) => state.searchFilter
);

export const selectPatientPending = createSelector(
  selectPatientDomain,
  (state) => state.pending
);

export const selectPatient = createSelector(
  selectPatientDomain,
  (state) => state.selectedPatient
);

export const selectStatusFilter = createSelector(
  selectPatientDomain,
  (state) => state.statusFilter
);
