import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';
import PhoneNumberText from 'components/PhoneNumberText';

const QuestionsBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        12. Cara Hubungan dan Pertanyaan
      </Typography>
      <Typography paragraph className={styles.content}>
        Untuk melaksanakan hak yang disebut di atas, atau jika anda mempunyai
        sebarang pertanyaan mengenai amalan privasi, penggunaan atau pendedahan
        Data Peribadi anda, sila hubungi mClinica dengan mengklik pada pautan
        &quot;Hubungi Kami&quot; (&quot;Contact Us&quot;)atau hubungi mClinica
        di:
      </Typography>
      <Typography paragraph className={styles.content}>
        E-mel:{' '}
        <Link href="mailto:incontrol.support@mclinica.com">
          incontrol.support@mclinica.com
        </Link>
        , Telefon: <PhoneNumberText />, Alamat:
        5 Shenton Way, UIC Building #10-01 Singapore 068808.
      </Typography>
    </Grid>
  );
};

export default QuestionsBlockBahasa;
