import { createSelector } from 'reselect';

const selectUserDomain = (state) => state.user;

export const selectProfile = createSelector(
  selectUserDomain,
  (state) => state.profile
);

export const selectProfilePending = createSelector(
  selectUserDomain,
  (state) => state.pending
);
