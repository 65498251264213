const services = {
  PROGRAMS: 'programs',
  USERS: 'users',
  TRANSACTIONS: 'transactions',
  STORAGE: 'storage',
  CUSTOMERS: 'customers',
  REPORTS: 'reporting',
  PRODUCTS: 'products',
};

const programId = process.env.REACT_APP_CONNECT_PROGRAM_ID;
const keycloakUrl = process.env.REACT_APP_CONNECT_KEYCLOAK_URL;

export const endpoints = {
  KEYCLOAK_URI: `${keycloakUrl}/realms/${programId}`,
  // PROGRAMS
  FETCH_CONFIG: `${services.PROGRAMS}/${programId}/config`,
  FETCH_VERSION: `${services.PROGRAMS}/${programId}/version`,
  // STORAGE
  UPLOAD_PHOTO: `${services.STORAGE}/${programId}/upload`,
  // TRANSACTION
  VERIFY_TRANSACTION: `${services.TRANSACTIONS}/${programId}/purchase/verify`,
  VERIFY_PATIENT_ELIGIBILITY: `${services.TRANSACTIONS}/${programId}/purchase`,
  VERIFY_REDEEM: `${services.TRANSACTIONS}/${programId}/purchase`,
  PURCHASE: `${services.TRANSACTIONS}/${programId}/purchase`,
  REDEEM: `${services.TRANSACTIONS}/${programId}/purchase`,
  // REGISTRATION
  USER_REGISTRATION: `${services.USERS}/${programId}/registration`,
  PATIENT_PRE_REGISTRATION: `${services.USERS}/${programId}/preregistration`,
  EMAIL_VERIFICATION: `${services.USERS}/${programId}/registration/verify`,
  RESEND_EMAIL_VERIFICATION: `${services.USERS}/${programId}/registration/verify/resend`,
  GET_USER: `${services.USERS}/${programId}/profile`,
  UPDATE_PASSWORD: `${services.USERS}/${programId}/profile/update-password`,
  LOGOUT: `${services.USERS}/${programId}/auth/logout`,
  // REPORTING
  TRANSACTION_HISTORY: `${services.REPORTS}/${programId}/core/transactions/list`,
  // PRODUCTS
  GET_PRODUCTS: `${services.PRODUCTS}/${programId}/catalog`,
};

export const screening = [
  {
    name: 'q1',
    number: '1',
    question:
      'My patient does not take his/her medication(s) MORE than directed',
  },
  {
    name: 'q2',
    number: '2',
    question:
      'My patient does not take his/her medication(s) LESS than directed',
  },
  {
    name: 'q3',
    number: '3',
    question: 'My patient is taking his/her medication(s) everyday as directed',
  },
  {
    name: 'q4',
    number: '4',
    question: 'My patient has a good understanding of his/her illness',
  },
  {
    name: 'q5',
    number: '5',
    question:
      'My patient is confident that his/her medication(s) are helping him/her',
  },
  {
    name: 'q6',
    number: '6',
    question:
      'My patient is satisfied with the information shared by his/her doctor',
  },
  {
    name: 'q7',
    number: '7',
    question:
      'My patient is able to make a decision together with his/her doctor regarding his/her medication(s)',
  },
  {
    name: 'q8',
    number: '8',
    question:
      'My patient knows how to take his/her medication(s) (eg. dose, frequency)',
  },
  {
    name: 'q9',
    number: '9',
    question:
      'My patient knows why he/she is taking their medication(s) (eg. indication)',
  },
];

export const settingsConfig = [
  {
    programId: 'sanofi-my-develop',
    name: 'old_password',
    label: 'Old Password',
    forUserType: 'patient',
    type: 'secure_input',
    title: null,
    hint: '',
    placeholder: 'Old Password',
    prefix: null,
    isRequired: true,
    isDefault: true,
    regexValidations: [
      {
        regex: '^.{8,}$',
        errorMessage: 'Old password needs to be at least 8 characters long.',
      },
      {
        regex: '^.*[A-Z]',
        errorMessage:
          'Old password needs to include at least one upper case English Letter.',
      },
      {
        regex: '^.*[a-z]',
        errorMessage:
          'Old password needs to include at least one lower case English Letter.',
      },
      {
        regex: '^.*[0-9]',
        errorMessage: 'Old password needs to include at least one number.',
      },
      {
        regex: '^.*[@#$%^&+=!*()?]',
        errorMessage:
          'Old password needs to include at least one special character.',
      },
    ],
    errorMessage: 'Old Password is Required',
    model: 'User',
    modelPath: null,
    fieldTypeOptions: {
      characterLimit: 50,
    },
    order: 1,
    isUnique: false,
    uniqueErrorMessage: '',
    isVisible: true,
    isEditable: false,
    metadata: null,
    createdAt: '2020-10-22T03:40:08.000Z',
    updatedAt: '2020-10-22T03:40:08.000Z',
    deletedAt: null,
  },
  {
    programId: 'sanofi-my-develop',
    name: 'password',
    label: 'New Password',
    forUserType: 'patient',
    type: 'secure_input',
    title: null,
    hint: '',
    placeholder: 'New Password',
    prefix: null,
    isRequired: true,
    isDefault: true,
    regexValidations: [
      {
        regex: '^.{8,}$',
        errorMessage: 'New password needs to be at least 8 characters long.',
      },
      {
        regex: '^.*[A-Z]',
        errorMessage:
          'New password needs to include at least one upper case English Letter.',
      },
      {
        regex: '^.*[a-z]',
        errorMessage:
          'New password needs to include at least one lower case English Letter.',
      },
      {
        regex: '^.*[0-9]',
        errorMessage: 'New password needs to include at least one number.',
      },
      {
        regex: '^.*[@#$%^&+=!*()?]',
        errorMessage:
          'New password needs to include at least one special character.',
      },
    ],
    errorMessage: 'New Password is Required',
    model: 'User',
    modelPath: null,
    fieldTypeOptions: {
      characterLimit: 50,
    },
    order: 2,
    isUnique: false,
    uniqueErrorMessage: '',
    isVisible: true,
    isEditable: false,
    metadata: null,
    createdAt: '2020-10-22T03:40:08.000Z',
    updatedAt: '2020-10-22T03:40:08.000Z',
    deletedAt: null,
  },
  {
    programId: 'sanofi-my-develop',
    name: 'confirm_password',
    label: 'Confirm Password',
    forUserType: 'patient',
    type: 'secure_input',
    title: null,
    hint: '',
    placeholder: 'Confirm Password',
    prefix: null,
    isRequired: true,
    isDefault: true,
    regexValidations: [
      {
        regex: '^.{8,}$',
        errorMessage:
          'Confirm password needs to be at least 8 characters long.',
      },
      {
        regex: '^.*[A-Z]',
        errorMessage:
          'Confirm password needs to include at least one upper case English Letter.',
      },
      {
        regex: '^.*[a-z]',
        errorMessage:
          'Confirm password needs to include at least one lower case English Letter.',
      },
      {
        regex: '^.*[0-9]',
        errorMessage: 'Confirm password needs to include at least one number.',
      },
      {
        regex: '^.*[@#$%^&+=!*()?]',
        errorMessage:
          'Confirm password needs to include at least one special character.',
      },
    ],
    errorMessage: 'Confirm Password is Required',
    model: 'User',
    modelPath: null,
    fieldTypeOptions: {
      characterLimit: 50,
    },
    order: 3,
    isUnique: false,
    uniqueErrorMessage: '',
    isVisible: true,
    isEditable: false,
    metadata: null,
    createdAt: '2020-10-22T03:40:08.000Z',
    updatedAt: '2020-10-22T03:40:08.000Z',
    deletedAt: null,
  },
];

export const links = {
  moriskyLink: `Goh SSL, Lai PSM, Liew S-M, Tan KM, Chung WW, Chua SS (2020) Development of a PATIENT-Medication Adherence Instrument (PMAI) and a HEALTHCARE PROFESSIONAL Medication Adherence Instrument (H-MAI-9) using the nominal group technique. PLoS ONE 15(11): e0242051.`,
  moriskyUrl: 'https://doi.org/10.1371/journal.pone.0242051',
  appProdUrl: 'https://incontrol.connect.mclinica.com/',
};

export const userRestrictedErrorMessage = `The dashboard is only for doctors' access. Please contact incontrol.support@mclinica.com for any concerns.`;

export const rescreenTooltipMessage =
  'You may re-screen the patient by clicking the icon.';
