import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const SecurityBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        10. Keselamatan
      </Typography>
      <Typography paragraph className={styles.content}>
        Kami prihatin tentang perlindungan Data Peribadi anda daripada akses dan
        penggunaan yang tidak sah atau kehilangan. Kami mempunyai
        langkah-langkah pentadbiran, teknikal, dan fizikal yang bersesuaian
        untuk melindungi Data Peribadi anda.
      </Typography>
      <Typography paragraph className={styles.content}>
        Data Peribadi tertentu disimpan di Peranti anda sahaja dan penjagaan
        keselamatan maklumat itu adalah tanggungjawab anda. Sila rujuk dokumen
        panduan peranti anda mengenai cara pengurusan simpanan dan cara
        pengawalan keselamatan yang bersesuaian untuk melindungi maklumat
        tersebut. Kami menggesa anda agar berhati-hati ketika anda menyimpan
        sebarang maklumat di Laman web atau menghantar maklumat melalui
        Internet, terutamanya maklumat yang berkaitan dengan kesihatan anda.
        Jangan sesekali kongsi maklumat log masuk anda. Perlu diketahui bahawa,
        walaupun kami berusaha untuk mengekalkan keselamatan yang wajar sebagai
        sebahagian daripada fungsi Laman ini, tidak ada sistem keselamatan yang
        dapat mencegah semua ancaman pelanggaran keselamatan yang mungkin
        berlaku.
      </Typography>
    </Grid>
  );
};

export default SecurityBlockBahasa;
