import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataTransferBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        7. Transfer of Personal Data
      </Typography>
      <Typography paragraph className={styles.content}>
        To the extent required for the relevant Purposes, the Personal Data may
        be disclosed to the parties listed in section 6 who may be located
        within or outside Malaysia. At all times, your personal data will be
        treated by Sanofi and our authorized employees, agents and third parties
        as confidential information. By accessing the Site, providing your
        Personal Data and/or allowing collection and processing of your Personal
        Data under the Program, you consent to the transfer of your Personal
        Data out of Malaysia as described herein.
      </Typography>
    </Grid>
  );
};

export default DataTransferBlock;
