import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageLayout from 'components/PageLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from 'assets/png/new-brand-logo-colored.png';
import { useHistory } from 'react-router';
import Content from './components/Content';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5.5em',
      padding: '1.5em',
    },
  },
  rootWithLogo: {
    paddingLeft: '4em',
    paddingRight: '4em',
    paddingTop: '2em',
    [theme.breakpoints.only('xs')]: {
      padding: '1em',
    },
  },
  logo: {
    height: '12em',
    width: 'auto',
    marginTop: '2em',
    marginBottom: '1.5em',
    [theme.breakpoints.only('md')]: {
      height: '10em',
      width: 'auto',
      marginTop: '1.8em',
      marginBottom: '1.4em',
    },
    [theme.breakpoints.down('sm')]: {
      height: '8em',
      width: 'auto',
      marginTop: '1em',
      marginBottom: '1em',
    },
  },
}));

const PhysicianConsentPublic = () => {
  const styles = useStyles();
  const history = useHistory();
  const handleBack = () => {
    history.replace('/login');
  };

  return (
    <PageLayout>
      <Grid container className={styles.rootWithLogo}>
        <React.Fragment>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <IconButton aria-label="back" onClick={handleBack}>
                <ArrowBackIcon color="secondary" />
              </IconButton>
              <Typography color="secondary">Back</Typography>
            </Grid>
          </Grid>
          <Grid item container justify="center">
            <img src={logo} alt="in-control icon" className={styles.logo} />
          </Grid>
        </React.Fragment>
        <Content />
      </Grid>
    </PageLayout>
  );
};

export default PhysicianConsentPublic;
