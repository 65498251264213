import { makeStyles } from '@material-ui/styles';

export const useDarkThemeStyles = makeStyles(() => ({
  root: {
    paddingLeft: '1.2em',
    paddingRight: '1.2em',
    paddingTop: '1em',
    paddingbottom: '1em',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.1rem',
    color: 'white',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '0.9rem',
    color: 'white',
  },
  content: {
    fontWeight: 300,
    fontSize: '0.9rem',
    color: 'white',
  },
  list: {
    marginBottom: '1em',
  },
}));
