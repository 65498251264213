import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataRetentionBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        9. Pengekalan Data
      </Typography>
      <Typography paragraph className={styles.content}>
        Kami akan menyimpan Data Peribadi dalam pangkalan data Program ini yang
        dikendalikan oleh atau untuk pihak Sanofi. Sebarang Data Peribadi
        disimpan selagi tujuan untuk pengumpulan Data Peribadi kekal sah. Data
        Peribadi akan dimusnahkan, dianonimkan atau dikembalikan kepada anda
        (jika dapat dilakukan secara wajar), sekiranya Data Peribadi tidak lagi
        diperlukan untuk Tujuan tersebut kecuali jika penyimpanan lebih lanjut
        diperlukan untuk memenuhi jangka waktu penyimpanan yang lebih lama demi
        memenuhi keperluan operasi, undang-undang, peraturan, cukai atau
        perakaunan.
      </Typography>
    </Grid>
  );
};

export default DataRetentionBlockBahasa;
