import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectSearchFilter, selectStatusFilter } from 'selectors/patient';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import PatientTableActions from './PatientTableActions';
import PatientTableBody from './PatientTableBody';
import PatientTableBodySmall from './PatientTableBodySmall';
import PatientTableBodyMedium from './PatientTableBodyMedium';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    [theme.breakpoints.only('xs')]: {
      minWidth: 0,
    },
  },
  tableHeadText: {
    fontSize: '0.8em',
    color: theme.palette.primary.main,
  },
  footer: {
    [theme.breakpoints.only('xs')]: {
      left: 'auto',
      bottom: 0,
      zIndex: 2,
      position: 'fixed',
      backgroundColor: '#ffffff',
      width: '100vw',
    },
  },
}));

const TableCellItem = ({ label }) => {
  const styles = useStyles();
  return (
    <TableCell>
      <Typography className={styles.tableHeadText}>{label}</Typography>
    </TableCell>
  );
};

TableCellItem.propTypes = {
  label: PropTypes.string.isRequired,
};

const PatientTable = ({ rows }) => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const searchFilter = useSelector(selectSearchFilter);
  const statusFilter = useSelector(selectStatusFilter);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [searchFilter, statusFilter]);

  return (
    <TableContainer>
      <Table className={styles.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {xs ? null : mdDown ? (
              <React.Fragment>
                <TableCellItem label="PRE-REGISTRATION ID" />
                <TableCellItem label="NAME" />
                <TableCellItem label="E-MAIL / MOBILE NUMBER " />
                <TableCellItem label="STATUS" />
                <TableCellItem label="ACTION" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableCellItem label="PRE-REGISTRATION ID" />
                <TableCellItem label="FIRST NAME" />
                <TableCellItem label="LAST NAME" />
                <TableCellItem label="E-MAIL ADDRESS" />
                <TableCellItem label="MOBILE NUMBER" />
                <TableCellItem label="STATUS" />
                <TableCellItem label="ACTION" />
              </React.Fragment>
            )}
          </TableRow>
        </TableHead>

        {xs ? (
          <PatientTableBodySmall
            rows={rows}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        ) : mdDown ? (
          <PatientTableBodyMedium
            rows={rows}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        ) : (
          <PatientTableBody rows={rows} rowsPerPage={rowsPerPage} page={page} />
        )}

        <TableFooter className={styles.footer}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={PatientTableActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

PatientTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default PatientTable;
