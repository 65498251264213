import React from 'react';
import { Button, Grid, Hidden, Link, Typography } from '@material-ui/core';

import logo from 'assets/png/new-brand-logo.png';
import { makeStyles } from '@material-ui/styles';
import ProgramBlock from 'pages/About/component/ProgramBlock';
import IntroductionBlock from 'pages/About/component/IntroductionBlock';
import { useHistory } from 'react-router';
import PhoneNumberText from 'components/PhoneNumberText';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.accent.main,
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
    },
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      margin: '2em',
      height: '6em',
      width: 'auto',
    },
  },
  introTextContainer: {
    paddingLeft: '10vw',
    paddingRight: '10vw',
    [theme.breakpoints.only('lg')]: {
      paddingLeft: '6vw',
      paddingRight: '6vw',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '3vw',
      paddingRight: '3vw',
    },
  },
  introTextContent: {
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    maxHeight: '30vh',
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  introText: {
    color: 'white',
    lineHeight: 1.4,
    fontWeight: 300,
    fontSize: '0.9rem',
  },
  contactText: {
    color: 'white',
    lineHeight: 1.4,
    fontWeight: 700,
    fontSize: '0.9rem',
    textDecoration: 'none',
  },
  linkContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const IntroBlock = () => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <Grid
      item
      container
      direction="column"
      className={styles.mainContainer}
      justify="center"
      alignItems="center"
      xs={12}
      sm={12}
      md
      lg
      xl
    >
      <Grid item>
        <img src={logo} alt="in-control icon" className={styles.logo} />
      </Grid>
      <Hidden smDown>
        <Grid
          item
          className={styles.introTextContainer}
          style={{ marginTop: '2em' }}
        >
          <Grid
            container
            direction="column"
            className={styles.introTextContent}
          >
            <IntroductionBlock darkTheme />
            <ProgramBlock darkTheme />
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={styles.introTextContainer}
          style={{ marginTop: '2em' }}
        >
          <Grid
            item
            container
            direction="column"
            className={styles.introTextContent}
            spacing={2}
          >
            <Grid item>
              <Button
                onClick={() => {
                  history.push('/program-information');
                }}
                style={{ textTransform: 'none', paddingLeft: 0 }}
              >
                <Typography className={styles.introText}>
                  Questions about the Program? Click{' '}
                  <b>
                    <u>here</u>
                  </b>{' '}
                  for more info
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Typography className={styles.introText}>
                Contact Us for Support:
              </Typography>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography>
                  <Link
                    href="mailto:incontrol.support@mclinica.com"
                    className={styles.contactText}
                  >
                    incontrol.support@mclinica.com
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <PhoneNumberText cssClass={styles.contactText} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};
export default IntroBlock;
