import { toUpper } from 'lodash';

export const getPatientInfo = (patientId, patientList) => {
  const keys = Object.keys(patientList);
  let selectedPatient;
  keys.forEach((index) => {
    if (patientList[index].id === patientId) {
      selectedPatient = patientList[index];
    }
  });
  return selectedPatient;
};

export const getStatus = (status) => {
  switch (status) {
    case 'qualified':
    case 'registered':
    case 'disqualified':
    case 'opted-out':
    case 'blacklisted':
    case 'deactivated':
      return toUpper(status);
    default:
      return 'PENDING APPROVAL';
  }
};

export const isAbleToScreen = (status) => {
  return status !== 'registered' && status !== 'opted-out';
};
