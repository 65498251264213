import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import VerifyImg from 'assets/svg/email-verification.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 'auto',
    width: '25em',
    [theme.breakpoints.only('md')]: {
      width: '20em',
    },
    [theme.breakpoints.down('sm')]: {
      width: '12em',
    },
  },
}));

const VerificationImage = () => {
  const styles = useStyles();
  return (
    <Grid item container alignItems="center" justify="center" md lg xl>
      <img src={VerifyImg} alt="Email Verification" className={styles.logo} />
    </Grid>
  );
};

export default VerificationImage;
