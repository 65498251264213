import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataUsageBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        5. Bagaimana Data Peribadi akan Digunakan
      </Typography>
      <Typography paragraph className={styles.content}>
        Data Peribadi hanya akan digunakan untuk tujuan berikut (secara
        kolektif, &quot;Tujuan&quot;):
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ul>
          <li className={styles.list}>
            untuk mentadbir Program ini, menjaga keselamatan dan kualiti
            Program, termasuk tetapi tidak terhad kepada pemeriksaan pematuhan,
            tinjauan audit dan tujuan perakaunan
          </li>
          <li className={styles.list}>
            untuk membolehkan anda menggunakan semua fungsi, ciri-ciri dan
            faedah Laman web;
          </li>
          <li className={styles.list}>
            untuk menjawab pertanyaan dan aduan anda dan demi menyelesaikan
            sebarang perselisihan;
          </li>
          <li className={styles.list}>
            untuk mematuhi kewajiban undang-undang atau peraturan, memantau
            keselamatan, mengurus kesan buruk, menjalani aktiviti pencegahan dan
            penyiasatan, melaksanakan formaliti pentadbiran, pendaftaran,
            pernyataan atau audit;
          </li>
          <li className={styles.list}>
            untuk menanggapi permintaan undang-undang dari pihak berkuasa
            pentadbiran atau kehakiman, mengikuti undang-undang yang berkenaan;
            mematuhi sepina, pendaftaran yang diperlukan, atau proses
            undang-undang;
          </li>
          <li className={styles.list}>
            untuk memenuhi tujuan yang sah yang berkaitan dengan urusan Sanofi,
            yang mungkin meliputi pelaksanaan proses pengadilan, penyiasatan
            atau penyelesaian perselisihan;
          </li>
          <li className={styles.list}>
            untuk menyusun data statistik mengenai penggunaan Laman web ini.
          </li>
        </ul>
      </Typography>
      <Typography paragraph className={styles.content}>
        Data Peribadi anda tidak akan digunakan untuk tujuan komersial dan
        pemasaran.
      </Typography>
    </Grid>
  );
};

export default DataUsageBlockBahasa;
