import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const RightsAndChoicesBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        8. Hak dan Pilihan Anda
      </Typography>
      <Typography paragraph className={styles.content}>
        Anda berhak menarik balik persetujuan anda berkaitan dengan pemprosesan
        Data Peribadi anda atau mengemukakan permintaan untuk akses atau membuat
        pembetulan atau menghadkan pemprosesan Data Peribadi anda, atau untuk
        membuat pertanyaan atau aduan berkenaan dengan Data Peribadi dengan
        menghubungi mClinica seperti yang dijelaskan di bahagian 12 di bawah.
        Mengenai hak anda untuk mengakses dan / atau membetulkan Data Peribadi
        anda, Sanofi berhak menolak permintaan anda untuk mengakses dan / atau
        membuat pembetulan terhadap Data Peribadi anda atas sebab-sebab yang
        dibenarkan di bawah undang-undang.
      </Typography>
    </Grid>
  );
};

export default RightsAndChoicesBlockBahasa;
