import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPatientScore,
  selectAdheranceLabel,
  selectSubmitPatientEnabled,
  selectPatient,
} from 'selectors/patient';
import { updatePreRegisteredUser } from 'store/patientSlice';
import { Grid, Hidden, Typography, Button, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { screening, links } from 'common/constants';
import Question from './Question';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
  },
  patient: {
    fontSize: '0.8rem',
    color: theme.palette.primary.main,
  },
  adherance: {
    fontSize: '0.85rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  name: {
    fontWeight: 700,
    fontSize: '1.1rem',
  },
  answer: {
    fontWeight: 700,
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  results: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '1em',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '0.15em',
    marginTop: '2em',
  },
  button: {
    textTransform: 'none',
    marginTop: '8em',
    marginBottom: '3em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2em',
    },
  },
  moriskyReference: {
    fontWeight: '500',
    fontSize: '0.9rem',
  },
}));

const ResultsBlock = ({ selectedPatient }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const score = useSelector(selectPatientScore);
  const adherance = useSelector(selectAdheranceLabel);
  const submitEnabled = useSelector(selectSubmitPatientEnabled);
  const patient = useSelector(selectPatient);

  const onSubmitHandler = async () => {
    const payload = {
      ...patient,
      adheranceScore: score,
    };
    delete payload.status;
    dispatch(updatePreRegisteredUser(payload)).then(() => {
      history.goBack();
    });
  };

  return (
    <Fade in>
      <Grid container className={styles.root}>
        <Grid item container direction="row" alignItems="center">
          <Grid item container direction="column" xs sm md lg={5} xl={6}>
            <Typography className={styles.patient}>PATIENT</Typography>
            <Typography className={styles.name}>
              {selectedPatient.firstName} {selectedPatient.lastName}
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item container lg={7} xl={6}>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>
                  Strongly Disagree
                </Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>Disagree</Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>Neutral</Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>Agree</Typography>
              </Grid>
              <Grid item xs sm md lg xl>
                <Typography className={styles.answer}>
                  Strongly Agree
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item container>
          <Typography className={styles.patient} style={{ marginTop: '2em' }}>
            SECTION B: KNOWLEDGE AND BELIEF (CONT.)
          </Typography>

          <Question
            name={screening[6].name}
            number={screening[6].number}
            question={screening[6].question}
          />
          <Question
            name={screening[7].name}
            number={screening[7].number}
            question={screening[7].question}
          />
          <Question
            name={screening[8].name}
            number={screening[8].number}
            question={screening[8].question}
          />

          <Grid
            item
            container
            alignItems="flex-end"
            direction="column"
            className={styles.results}
          >
            <Grid item container lg={7} xl={6}>
              <div className={styles.divider} />
            </Grid>
            <Grid item style={{ marginTop: '2em' }}>
              <Typography variant="subtitle2">
                ASSESSMENT OF PATIENT’S ADHERENCE
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: '2em' }}>
              <Typography variant="h3">
                {score <= 35 ? '< 80%' : '> 80%'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={styles.adherance}>{adherance}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={styles.button}
                type="submit"
                onClick={onSubmitHandler}
                disabled={!submitEnabled}
                style={{
                  color: 'white',
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={styles.moriskyReference}>
            {links.moriskyLink} &nbsp;
            <a
              rel="noreferrer"
              href={links.moriskyUrl}
              target="_blank"
              className={styles.moriskyReference}
            >
              {links.moriskyUrl}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  );
};

ResultsBlock.propTypes = {
  selectedPatient: PropTypes.object.isRequired,
};

export default ResultsBlock;
