import { createSelector } from 'reselect';

const selectResourcesDomain = (state) => state.resources;

export const selectResourcesPending = createSelector(
  selectResourcesDomain,
  (state) => state.pending
);

export const selectConfig = createSelector(
  selectResourcesDomain,
  (state) => state.config
);

export const selectShowMessage = createSelector(
  selectResourcesDomain,
  (state) => state.showMessage
);

export const selectMessage = createSelector(
  selectResourcesDomain,
  (state) => state.message
);

export const selectRegistrationValues = createSelector(
  selectResourcesDomain,
  (state) => state.registrationValues
);
