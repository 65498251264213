import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IntroductionBlock from './component/IntroductionBlock';
import ProgramBlock from './component/ProgramBlock';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5.5em',
      padding: '1.5em',
    },
  },
  title: {
    marginBottom: '1em',
    fontWeight: 700,
  },
}));

const About = () => {
  const styles = useStyles();
  return (
    <Grid container className={styles.root} direction="row">
      <Typography variant="h5" className={styles.title}>
        About In-Control Program
      </Typography>
      <IntroductionBlock />
      <ProgramBlock />
    </Grid>
  );
};

export default About;
