import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataUsageBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        5. How will Personal Data be Used
      </Typography>
      <Typography paragraph className={styles.content}>
        The Personal Data will only be used for the following purposes
        (collectively, the “Purpose”):
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ul>
          <li className={styles.list}>
            to administer this Program and maintain the security and quality of
            the Program, including but not limited to compliance checks, audit
            review and accounting purposes;
          </li>
          <li className={styles.list}>
            to enable you to use all the functionalities, features and benefits
            of the Site;
          </li>
          <li className={styles.list}>
            to respond to your enquiries and complaints and to resolve disputes;
          </li>
          <li className={styles.list}>
            to comply with legal or regulatory obligations, monitor safety,
            manage adverse events, carry out prevention and investigatory
            activities, carry out administrative formalities, registration,
            declarations or audits;
          </li>
          <li className={styles.list}>
            to respond to legal requests from administrative or judicial
            authorities, in accordance with applicable laws; comply with a
            subpoena, required registration, or legal process;
          </li>
          <li className={styles.list}>
            to fulfil legitimate purposes relating to Sanofi&apos;s affairs,
            which may include the conduct of litigation, investigations or
            dispute resolutions;
          </li>
          <li className={styles.list}>
            to compile statistical data on the use of the Site.
          </li>
        </ul>
      </Typography>
      <Typography paragraph className={styles.content}>
        Your Personal Data will not be used for commercial and marketing
        purposes.
      </Typography>
    </Grid>
  );
};

export default DataUsageBlock;
