import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';
import PhoneNumberText from 'components/PhoneNumberText';

const QuestionsBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        12. Contact and Questions
      </Typography>
      <Typography paragraph className={styles.content}>
        In order to exercise the above mentioned rights, or if you have any
        questions about the privacy practices or use or disclosure of your
        Personal Data, please contact mClinica by clicking on the “Contact Us”
        link or contact mClinica at:
      </Typography>
      <Typography paragraph className={styles.content}>
        Email:{' '}
        <Link href="mailto:incontrol.support@mclinica.com">
          incontrol.support@mclinica.com
        </Link>
        , Tel: <PhoneNumberText />, Address: 5
        Shenton Way, UIC Building #10-01 Singapore 068808.
      </Typography>
    </Grid>
  );
};

export default QuestionsBlock;
