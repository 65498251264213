import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataSharingBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        6. Dengan siapa Data Peribadi akan dikongsi
      </Typography>
      <Typography paragraph className={styles.content}>
        Untuk Tujuan yang dinyatakan di atas, Data Peribadi anda boleh
        didedahkan kepada pihak-pihak (yang berada di dalam atau di luar
        Malaysia) seperti yang disenaraikan di bawah, termasuk tetapi tidak
        terhad kepada:
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ul>
          <li className={styles.list}>
            Pembekal, pengedar, penyedia perkhidmatan, ejen atau kontraktor yang
            dilantik oleh Sanofi atau bertindak atas nama Sanofi;
          </li>
          <li className={styles.list}>Penasihat luaran;</li>
          <li className={styles.list}>
            Pengawal selia kerajaan yang berkaitan, lembaga undang-undang atau
            pihak berkuasa atau agensi penguatkuasaan undang-undang atau
            pengawal selia industri atau sesiapa antara kami yang diminta atau
            diwajibkan di bawah undang-undang, peraturan, garis panduan,
            perundangan atau skema yang dikenakan oleh mana-mana pihak berkuasa
            kerajaan;
          </li>
          <li className={styles.list}>
            Mana-mana pihak lain yang anda memberi kebenaran secara bertulis
            kepada kami untuk mendedahkan Data Peribadi anda.
          </li>
          <li className={styles.list}>
            Laman web ini membolehkan anda menerima Data Peribadi Pesakit anda,
            seperti rekod tekanan darah mereka.
          </li>
        </ul>
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        Analisis Pihak Ketiga
      </Typography>
      <Typography paragraph className={styles.content}>
        Semasa Laman web dimuat turun dan digunakan, kami secara automatik dapat
        mengumpul maklumat mengenai penggunaan Laman oleh penggunanya. Sebagai
        contoh, jenis fungsi yang digunakan dan masa yang dihabiskan oleh
        pengguna untuk melayari setiap halaman di Laman. Kami menggunakan
        maklumat ini untuk menganalisis penggunaan Laman dan mengenal pasti
        ruang / peluang untuk pengembangan dan penambahbaikan Laman selanjutnya.
        Alat analitik (pihak ketiga) (seperti Analitis Google, Crashlytics) yang
        kami guna untuk mengumpul dan menganalisis maklumat seperti yang
        tersebut berupaya melakukannya tanpa mengumpul dan memproses maklumat
        yang dapat mengenal pasti identiti pengguna Laman web ini.
      </Typography>
    </Grid>
  );
};

export default DataSharingBlockBahasa;
