import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataAccuracyBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        13. Ketepatan Data
      </Typography>
      <Typography paragraph className={styles.content}>
        Untuk memastikan bahawa Data Peribadi anda adalah terkini, lengkap dan
        tepat, sila kemas kini sebarang pertukaran Data Peribadi anda melalui
        maklumat hubungan yang diberikan.
      </Typography>
    </Grid>
  );
};

export default DataAccuracyBlockBahasa;
