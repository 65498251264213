import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const SecurityBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        10. Security
      </Typography>
      <Typography paragraph className={styles.content}>
        We are concerned about safeguarding your Personal Data against
        unauthorized access, use and loss. We have appropriate administrative,
        technical, and physical measures in place to safeguard your Personal
        Data.
      </Typography>
      <Typography paragraph className={styles.content}>
        Certain Personal Data is stored on your Device only and keeping that
        information secure is your responsibility. Please consult your device’s
        documentation on how to manage local storage and how to apply
        appropriate security controls to the device for the protection of such
        information. We urge you to use caution when storing information in the
        Site or transmitting information over the Internet, especially
        information related to your health. Please keep your login details
        confidential. Please be aware that, although we endeavor to provide
        reasonable security as part of the functioning of the Site, no security
        system can prevent all potential security breaches.
      </Typography>
    </Grid>
  );
};

export default SecurityBlock;
