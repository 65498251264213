import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const RevisionNoticeBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        14. Revision of Notice
      </Typography>
      <Typography paragraph className={styles.content}>
        This Notice was last updated in March 19, 2021. This Notice may be
        revised or amended from time to time without any prior notice. The
        revised Notice will be available on this Site. You may determine if any
        such revision has taken place by referring to the date on which this
        Notice was last updated. We invite you to check this Notice
        periodically. By continuing to participate in the Program, by continuing
        to use the Site and/or providing your Personal Data to or allowing
        collection of your Personal Data under the Program, following the
        revisions to this Notice, this shall signify your acceptance of such
        revisions.
      </Typography>
    </Grid>
  );
};

export default RevisionNoticeBlock;
