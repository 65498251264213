import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataTransferBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        7. Pemindahan Data Peribadi
      </Typography>
      <Typography paragraph className={styles.content}>
        Sejauh yang diperlukan untuk Tujuan yang berkenaan, Data Peribadi boleh
        diungkapkan kepada pihak-pihak yang disenaraikan pada bahagian 6, yang
        mungkin berada di dalam atau di luar Malaysia. Data peribadi anda akan
        dikendalikan sebagai maklumat sulit oleh Sanofi dan pekerja, ejen dan
        pihak ketiga kami yang sah pada setiap masa. Dengan mengakses Laman web
        ini, membekalkan Data Peribadi anda dan / atau membenarkan pengumpulan
        dan pemprosesan Data Peribadi anda di bawah Program ini, anda bersetuju
        untuk memindahkan Data Peribadi anda ke luar Malaysia seperti yang
        dijelaskan di sini.
      </Typography>
    </Grid>
  );
};

export default DataTransferBlockBahasa;
