import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';
import { useDarkThemeStyles } from 'components/StaticPage/stylesDarkTheme';

const IntroductionBlock = ({ darkTheme }) => {
  const styles = useStyles();
  const stylesDarkTheme = useDarkThemeStyles();
  return (
    <Grid container direction="column">
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.title : styles.title}
      >
        INTRODUCTION
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        This Program is sponsored by Sanofi-Aventis (Malaysia) Sdn. Bhd for only
        patients in Malaysia who are prescribed to use Aprovasc
        (Irbesartan/amlodipine) (150 mg/5 mg,150/10mg, 300 mg/5 mg and 300 mg/10
        mg) by their treating physician.
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        The purpose of this Patient Access Patient Program is to drive change in
        patient adherence behavior amongst uncontrolled hypertension patients
        and simultaneously improve blood pressure (BP) control in the form of
        patient support consisting of BP recording calendar and educational
        materials and patient assistance in the form of (i) home blood pressure
        monitoring device (ii) complimentary (free) Sanofi product, i.e.
        Aprovasc (Irbesartan/amlodipine) (150 mg/5 mg,150 mg/10 mg, 300 mg/5 mg
        and 300 mg/10 mg) upon completion of purchase of three (3) boxes of the
        said product.
      </Typography>
    </Grid>
  );
};

IntroductionBlock.defaultProps = {
  darkTheme: false,
};

IntroductionBlock.propTypes = {
  darkTheme: PropTypes.bool,
};

export default IntroductionBlock;
