import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataRetentionBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        9. Data Retention
      </Typography>
      <Typography paragraph className={styles.content}>
        We will store the Personal Data in a database of this Program maintained
        by or for on behalf of Sanofi. Any of the Personal Data is retained for
        as long as the purposes for which the Personal Data was collected
        continues. the Personal Data is then destroyed, anonymized or returned
        to you (if reasonably practicable), in the event the Personal Data is no
        longer required for the said Purpose unless its further retention is
        required to satisfy a longer retention period to meet operational,
        legal, regulatory, tax or accounting requirements.
      </Typography>
    </Grid>
  );
};

export default DataRetentionBlock;
