import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectProfile } from 'selectors/user';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { drawerWidth } from 'components/HomeDrawer';

import logo from 'assets/png/new-brand-logo.png';
import accountIcon from 'assets/svg/ic-tab-account.svg';
import aboutIcon from 'assets/svg/ic-tab-about.svg';
import privacyPolicyIcon from 'assets/svg/ic-tab-privacy-policy.svg';
import settingsIcon from 'assets/svg/ic-tab-settings.svg';
import termsAndConditionIcon from 'assets/svg/ic-tab-terms-and-conditions.svg';
import userGuideIcon from 'assets/svg/ic-tab-user-guide.svg';
import physicianConsentIcon from 'assets/svg/ic-tab-physician-consent.svg';
import PhoneNumberText from 'components/PhoneNumberText';
import StyledTabLabel from './StyledTabLabel';
import LogoutButton from './LogoutButton';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#AB4494',
  },
  container: {
    background: '#AB4494',
    height: '100%',
    width: '100%',
    marginTop: '2em',
  },
  tabContainer: {
    backgroundColor: '#AB4494',
    height: '100vh',
    flexGrow: 1,
    display: 'flex',
    width: drawerWidth,
  },
  logo: {
    height: '12em',
    width: 'auto',
    marginTop: '2em',
    marginBottom: '1.5em',
    [theme.breakpoints.only('md')]: {
      height: '11em',
      width: 'auto',
      marginTop: '1.8em',
      marginBottom: '1.4em',
    },
    [theme.breakpoints.only('sm')]: {
      height: '10em',
      width: 'auto',
      marginTop: '1em',
      marginBottom: '1em',
    },
    [theme.breakpoints.only('xs')]: {
      height: '7em',
      width: 'auto',
      marginTop: '2em',
      marginBottom: '2em',
    },
  },
  text: {
    color: 'white',
    textTransform: 'none',
    marginLeft: '2em',
    marginRight: '2em',
    fontSize: '1rem',
    fontWeight: '300',
    wordBreak: 'break-all',
    textDecoration: 'none',
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem',
      marginLeft: '2.5em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      marginLeft: '3em',
    },
  },
  logout: {
    color: 'white',
    fontWeight: 700,
    textTransform: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginLeft: '2em',
    paddingLeft: '0.2em',
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem',
      marginLeft: '2.3em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      marginLeft: '2.5em',
    },
  },
  divider: {
    backgroundColor: '#FFFFFF',
    width: 'auto',
    height: '0.15em',
    marginLeft: '2em',
    marginRight: '2em',
    marginTop: '1em',
    marginBottom: '1em',
  },
  linkContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const DrawerContent = ({ setMobileOpen, value, setValue }) => {
  const history = useHistory();
  const styles = useStyles();
  const profile = useSelector(selectProfile);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid item container justify="center">
        <img src={logo} alt="in-control icon" className={styles.logo} />
      </Grid>
      <Grid item>
        <Typography className={styles.text}>{profile.fullName}</Typography>
        <Typography className={styles.text}>
          {profile?.metadata?.hospitalName}
        </Typography>
        <Typography className={styles.text}>{profile.email}</Typography>
      </Grid>
      <Grid item container className={styles.container}>
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#FFFFFF45',
              width: '100%',
              marginRight: `calc(${drawerWidth}px - 8px)`,
            },
          }}
        >
          <Tab
            label={
              <StyledTabLabel label="Enrolled Patient" icon={accountIcon} />
            }
            {...a11yProps(0)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/home');
            }}
            disableRipple
          />
          <Tab
            label={<StyledTabLabel label="About" icon={aboutIcon} />}
            {...a11yProps(1)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/about');
            }}
            disableRipple
          />
          <Tab
            label={<StyledTabLabel label="User Guide" icon={userGuideIcon} />}
            {...a11yProps(2)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/user-guide');
            }}
            disableRipple
          />
          <Tab
            label={
              <StyledTabLabel
                label="Terms of Use"
                icon={termsAndConditionIcon}
              />
            }
            {...a11yProps(3)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/terms-and-conditions');
            }}
            disableRipple
          />
          <Tab
            label={
              <StyledTabLabel
                label="Personal Data Protection Notice"
                icon={privacyPolicyIcon}
              />
            }
            {...a11yProps(4)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/privacy-policy');
            }}
            disableRipple
          />
          <Tab
            label={
              <StyledTabLabel
                label="Consent Form"
                icon={physicianConsentIcon}
              />
            }
            {...a11yProps(3)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/physician-consent-form');
            }}
            disableRipple
          />
          <Tab
            label={<StyledTabLabel label="Settings" icon={settingsIcon} />}
            {...a11yProps(4)}
            onClick={() => {
              setMobileOpen(false);
              history.push('/settings');
            }}
            disableRipple
          />
        </Tabs>
      </Grid>
      <Grid item>
        <div className={styles.divider} />
      </Grid>
      <Grid item>
        <Typography className={styles.text}>
          <b> Contact Us for Support:</b>
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Button className={styles.linkContainer} disableRipple>
          <Typography>
            <Link
              href="mailto:incontrol.support@mclinica.com"
              className={styles.text}
            >
              incontrol.support@mclinica.com
            </Link>
          </Typography>
        </Button>
        <Button className={styles.linkContainer} disableRipple>
          <Typography>
            <PhoneNumberText cssClass={styles.text} />
          </Typography>
        </Button>
      </Grid>
      <Grid item style={{ paddingBottom: '2em' }}>
        <LogoutButton
          extraHandler={() => {
            setMobileOpen(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

DrawerContent.propTypes = {
  setMobileOpen: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default DrawerContent;
