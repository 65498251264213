import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const DataAccuracyBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        13. Accuracy of Data
      </Typography>
      <Typography paragraph className={styles.content}>
        In order to ensure that your Personal Data is current, complete and
        accurate, please update changes to your Personal Data at the contact
        details provided.
      </Typography>
    </Grid>
  );
};

export default DataAccuracyBlock;
