import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const Content = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.subtitle}>
        1. How do I add a patient?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to &apos;Enrolled Patients&apos; screen, and click the &apos;Add
        Patient&apos; button on the top right side. Input the patient&apos;s
        first name, last name, email address and mobile number and click the
        &apos;Add&apos; button. The newly registered patient should now appear
        as part of the eligible patients
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        2. How do I search for a patient?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to &apos;Enrolled Patients&apos; screen and type in the name, mobile
        number and/or email address of the patient. Patients with the matching
        name, mobile number and/or email address would appear in the results
        list.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        3. How do I screen a patient?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to &apos;Enrolled Patients&apos; screen. For patients that have
        &apos;Pending Approval&apos; status, click the &apos;Screen&apos;
        button. You should be directed to the patient screening page for that
        particular patient where you can answer the questions respectively to
        the patient being screened.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        4. What if I made a mistake, and want to re-screen a patient?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to &apos;Enrolled Patients&apos; screen. After the initial screening,
        you should be able to see the re-screen/refresh icon right beside the
        patient details. Click the re-screen icon for the patient that you want
        to re-screen, and you should be directed to the patient screening page.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        5. How do I edit a patient&apos;s details?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to &apos;Enrolled Patients&apos; screen. If in case after the initial
        screening, you want to change the patient details (i.e. mobile number,
        email address, etc.), then you can click the pencil/edit icon right
        beside each patient details. Click the edit icon, and there should be a
        modal showing existing patient details. Edit the specific details that
        you want to change, and click &apos;Save.&apos; The patient details
        should now be updated.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        6. How do I remove a patient?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to &apos;Enrolled Patients&apos; screen. If in case after the initial
        screening, you want to remove the patient, then click the pencil/edit
        icon right beside each patient details. Click the edit icon, and there
        should be a modal showing existing patient details. Click
        &apos;Remove&apos; to delete the patient. Patient should now be removed
        from the list.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        7. Can my patients register even without me registering them to the
        program?
      </Typography>
      <Typography paragraph className={styles.content}>
        No. Only patients who have been pre-enrolled by you and in qualified
        status can enroll into the program.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        8. Which screening method do I need to use for screening the patients?
      </Typography>
      <Typography paragraph className={styles.content}>
        In-Control currently uses the H-MAI-9 questionnaire for evaluating
        patient&apos;s adherence in taking their high blood pressure medication.
        Since In-Control is a program designed to help with patient adherence,
        patients that are low adherence (9-35 (calculated score &lt;80%) ) are
        qualified to be part of the program. Patients with high adherance score
        (36-45 (calculated &gt;80%) ) are disqualified to be part of the
        program.
      </Typography>
      <Typography paragraph className={styles.content}>
        See reference: Goh SSL, Lai PSM, Liew S-M, Tan KM, Chung WW, Chua SS
        (2020) Development of a PATIENT-Medication Adherence Instrument (PMAI)
        and a HEALTHCARE PROFESSIONAL Medication Adherence Instrument (H-MAI-9)
        using the nominal group technique. PLoS ONE 15(11): e0242051.
        <Link
          href="https://doi.org/10.1371/journal.pone.0242051"
          target="_blank"
          rel="noopener"
        >
          https://doi.org/10.1371/journal.pone.0242051
        </Link>
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        9. How do I change my password?
      </Typography>
      <Typography paragraph className={styles.content}>
        Go to the sidebar and click &apos;Settings.&apos; Input old password to
        replace with the new password.
      </Typography>
      <Typography paragraph className={styles.subtitle}>
        10. How do I opt-out of the program?
      </Typography>
      <Typography paragraph className={styles.content}>
        Please contact our customer support either via phone or email to
        indicate your interest to opt-out of the program.
      </Typography>
    </Grid>
  );
};

export default Content;
