import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/authSlice';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logout: {
    color: 'white',
    fontWeight: 700,
    textTransform: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginLeft: '2em',
    paddingLeft: '0.2em',
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem',
      marginLeft: '2.3em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      marginLeft: '2.5em',
    },
  },
}));

const LogoutButton = ({ extraHandler }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    extraHandler(false);
    setOpen(false);
    dispatch(logout());
  };

  return (
    <React.Fragment>
      <Button
        variant="text"
        disableRipple
        onClick={handleOpen}
        className={styles.logout}
      >
        Logout
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">In-Control</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleLogout} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

LogoutButton.defaultProps = {
  extraHandler: () => {},
};

LogoutButton.propTypes = {
  extraHandler: PropTypes.func,
};

export default LogoutButton;
