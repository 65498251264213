import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectShowMessage } from 'selectors/resources';
import IntroBlock from './components/IntroBlock';
import RegistrationBlock from './components/RegistrationBlock';
import AlertDialog from '../../components/AlertDialog';

const Login = () => {
  const showMessage = useSelector(selectShowMessage);
  return (
    <React.Fragment>
      <Grid container direction="row">
        <IntroBlock />
        <RegistrationBlock />
      </Grid>
      <AlertDialog open={showMessage} />
    </React.Fragment>
  );
};
export default Login;
