import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { ErrorMessage } from 'formik';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, styled } from '@material-ui/styles';

const ModifiedInputBase = styled(InputBase)(({ theme }) => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    border: '1px solid #ced4da',
    borderRight: 'none',
    fontSize: 16,
    padding: 18,
    minHeight: 'inherit',
    height: 18,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    fontSize: 14,
  },
  helperText: {
    marginTop: 5,
    marginLeft: 5,
  },
  outlinedInput: {
    borderRadius: 0,
  },
  textInput: {
    borderRadius: 0,
  },
  select: {
    width: '100%',
    borderColor: theme.palette.primary.main,
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  fieldContainer: {},
  inputLabel: {
    '&.focused': {
      color: theme.palette.primary.main,
    },
    '&.shrink': {
      transform: 'translate(-60px, 1.5px) scale(0.75)',
      [theme.breakpoints.only('sm')]: {
        transform: 'translate(-80px, 1.5px) scale(0.75)',
      },
      [theme.breakpoints.only('xs')]: {
        transform: 'translate(-110px, 1.5px) scale(0.75)',
      },
    },
  },
  notchedOutline: {
    borderColor: theme.palette.primary.main,
  },
  selectBox: {
    borderColor: theme.palette.border.main,
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
}));

const ConnectMobileNumberField = ({ fieldOptions, setFieldValue, field }) => {
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [characterLimit, setCharacterLimit] = useState(0);
  const style = useStyles();

  const {
    name,
    label,
    hint,
    placeholder,
    isRequired,
    fieldTypeOptions,
    initialValue,
  } = fieldOptions;

  const onChangeDropdown = (data) => {
    const prefix = get(fieldTypeOptions, 'mobilePrefixes', []).find(
      (mobilePrefixes) => mobilePrefixes.callingCode === data.target.value
    );
    setCharacterLimit(prefix.characterLimit);
    setCountryCode(data.target.value);
    setFieldValue(
      field.name,
      isEmpty(phoneNumber) ? '' : `${data.target.value}${phoneNumber}`
    );
  };

  const onChangeField = (data) => {
    const value = data.target.value
      .replace(/\D/, '')
      .substring(0, characterLimit)
      .trimStart();
    setPhoneNumber(value);
    setFieldValue(field.name, isEmpty(value) ? '' : `${countryCode}${value}`);
  };

  useEffect(() => {
    const prefix = get(fieldTypeOptions, 'mobilePrefixes', [])[0];
    setCharacterLimit(prefix.characterLimit);
    if (initialValue) {
      setCountryCode(`+${initialValue.substring(0, 2)}`);
      setPhoneNumber(initialValue.substring(2));
      setFieldValue(field.name, `+${initialValue}`);
    } else {
      setCountryCode(prefix.callingCode);
    }
  }, [fieldTypeOptions, initialValue, field.name, setFieldValue]);

  useEffect(() => {
    if (phoneNumber) {
      const newValue = phoneNumber.substring(0, characterLimit);
      setPhoneNumber(newValue);
    }
  }, [characterLimit, phoneNumber]);

  return (
    <Grid
      container
      key={name}
      spacing={0}
      className={style.fieldContainer}
      alignItems="flex-end"
    >
      <Grid
        item
        className={style.selectContainer}
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
      >
        <Box borderBottom={1} className={style.selectBox}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id={`${name}_dropdown`}
            value={countryCode}
            onChange={onChangeDropdown}
            input={<ModifiedInputBase />}
            style={{
              root: style.select,
            }}
            data-testid="connectkit-mobilenumber-dropdown"
          >
            {get(fieldTypeOptions, 'mobilePrefixes', []).map((prefix) => {
              return (
                <MenuItem key={prefix.isoCode} value={prefix.callingCode}>
                  {prefix.callingCode}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Grid>
      <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
        <TextField
          type="text"
          label={
            label ? `${label}${isRequired ? ' *' : '(optional)'}` : placeholder
          }
          value={field.value ? phoneNumber : ''}
          onChange={onChangeField}
          onBlur={field.onBlur}
          name={field.name}
          color="primary"
          onInput={
            get(fieldTypeOptions, 'shouldUppercase', false)
              ? (e) => {
                  const start = e.target.selectionStart;
                  const end = e.target.selectionEnd;
                  e.target.value = e.target.value.toUpperCase();
                  e.target.setSelectionRange(start, end);
                }
              : null
          }
          InputProps={{
            style: { notchedOutline: style.notchedOutline },
            autoComplete: 'off',
          }}
          InputLabelProps={{
            classes: {
              root: style.inputLabel,
              focused: 'focused',
              shrink: 'shrink',
            },
          }}
          fullWidth
          data-testid="connectkit-mobilenumberfield"
        />
      </Grid>
      {hint && (
        <React.Fragment>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="primary"
          >
            {hint}
          </Typography>
          <br />
        </React.Fragment>
      )}
      <ErrorMessage
        name={field.name}
        render={(message) => {
          return (
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              color="primary"
            >
              {message}
            </Typography>
          );
        }}
      />
    </Grid>
  );
};

ConnectMobileNumberField.propTypes = {
  fieldOptions: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    fieldTypeOptions: PropTypes.object,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

export default ConnectMobileNumberField;
