import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, fade } from '@material-ui/core';
import { setStatusFilter } from 'store/patientSlice';
import { selectStatusFilter } from 'selectors/patient';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 'min-content',
    minWidth: 180,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'min-content',
    height: 40,
    backgroundColor: 'transparent',
    marginLeft: '1em',
    [theme.breakpoints.down('md')]: {
      height: 32,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: '0.5em',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  whiteInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: 'white',
  },
  placeholderTextWhite: {
    color: 'white',
    opacity: 0.4,
  },
  placeholderText: {
    color: 'black',
    opacity: 0.4,
  },
  icon: {
    fill: 'white',
  },
}));

const names = [
  'Qualified',
  'Disqualified',
  'Pending Approval',
  'Registered',
  'Opted-Out',
  'Blacklisted',
  'Deactivated',
];

const StatusFilter = ({ noElevation, icon }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const statusFilters = useSelector(selectStatusFilter);

  const handleChange = (event) => {
    dispatch(setStatusFilter(event.target.value));
  };

  useEffect(() => {
    setStatusFilter(statusFilters);
  }, [statusFilters]);

  return (
    <Paper className={styles.paper} elevation={noElevation ? 0 : 1}>
      <Grid item container direction="row">
        <FormControl className={styles.formControl}>
          <Select
            multiple
            displayEmpty
            value={statusFilters}
            onChange={handleChange}
            input={
              <Input
                className={noElevation ? styles.whiteInput : styles.input}
              />
            }
            inputProps={{
              classes: {
                icon: noElevation ? styles.icon : 'inherit',
              },
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    className={
                      noElevation
                        ? styles.placeholderTextWhite
                        : styles.placeholderText
                    }
                  >
                    {icon ? 'Status' : 'Patient Status'}
                  </Typography>
                );
              }
              return selected.join(', ');
            }}
            disableUnderline
          >
            {names.map((name, i) => (
              <MenuItem key={i} value={name}>
                <Checkbox checked={statusFilters.includes(name)} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Paper>
  );
};

StatusFilter.defaultProps = {
  noElevation: false,
  icon: false,
};

StatusFilter.propTypes = {
  noElevation: PropTypes.bool,
  icon: PropTypes.bool,
};

export default StatusFilter;
