import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPreRegistrationField,
  selectPatientFieldValidationSchema as schema,
} from 'selectors/userRegistration';
import { selectSelectedPatient, selectPatientPending } from 'selectors/patient';
import {
  preRegisterUser,
  updatePreRegisteredUser,
  deletePreRegisteredUser,
} from 'store/patientSlice';
import { Field, Form, Formik } from 'formik';
import { ConnectMobileNumberField, ConnectTextField } from 'components/Fields';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Loader from 'components/Loader';
import AddPatientQuestion from './AddPatientQuestion';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    marginLeft: '1em',
    marginRight: '1em',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  actionsContainer: {
    paddingLeft: '1em',
    paddingRight: '1em',
    marginBottom: '1em',
    marginTop: '3em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: '2em',
    },
  },
  cancel: {
    marginRight: '1em',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    paddingLeft: '2em',
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  save: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    paddingLeft: '2em',
    paddingRight: '2em',
  },
}));

const fieldType = (field, type) => ({
  inputType: type,
  component:
    type === 'text_input' ? ConnectTextField : ConnectMobileNumberField,
  mobilePrefixes:
    field.field_type_options && field.field_type_options.mobile_prefixes,
});

const questions = [
  {
    fieldName: 'has_bp_device',
    question: 'Does the patient have his/her own bp device?',
  },
];

const AddPatientForm = ({ handleClose, editMode }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const selectedPatient = useSelector(selectSelectedPatient);
  const firstNameField = useSelector(selectPreRegistrationField('first_name'));
  const lastNameField = useSelector(selectPreRegistrationField('last_name'));
  const emailField = useSelector(selectPreRegistrationField('email'));
  const mobileField = useSelector(selectPreRegistrationField('mobile_number'));
  const validationSchema = useSelector(schema);
  const patientPending = useSelector(selectPatientPending);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});

  const onSubmitHandler = (values, actions) => {
    actions.setSubmitting(true);
    if (!editMode) {
      setPatientInfo({
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        mobileNumber: values.mobile_number.substring(1),
      });
      dispatch(preRegisterUser(values)).then((data) => {
        actions.setSubmitting(false);
        if (!data.payload) {
          return;
        }
        handleClose(true);
      });
    } else {
      const payload = {
        ...selectedPatient,
        firstName: values.first_name,
        lastName: values.last_name,
        mobileNumber: values.mobile_number,
      };
      dispatch(updatePreRegisteredUser(payload)).then((data) => {
        actions.setSubmitting(false);
        if (!data.payload) {
          return;
        }
        handleClose(true);
      });
    }
  };

  const onDeleteHandler = () => {
    handleClose(true);
    dispatch(deletePreRegisteredUser(selectedPatient.id));
  };

  return (
    <div>
      <Formik
        initialValues={patientInfo}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, isValid, dirty, isSubmitting }) => {
          let disableSubmitButton = !dirty || !isValid || isSubmitting;
          if (editMode) {
            disableSubmitButton = false;
          }

          return (
            <Form>
              <Grid container>
                <Grid item container>
                  <Grid
                    item
                    className={styles.inputContainer}
                    xs={12}
                    sm={12}
                    md
                    lg
                    xl
                  >
                    <Field
                      key={firstNameField.name}
                      name={firstNameField.name}
                      fieldOptions={{
                        ...firstNameField,
                        initialValue: editMode
                          ? selectedPatient.firstName
                          : patientInfo.firstName,
                      }}
                      {...fieldType(firstNameField, 'text_input')}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    item
                    className={styles.inputContainer}
                    xs={12}
                    sm={12}
                    md
                    lg
                    xl
                  >
                    <Field
                      key={lastNameField.name}
                      name={lastNameField.name}
                      fieldOptions={{
                        ...lastNameField,
                        initialValue: editMode
                          ? selectedPatient.lastName
                          : patientInfo.lastName,
                      }}
                      {...fieldType(lastNameField, 'text_input')}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid
                    item
                    className={styles.inputContainer}
                    xs={12}
                    sm={12}
                    md
                    lg
                    xl
                  >
                    <Field
                      key={emailField.name}
                      name={emailField.name}
                      fieldOptions={{
                        ...emailField,
                        disabled: editMode,
                        initialValue: editMode
                          ? selectedPatient.email
                          : patientInfo.email,
                      }}
                      {...fieldType(emailField, 'text_input')}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    item
                    className={styles.inputContainer}
                    xs={12}
                    sm={12}
                    md
                    lg
                    xl
                  >
                    <Field
                      key={mobileField.name}
                      name={mobileField.name}
                      fieldOptions={{
                        ...mobileField,
                        initialValue: editMode
                          ? selectedPatient.mobileNumber
                          : patientInfo.mobileNumber,
                      }}
                      {...fieldType(mobileField, 'mobile_number_input')}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
                {editMode ? null : (
                  <Grid
                    container
                    direction="column"
                    className={styles.inputContainer}
                  >
                    <Field
                      key={questions[0].name}
                      fieldName={questions[0].fieldName}
                      question={questions[0].question}
                      component={AddPatientQuestion}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                direction="row"
                className={styles.actionsContainer}
              >
                {editMode ? (
                  <Grid item container xs={4} sm={4} md lg xl>
                    <Button
                      onClick={() => {
                        setShowConfirmation(true);
                      }}
                      variant="contained"
                      color="primary"
                      style={{ marginRight: '1em' }}
                    >
                      Delete
                    </Button>
                  </Grid>
                ) : null}

                <Grid item container xs sm md lg xl justify="flex-end">
                  <Button
                    onClick={handleClose}
                    color="primary"
                    className={styles.cancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={disableSubmitButton}
                    className={styles.save}
                    style={{
                      color: 'white',
                    }}
                  >
                    {editMode ? 'SAVE' : 'ADD'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog open={showConfirmation}>
        <DialogTitle>{'In-Control'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete patient?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)}>No</Button>
          <Button onClick={onDeleteHandler} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={patientPending} />
    </div>
  );
};

AddPatientForm.defaultProps = {
  editMode: false,
};

AddPatientForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};

export default AddPatientForm;
