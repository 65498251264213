import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectShowMessage } from 'selectors/resources';
import { selectProfilePending } from 'selectors/user';
import Loader from 'components/Loader';
import AlertDialog from 'components/AlertDialog';

const PageLayout = ({ children, isLoaderOpen }) => {
  const showMessage = useSelector(selectShowMessage);
  const profilePending = useSelector(selectProfilePending);

  // Insert global style here
  return (
    <React.Fragment>
      {children}
      <Loader open={isLoaderOpen || profilePending} />
      <AlertDialog open={showMessage} />
    </React.Fragment>
  );
};

PageLayout.propTypes = {
  isLoaderOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]).isRequired,
};

PageLayout.defaultProps = {
  isLoaderOpen: false,
};

export default PageLayout;
