import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const IntroductionBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        NOTIS PERLINDUNGAN DATA PERIBADI UNTUK PENJAGAAN KESIHATAN (“NOTIS”)
      </Typography>
      <Typography paragraph className={styles.title}>
        1. Pengenalan
      </Typography>
      <Typography paragraph className={styles.content}>
        Papan pemuka program pesakit In-Control untuk pakar perubatan
        (&quot;HCP&quot;) yang dapat diakses melalui halaman web isi alamat
        halaman web (&quot;Laman&quot;) disediakan untuk pakar perubatan
        sehubungan dengan Program Akses Pesakit In-Control (&quot;Program&quot;)
        yang ditaja oleh Sanofi-Aventis ( Malaysia) Sdn Bhd (&quot;Sanofi&quot;,
        atau &quot;kami&quot;) dan ditadbir bagi pihak kami oleh penyedia
        perkhidmatan kami yang sah, mClinica Pte. Ltd. (&quot;mClinica&quot;).
      </Typography>
      <Typography paragraph className={styles.content}>
        Notis ini adalah untuk memberitahu anda bagaimana dan mengapa Data
        Peribadi anda (seperti yang disebut di bawah) dikumpul, digunakan,
        dikekalkan, didedah, dipindah dan ditangani (secara kolektif
        &quot;Proses&quot;) selama penyertaan anda dalam Program ini melalui
        Laman seperti yang dikehendaki oleh Akta Perlindungan Data Peribadi 2010
        Malaysia dan semua undang-undang dan peraturan yang berkaitan dengan
        perlindungan Data Peribadi anda (&quot;Undang-undang yang
        Berkenaan&quot;) dan Notis ini. Notis ini digabungkan dengan, dan
        tertakluk pada, Syarat Penggunaan. &quot;Data Peribadi&quot; bermaksud
        segala informasi tentang seorang individu yang membolehkan
        pengenalpastian individu tersebut, secara langsung atau tidak langsung,
        seperti yang ditentukan oleh Undang-Undang yang Berkenaan.
      </Typography>
      <Typography paragraph className={styles.content}>
        Dengan mengklik butang di bawah bertanda &quot;[Saya setuju]&quot;, anda
        mengesahkan (i) bahawa anda berumur sekurang-kurangnya 18 tahun dan
        telah mencapai usia majoriti berdasarkan semua undang-undang yang
        berkaitan di negara anda, dan (ii) bahawa anda telah membaca, memahami
        dan bersetuju dengan terma dan syarat seperti yang dinyatakan dalam
        Notis ini dan anda bersetuju dengan Pemprosesan Data Peribadi anda yang
        telah atau akan diterima atau dikumpul dengan cara dan untuk tujuan yang
        dijelaskan dalam Notis ini. Sekiranya anda tidak bersetuju dengan
        sebarang peruntukan Notis ini, jangan gunakan Laman web ini dan padamkan
        ia dari peranti dimana anda memuat turunnya.
      </Typography>
      <Typography paragraph className={styles.content}>
        Dengan mengakses dan / atau mendaftar untuk mengakses Aplikasi ini,
        membekalkan Data Peribadi anda dan / atau membenarkan pengumpulan Data
        Peribadi anda untuk Program Pesakit, termasuk melalui Laman web ini,
        anda mengesahkan bahawa (i) mewakili dan menjamin kepada Sanofi dan
        bersetuju bahawa anda (i) sama ada (a) pakar perubatan / penjagaan
        kesihatan yang berkelayakan dan berlesen di Malaysia (&quot;Pakar /
        penjagaan kesihatan&quot; atau &quot;HCP&quot;) atau (b) dibenarkan oleh
        Sanofi untuk menggunakan Laman web ini, dan anda (ii) akan menggunakan
        Laman ini hanya digunakan mengikuti prinsip profesional, etika,
        peraturan, statutori dan undang-undang lain; dan anda mengesahkan bahawa
        anda telah membaca, memahami dan bersetuju dengan terma dan syarat
        seperti yang dinyatakan di sini dan anda bersetuju dengan Pemprosesan
        Data Peribadi anda yang telah atau akan diterima atau dikumpul oleh
        Sanofi dengan cara dan untuk tujuan yang dijelaskan dalam ini Notis.
        Sekiranya anda melakukannya atas nama sebuah entiti undang-undang, anda
        menyatakan dan memberi jaminan bahawa anda mempunyai kuasa untuk
        menerima Notis ini bagi pihak entiti undang-undang tersebut, dan anda
        menyatakan dan memberi jaminan bahawa semua pengguna Laman web di entiti
        undang-undang tersebut harus mematuhi terma dan syarat Notis ini.
      </Typography>
      <Typography paragraph className={styles.content}>
        Sekiranya terdapat sebarang konflik, pencanggahan atau kekeliruan antara
        versi Bahasa Inggeris and Bahasa Malaysia di dalam Notis ini, dan
        penterjemahan bahasa lain, versi Bahasa Inggeris akan diguna pakai.
        Kecuali ditentukan lain, definisi dalam Syarat Penggunaan akan berlaku
        untuk Notis ini. Kecuali ditentukan lain di sini, istilah yang
        menggunakan huruf besar dalam Notis ini mempunyai makna yang sama
        seperti yang ditentukan dalam Syarat Penggunaan.
      </Typography>
    </Grid>
  );
};

export default IntroductionBlockBahasa;
