import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.accent.main,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: '12em',
  },
}))(Tooltip);

export default LightTooltip;
