import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { links } from 'common/constants';
import PhoneNumberText from 'components/PhoneNumberText';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '10em',
    marginRight: '10em',
    [theme.breakpoints.only('md')]: {
      marginLeft: '6em',
      marginRight: '6em',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1.5em',
      marginRight: '1.5em',
    },
  },
  boldFont: {
    fontWeight: 600,
  },
}));

const Content = ({ isLoggedIn }) => {
  const styles = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={isLoggedIn ? undefined : styles.root}
      spacing={4}
    >
      <Grid item>
        <Typography variant="body2" paragraph>
          Last updated on April 13, 2021
        </Typography>
        <Typography variant="h6">Terms of Use</Typography>
      </Grid>
      <Grid item>
        <Box mb={2}>
          <Typography variant="body2" className={styles.boldFont}>
            1. Introduction
          </Typography>
        </Box>

        <Typography variant="body2" paragraph>
          The In-Control Patient Access Program (“Program”) is sponsored by
          Sanofi-Aventis (Malaysia) Sdn Bhd (“Sanofi”, “we” “our” or “us”) and
          administered on its behalf by its authorized service provider,
          mClinica Pte. Ltd. (“mClinica”).
        </Typography>

        <Typography variant="body2" paragraph>
          This document describes the terms and conditions (“Terms of Use”) that
          govern your access or use of the following in connection with your
          participation in the Program:
        </Typography>

        <Typography variant="body2" paragraph>
          1.1.1 the healthcare professional (“HCP”) In-Control patient program
          dashboard accessible through the website{' '}
          <a href={links.appProdUrl} target="_blank" rel="noreferrer">
            {links.appProdUrl}
          </a>{' '}
          (the “Site”);
        </Typography>

        <Typography variant="body2" paragraph>
          1.1.2 the In-Control patient program mobile application software (the
          “Application”) (made available on different application marketplaces
          (“App Store”), which you can download a copy of to your mobile device
          (“Device”); and/or
        </Typography>

        <Typography variant="body2" paragraph>
          1.1.3 any other services associated with the Site and/or Application
          in connection with the Program,
        </Typography>

        <Typography variant="body2" paragraph>
          (hereinafter collectively and individually referred to as “Platform”).
        </Typography>

        <Typography variant="body2" paragraph>
          These Terms of Use incorporate by reference the following additional
          terms (which also apply to your use of the Platform):
        </Typography>

        <Typography variant="body2" paragraph>
          1.2.1 the{' '}
          <Link to="/public/privacy-notice">
            Personal Data Protection Notice
          </Link>
          , that describes how we process your personal data and your rights and
          responsibilities with respect to such processing; and
        </Typography>

        <Typography variant="body2" paragraph>
          For Patients: By downloading the Application, signing up for, or
          otherwise obtaining an account on the Application, or by accessing or
          using the any part of the Application, you represent and warrant that:
        </Typography>

        <Typography variant="body2" paragraph>
          1.3.1 you are at least 18 years of age;
        </Typography>

        <Typography variant="body2" paragraph>
          1.3.2 you are a resident in Malaysia; and
        </Typography>

        <Typography variant="body2" paragraph>
          1.3.3 you agree to be bound by these Terms of Use and agree to comply
          with them.
        </Typography>

        <Typography variant="body2" paragraph>
          For HCPs: By signing up for, or otherwise obtaining an account on the
          Site, or by accessing or using the any part of the Site, you represent
          and warrant that:
        </Typography>

        <Typography variant="body2" paragraph>
          1.4.1 you are a qualified and licensed healthcare professional in
          Malaysia (“Healthcare Professional” or &quot;HCP&quot;);
        </Typography>

        <Typography variant="body2" paragraph>
          1.4.2 you will use the Site only in a manner consistent with your
          professional, ethical, regulatory, statutory and other legal
          requirements; and
        </Typography>

        <Typography variant="body2" paragraph>
          1.4.3 you agree to be bound by these Terms of Use and agree to comply
          with them.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          PLEASE READ THESE TERMS OF USE CAREFULLY, AND DO NOT SIGN UP FOR AN
          ACCOUNT AND/OR USE THE PLATFORM IF YOU ARE UNWILLING OR UNABLE TO BE
          BOUND BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO THESE TERMS OF
          USE, THEN YOU MUST NOT USE THE PLATFORM (INCLUDING, DOWNLOADING OR
          ACCESSING THE APPLICATION AND/OR THE SITE) AND YOU SHOULD REMOVE THE
          APPLICATION FROM YOUR DEVICE ON WHICH YOU HAVE DOWNLOADED IT. IN
          RESPECT OF THE SITE, IF YOU ARE ACTING OR CARRYING OUT ANY OF THE
          FOREGOING ON BEHALF OF A LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT
          YOU HAVE THE AUTHORITY TO ACCEPT THESE TERMS AND CONDITIONS ON BEHALF
          OF THAT LEGAL ENTITY, AND YOU REPRESENT AND WARRANT THAT ALL USERS OF
          THE SITE AT SUCH LEGAL ENTITY SHALL COMPLY WITH THESE TERMS OF USE.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          2. About the Platform
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform is designed to facilitate participation in Program by
          treating physicians and their patients who are enrolled in the
          Program.
        </Typography>

        <Typography variant="body2" paragraph>
          The purpose of this Program is to drive change in patient adherence
          behavior amongst uncontrolled hypertension patients and simultaneously
          improve blood pressure (BP) control in the form of patient support
          consisting of BP recording calendar and educational materials and
          patient assistance in the form of (i) home blood pressure monitoring
          device (ii) complimentary (free) Sanofi product, i.e. Aprovasc (Irbesartan/amlodipine) (150
          mg/5 mg,150/10mg, 300 mg/5 mg or 300 mg/10 mg) (“Product”) upon
          completion of purchase of three (3) boxes of the said product.
        </Typography>

        <Typography variant="body2" paragraph>
          The Application provides the patients a tool to monitor their blood
          pressure, educate patients about relevant health related topics,
          provide access to patients to redeem the prescribed Product under the
          Program (access of which is restricted subject to the prescription of
          the patient’s treating physician and patient’s receipt upload of
          prescribed medication). The application will also prompt reminders of
          blood pressure recording and if the patient consent the recording of
          blood pressure can be emailed to their treating physician.
        </Typography>

        <Typography variant="body2" paragraph>
          The Site provides the treating physician a screening tool to determine
          the eligibility of patients to be enrolled into the programme, by
          assessing by assessing their medication adherence to hypertension
          treatment using the Healthcare Professional-Medication Adherence
          Instrument (H-MAI-9) and allow the physician to monitor the enrolled
          patients’ blood pressure recording if the patient has consent to do
          so.
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform is designed and intended for use by residents of Malaysia
          who are at least 18 years of age and have been enrolled to the Program
          in accordance with the requirements of the Program.
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform is provided exclusively for purposes permitted by this
          Platform for your participation in the Program, including:
        </Typography>

        <Box pl={2}>
          <Typography variant="body2" paragraph>
            (1) to provide access to treating physicians and their patients with
            information relating to the Program;
          </Typography>

          <Typography variant="body2" paragraph>
            (2) to facilitate physician to screen and qualify eligible patients
            into the Program in accordance with the Program’s eligibility
            criteria;
          </Typography>

          <Typography variant="body2" paragraph>
            (3) To facilitate patient’s redemption of the product under the
            Program;
          </Typography>

          <Typography variant="body2" paragraph>
            (4) to provide educational resources to patient relating to blood
            pressure control;
          </Typography>

          <Typography variant="body2" paragraph>
            (5) to allow the treating physicians to monitor their patient’s
            blood pressure record and medication adherence.
          </Typography>
        </Box>

        <Typography variant="body2" paragraph>
          No component of the Platform may be copied, reproduced, altered,
          edited, downloaded, denatured, transmitted or distributed in any way
          whatsoever, on any medium whatsoever, in whole or in part.
        </Typography>

        <Typography variant="body2" paragraph>
          The functionalities of the Platform relating to the Program can only
          be accessed by you by entering login details which are specific to
          you. Enabling other individuals to use the Platform through your login
          details is prohibited. Your usage of the Platform is subject to and
          governed by any usage requirements established from time to time by
          mClinica and the usage rules set forth in the applicable App Store.
          You agree to use the Platform in a manner that complies with all
          applicable laws in Malaysia and in the jurisdictions in which you use
          the Platform, including, but not limited to, applicable restrictions
          concerning copyright and other intellectual property rights (including
          those set forth in this Terms of Use).
        </Typography>

        <Typography variant="body2" paragraph>
          For Patients: The Application and any information presented by, stored
          on, generated by or received through the application are NOT intended
          to provide or be a substitute for professional medical advice,
          judgement, diagnosis, or treatment. Please consult your physician or
          other qualified healthcare professional if you have any questions
          about your health or treatment. Never disregard professional medical
          advice or delay in seeking it because of something you have read or
          learned through the use of the Application. For healthcare
          professionals:
        </Typography>

        <Box pl={3}>
          <Typography variant="body2" paragraph>
            The Site contains information relating to treatment given by other
            HCPs to their own patients and clinical development worldwide for
            the purpose of scientific exchange. While we hope you find the Site
            helpful, it is not a substitute for your own clinical judgment as a
            Healthcare Professional and nothing herein shall be construed as an
            encouragement of off-label use of any therapeutic products. No
            clinical decisions are to be based solely on the Site.
          </Typography>

          <Typography variant="body2" paragraph>
            You, and specifically not Sanofi, are solely responsible for
            verifying the accuracy of all patient information and determining
            the data necessary for you to make clinical decisions, as well as
            for complying with all laws, regulations and licensing requirements
            applicable to your delivery of healthcare services. In using the
            Site, you agree that neither Sanofi nor any other party is or will
            be liable or otherwise responsible for any decision made or any
            action taken or any action not taken due to your use of any
            information presented through the Site.
          </Typography>

          <Typography variant="body2" paragraph>
            You, and specifically not Sanofi, are solely responsible for
            verifying the accuracy of all patient information and determining
            the data necessary for you to make clinical decisions, as well as
            for complying with all laws, regulations and licensing requirements
            applicable to your delivery of healthcare services. In using the
            Site, you agree that neither Sanofi nor any other party is or will
            be liable or otherwise responsible for any decision made or any
            action taken or any action not taken due to your use of any
            information presented through the Site.
          </Typography>
        </Box>

        <Typography variant="body2" paragraph>
          If you have any questions about any of the products of Sanofi, please
          visit the website{' '}
          <a href="http://www.sanofi.com" target="_blank" rel="noreferrer">
            https://sanofi.com
          </a>{' '}
          and select your country of residence in order to find the
          corresponding Sanofi website and information on the local offices of
          the Sanofi Group. Should any of your User Provided Information (as
          defined hereunder in Paragraph 3) (or any other materials You may
          provide to Sanofi) mention a side effect related to a prescription
          drug or medical device, Sanofi may be required to +-2.
        </Typography>

        <Typography variant="body2" paragraph>
          contact you for further information and may be required to report to a
          regulatory authority.
        </Typography>

        <Typography variant="body2" paragraph>
          In using the Platform, you agree that neither Sanofi nor any other
          party is or will be liable or otherwise responsible for any decision
          made or any action taken or any action not taken due to your use of
          any information presented through the Platform.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          3. User Provided Information
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform may provide functionalities through which you are able to
          create, generate or upload information (such as non-clinical data,
          text and images) in connection with your use of the Platform (“
          <b>User Provided Information</b>”). You agree that you are solely
          responsible for all of your User Provided Information. We recommend
          that you back up any User Provided Information to protect yourself in
          case of problems with the Platform. We are not responsible for any
          loss, theft or damage of any kind to your User Provided Information.
          We will have no liability whatsoever to you or any third party for any
          failure of the Platform and has no obligation to retain your User
          Provided Information (or any other materials you may upload through
          the Platform) on its servers or return your User Provided Information
          (or any other materials or data you may provide in relation to your
          use of the Platform) to you before deleting them from its servers.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          4. Reporting An Adverse Event or Product Safety Concern or Requesting
          Medical Information
        </Typography>

        <Typography variant="body2" paragraph>
          <u>For Patient:</u>
        </Typography>

        <Typography variant="body2" paragraph>
          The Application is not designed for reporting of adverse events or
          product technical complaints relating to Sanofi products. In
          particular, any comments and notes that you make about the Platform,
          such as ratings and reviews on the App Store in which the Application
          is downloaded must not relate to side effects related to treatment or
          drugs or medical devices of Sanofi or other third party companies. If
          you wish to report any such events or complaints, please contact the
          health authority, your physician or pharmacist, or report through
          designated Sanofi reporting channel available in your country.
        </Typography>

        <Typography variant="body2" paragraph>
          <u>For healthcare professionals:</u>
        </Typography>

        <Typography variant="body2" paragraph>
          The Site is not intended to be used to report adverse events or
          product safety concerns or request for medical information. However,
          should you wish to report adverse events or product safety concerns or
          request for medical information with respect to Sanofi product, you
          may contact us as follows:
        </Typography>

        <ul>
          <li>
            <Typography variant="body2" paragraph>
              For any concerns related to adverse event or product safety
              concerns, email: PV-malaysia@sanofi.com
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              For medical information requests, email: Med.SAMS@sanofi.com
            </Typography>
          </li>
        </ul>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          5. Personal Data Protection Notice
        </Typography>

        <Typography variant="body2" paragraph>
          Through your use of the Platform and your participation in the
          Program, Sanofi will collect, use, process certain data about you and
          you might submit or allow access to certain data about yourself.
          Sanofi respects your rights to such data and has established policy
          governing Sanofi’s collection and use of your data (“the Personal Data
          Protection Notice”). The Personal Data Protection Notice is hereby
          incorporated into these Terms of Use.
        </Typography>

        <Typography variant="body2" paragraph>
          You are required to read through, understand and agree to the Personal
          Data Protection Notice when you first log in to the Application or the
          Site (as the case may be). Each of the Personal Data Protection Notice
          of the Application and the Site can be found via the “Sidebar”
          respective page in the Application and the Site.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          6. Links and Third Party Services
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform may contain links to websites or internet resources of
          Sanofi or its affiliated companies. You acknowledge that while such
          websites or internet resources are owned by Sanofi or its affiliated
          companies, they may have different terms of use and data protection
          policies. You are responsible for reviewing and complying with such
          terms and policies.
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform may also contain links to or provide features that
          communicate or interface with third party Platforms, websites or other
          resources, including third party social networks (&quot;
          <b>Third Party Services</b>&quot;). Third Party Services are not under
          the control of Sanofi and Sanofi is not responsible for the
          availability, contents, or performance of any Third Party Service.
          Sanofi is providing these features to you only as a convenience, and
          the inclusion of any link or third party feature does not imply
          endorsement by Sanofi of such Third Party Service, its contents or any
          products or services available through such Third Party Service or any
          association with its operators. You are responsible for complying with
          any privacy statements or terms of use of the Third Party Services.
          You should direct any concerns regarding these Third Party Services to
          those services’ administrators.
        </Typography>

        <Typography variant="body2" paragraph>
          YOU AGREE THAT SANOFI WILL HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY
          FEATURES, INFORMATION, SOFTWARE, OR MATERIALS FOUND AT ANY THIRD PARTY
          SERVICES.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          7. Availability of the Platform, Updates and Changes
        </Typography>

        <Typography variant="body2" paragraph>
          You acknowledge and agree that:
        </Typography>

        <ul>
          <li>
            <Typography variant="body2" paragraph>
              it is technically impossible to provide the Platform free of
              defaults and that we cannot take any responsibility for this;
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              defaults may lead to the temporary unavailability of the Platform;
              and
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              the availability and operation of the Platform is dependent on
              conditions and services that we do not control, such as, for
              example, telephone and internet connections.
            </Typography>
          </li>
        </ul>

        <Typography variant="body2" paragraph>
          The Platform may be modified or updated from time to time to improve
          performance, enhance functionality, reflect changes to the operating
          system or address security issues. In addition, you may be required to
          download new or updated versions of the Platform on a periodic basis,
          such as via the App Store where the Application was downloaded. To the
          extent that you choose to download or use any such updates, you
          acknowledge that you will be bound by the version of the Terms of Use
          that is applicable as at the time you download and use any such update
          to the Platform. We do not represent or warrant that any future
          updates to the Platform will provide the same functionality that is
          provided by the current version of the Platform. If you choose not to
          install such updates or if you opt out of automatic updates you may
          not be able to continue using the Platform.
        </Typography>

        <Typography variant="body2" paragraph>
          We have no obligation to provide, and may change the form or nature
          of, the Platform. We reserve the right to modify, suspend or
          discontinue your access to the Platform for any reason without notice,
          in its sole discretion.
        </Typography>

        <Typography variant="body2" paragraph>
          We may, at any time, alter or interrupt, temporarily or permanently,
          all or part of the Platform to undertake maintenance and/or make
          improvements and/or changes to the Platform. We are not responsible
          for any alteration, suspension or interruption of the Platform.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          8. Intellectual Property
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform and all intellectual property rights associated
          therewith, such as author’s rights, copyrights, patents, trademarks,
          sui generis rights relating to databases, designs, logos, know-how and
          all other intellectual property rights (whether registered or not)
          contained in this Platform are protected by applicable laws on
          intellectual property. They are owned by Sanofi or its affiliated
          companies, or belong to third-parties (including mClinica, the
          developer of the Application) and are used by Sanofi pursuant to an
          authorization of use granted by their owner
        </Typography>

        <Typography variant="body2" paragraph>
          You are granted a personal, limited, revocable, non-exclusive,
          non-transferable, and cost-free license to download, access and use
          the Platform in accordance with the conditions and within the limits
          described in these Terms of Use (&quot;<b>License</b>&quot;). The
          License is granted for the use of the Platform on compatible devices
          that you own or control.
        </Typography>

        <Typography variant="body2" paragraph>
          Apart from the License granted to you, and to the extent permitted by
          applicable law, the Platform and intellectual property rights
          associated therewith cannot be reproduced, used or transferred without
          our prior written consent, or, if the rights over the said elements
          are held by third parties, the consent of their respective owners.
        </Typography>

        <Typography variant="body2" paragraph>
          Except as provided above, you shall not copy, reproduce, modify,
          translate, adapt, republish, upload, distort, modify, alter, transmit,
          or distribute any element or component of the Platform in any manner
          whatsoever, in any medium whatsoever, in part or in whole, without our
          prior written consent, or, if the rights over the said elements are
          held by third-parties, the consent of their respective owners, and
          subject to compliance with intellectual property rights and other
          property rights that are mentioned. You shall not decompile, reverse
          engineer, disassemble the Platform or disable a feature that could
          limit the use of the Platform. You shall not rent or sublicense,
          lease, loan, sell, or distribute the Platform and any intellectual
          property contained in the Platform, or create derivative works of the
          Platform or any part thereof without our prior written consent. You
          shall not remove, alter or obscure any intellectual property notices
          (including copyright notices) on any authorized copy of the Platform.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          9. Disclaimer of Warranties & Limitations on Liability
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform is provided on an “as is” and “as available” basis
          without any express warranties of any kind.
        </Typography>

        <Typography variant="body2" paragraph>
          We disclaim and exclude all liability and warranties, express or
          implied, in law or contract related to, without limitation,
          merchantability, fitness for a particular purpose and non-infringement
          of application character. We do not warrant that the Platform will
          meet your requirements in any respect or be available at all times, or
          that the operation of the Platform will be uninterrupted or error-free
          or that the errors in the Platform or nonconformity to its or their
          documentation can or will be corrected.
        </Typography>

        <Typography variant="body2" paragraph>
          Any material downloaded or otherwise obtained through the use of the
          Platform is accessed at your own discretion and risk, and you will be
          solely responsible for any damage to your computer system or loss of
          data that results from the download or use of any such material. No
          advice or information, whether oral or written, obtained by you from
          Sanofi or its authorized representatives including mClinica through or
          from the Platform shall create any warranty not expressly stated in
          these Terms of Use.
        </Typography>

        <Typography variant="body2" paragraph>
          We do not guarantee that the Platform (including any information
          presented through the Platform) is free of bug, defect, error or
          inaccuracies, that it does not contain viruses or other elements that
          could damage your computer or your mobile device, or that it is
          compatible with any hardware or software versions or applications,
          including any specific versions of your device, or its specific
          operating system.
        </Typography>

        <Typography variant="body2" paragraph>
          We will make reasonable efforts to ensure that the Platform works
          accordingly, and any information stored on, generated by, or received
          through the Platform is accurate, complete and up to date. However we
          reserve the right to correct content at any time, without prior
          notice. However, we cannot guarantee that the information available on
          the Platform is accurate, correct, up-to-date or complete.
        </Typography>

        <Typography variant="body2" paragraph>
          To the extent permitted by law and except for direct damages resulting
          from our wilful misconduct or gross negligence, we and the Provider
          shall not be liable for:
        </Typography>

        <ul>
          <li>
            <Typography variant="body2" paragraph>
              any damages resulting from the incorrect use of the Platform;
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              any damages resulting from fraudulent intrusion by a third party
              resulting in an alteration of information or functionalities that
              are made available on the Platform;
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              any damages resulting from the use of Third Party Services
              referred to in Section 6 above;
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              any loss or damage that is not foreseeable. Loss or damage is
              foreseeable if either it is obvious that it will happen or if, at
              the time you accepted these terms, both we and you knew it might
              happen;
            </Typography>
          </li>

          <li>
            <Typography variant="body2" paragraph>
              any and, more broadly, for all damages resulting from (i) access
              or inability to access the Platform, (ii) the use of the Platform,
              including any bug, virus or other harmful element that may infect
              the device on which you have downloaded or use the Platform,
              and/or (iii) any credit given to any information provided directly
              or indirectly in the Platform.
            </Typography>
          </li>
        </ul>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          10. Termination of Rights
        </Typography>

        <Typography variant="body2" paragraph>
          We reserve the right to terminate your access to the Platform at any
          time without notice for any reason, or no reason, whatsoever. In the
          case of termination, you must cease all use of the Platform.
        </Typography>

        <Typography variant="body2" paragraph>
          Without derogating the generating of the foregoing, your rights under
          these Terms of Use will automatically terminate if you fail to comply
          with any term of these Terms of Use and/or if your participation in
          the Program ceases. Our failure to insist upon or enforce your strict
          compliance with these Terms of Use will not constitute a waiver of any
          of our rights.
        </Typography>

        <Typography variant="body2" paragraph>
          Despite the termination of use, you agree you will continue to be
          bound by the Terms of Use in respect to use of downloaded materials,
          intellectual property, limitation of liability, warranties, disclaimer
          of warranties, limitations of liability, governing law.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          11. Indemnity
        </Typography>

        <Typography variant="body2" paragraph>
          You hereby agree to defend, indemnify and hold harmless Sanofi and its
          affiliates and their respective officers, directors, employees,
          agents, representatives, service providers for, from and against any
          claim, suit, proceedings, loss, liability, damage, cost and expense
          (including, without limitation, costs of investigation and reasonable
          legal fees and expenses), directly or indirectly relating to,
          resulting from or arising out of: (a) any breach by you of these Terms
          of Use; (b) any negligent act or omission or willful misconduct by you
          in connection with your registration for an account (if any) and/of
          use of the Platform; and/or (c) any violation by you of applicable
          law.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          12. Severability
        </Typography>

        <Typography variant="body2" paragraph>
          If any part of these Terms of Use is unlawful, void, or unenforceable,
          that part will be deemed severable and will not affect the validity
          and enforceability of any remaining provisions.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          13. Contact Information
        </Typography>

        <Typography variant="body2" paragraph>
          Please contact incontrol.support@mclinica.com, <PhoneNumberText />,
          address: 5 Shenton Way, UIC Building #10-01 Singapore 068808, if you
          have any questions or comments regarding the Platform.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          14. Modifications to these Terms of Use
        </Typography>

        <Typography variant="body2" paragraph>
          Sanofi reserves the right to modify the Terms of Use and the Personal
          Data Protection Notice, by (i) posting revised Terms of Use or the
          Personal Data Protection Notice on and/or through the Platform, and/or
          (ii) providing advance notice to you of material changes to the Terms
          of Use or the Privacy Policy, generally via email where practicable,
          and/or otherwise through the Platform (such as through a notification
          on the main screen page of the Site).
        </Typography>

        <Typography variant="body2" paragraph>
          We may sometimes ask you to review and to explicitly agree to or
          reject a revised version of the Terms of Use or the Personal Data
          Protection Notice. In such cases, modifications will be effective at
          the time of your agreement to the modified version of the Terms of Use
          or the Personal Data Protection Notice. If you do not agree at that
          time, you are not permitted to use the Platform. In cases where we do
          not ask for your explicit agreement to a modified version of the Terms
          of Use or the Personal Data Protection Notice, the modified version of
          the Terms of Use or the Personal Data Protection Notice will become
          effective as of the date specified in the Terms of Use or the Personal
          Data Protection Notice. Your use of the Platform following that date
          constitutes your acceptance of the terms and conditions of the Terms
          of Use or the Personal Data Protection Notice as modified. If you do
          not agree to the modifications, you are not permitted to use, and
          should discontinue your use of, the Platform.
        </Typography>

        <Typography variant="body2" className={styles.boldFont} paragraph>
          15. Governing Law
        </Typography>

        <Typography variant="body2" paragraph>
          The Platform and its content, including these Terms of Use, are
          governed by the laws of Malaysia, and any dispute or claim related
          thereto will be submitted to the exclusive jurisdiction of the courts
          of Malaysia.
        </Typography>
      </Grid>
    </Grid>
  );
};

Content.defaultProps = {
  isLoggedIn: false,
};

Content.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default Content;
