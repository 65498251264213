import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Simply a phone number text link.
 *
 * @param {Object} param0 { cssClass }
 * @returns {DOM}
 */
export const PhoneNumberText = ({ cssClass }) => (
  <Link href="tel:+601800819734" className={cssClass}>
    1-800-81-9734
  </Link>
);

PhoneNumberText.propTypes = {
  cssClass: PropTypes.string,
};

PhoneNumberText.defaultProps = {
  cssClass: '',
};

export default PhoneNumberText;
