import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1em',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
  },
  radioContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'min-content',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '1em',
    },
  },
  radioButton: {
    marginRight: '2em',
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
  },
}));

const AddPatientQuestion = ({ fieldName, question, setFieldValue }) => {
  const styles = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState('no');

  useEffect(() => {
    setFieldValue(fieldName, value);
  }, [setFieldValue, fieldName, value]);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    setFieldValue(fieldName, e.target.value);
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      className={styles.container}
    >
      <Grid item>
        <Typography color="primary">{question}</Typography>
      </Grid>
      <Grid item>
        <RadioGroup
          row
          className={styles.radioContainer}
          name={fieldName}
          value={value || ''}
          onChange={(e) => onChangeHandler(e)}
        >
          <Grid item container justify={smDown ? undefined : 'center'} md lg xs>
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label="Yes"
              className={styles.radioButton}
            />
          </Grid>
          <Grid item container justify={smDown ? undefined : 'center'} md lg xs>
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="No"
              className={styles.radioButton}
            />
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

AddPatientQuestion.propTypes = {
  fieldName: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default AddPatientQuestion;
