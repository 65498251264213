import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IntroductionBlock from './IntroductionBlock';
import AboutApplicationBlock from './AboutApplicationBlock';
import PersonalDataBlock from './PersonalDataBlock';
import DataCollectionBlock from './DataCollectionBlock';
import DataUsageBlock from './DataUsageBlock';
import DataSharingBlock from './DataSharingBlock';
import DataTransferBlock from './DataTransferBlock';
import RightsAndChoicesBlock from './RightsAndChoicesBlock';
import DataRetentionBlock from './DataRetentionBlock';
import SecurityBlock from './SecurityBlock';
import ThirdPartyBlock from './ThirdPartyBlock';
import QuestionsBlock from './QuestionsBlock';
import DataAccuracyBlock from './DataAccuracyBlock';
import RevisionNoticeBlock from './RevisionNoticeBlock';
import IntroductionBlockBahasa from './IntroductionBlockBahasa';
import AboutApplicationBlockBahasa from './AboutApplicationBlockBahasa';
import PersonalDataBlockBahasa from './PersonalDataBlockBahasa';
import DataCollectionBlockBahasa from './DataCollectionBlockBahasa';
import DataUsageBlockBahasa from './DataUsageBlockBahasa';
import DataSharingBlockBahasa from './DataSharingBlockBahasa';
import DataTransferBlockBahasa from './DataTransferBlockBahasa';
import RightsAndChoicesBlockBahasa from './RightsAndChoicesBlockBahasa';
import DataRetentionBlockBahasa from './DataRetentionBlockBahasa';
import SecurityBlockBahasa from './SecurityBlockBahasa';
import ThirdPartyBlockBahasa from './ThirdPartyBlockBahasa';
import QuestionsBlockBahasa from './QuestionsBlockBahasa';
import DataAccuracyBlockBahasa from './DataAccuracyBlockBahasa';
import RevisionNoticeBlockBahasa from './RevisionNoticeBlockBahasa';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '10em',
    marginRight: '10em',
    [theme.breakpoints.only('md')]: {
      marginLeft: '6em',
      marginRight: '6em',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1.5em',
      marginRight: '1.5em',
    },
  },
}));

const Content = ({ isLoggedIn }) => {
  const styles = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={isLoggedIn ? undefined : styles.root}
      spacing={4}
    >
      {/* English Translations */}
      <IntroductionBlock />
      <AboutApplicationBlock />
      <PersonalDataBlock />
      <DataCollectionBlock />
      <DataUsageBlock />
      <DataSharingBlock />
      <DataTransferBlock />
      <RightsAndChoicesBlock />
      <DataRetentionBlock />
      <SecurityBlock />
      <ThirdPartyBlock />
      <QuestionsBlock />
      <DataAccuracyBlock />
      <RevisionNoticeBlock />
      {/* Bahasa Translations */}
      <IntroductionBlockBahasa />
      <AboutApplicationBlockBahasa />
      <PersonalDataBlockBahasa />
      <DataCollectionBlockBahasa />
      <DataUsageBlockBahasa />
      <DataSharingBlockBahasa />
      <DataTransferBlockBahasa />
      <RightsAndChoicesBlockBahasa />
      <DataRetentionBlockBahasa />
      <SecurityBlockBahasa />
      <ThirdPartyBlockBahasa />
      <QuestionsBlockBahasa />
      <DataAccuracyBlockBahasa />
      <RevisionNoticeBlockBahasa />
    </Grid>
  );
};

Content.defaultProps = {
  isLoggedIn: true,
};

Content.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default Content;
