import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const primaryColor = '#AB4494';
const primaryDarkColor = '#6F2B90';
const secondaryColor = '#D83883';
const accentColor = '#A4308A';
const borderColor = '#949494';

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      dark: primaryDarkColor,
    },
    secondary: {
      main: secondaryColor,
    },
    accent: {
      main: accentColor,
    },
    border: {
      main: borderColor,
    },
  },
  typography: {
    h5: {
      [breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
    },
    h6: {
      fontWeight: 700,
    },
    subtitle2: {
      [breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.7rem',
      },
    },
    body2: {
      [breakpoints.down('md')]: {
        fontSize: '0.9rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        '&$disabled': {
          backgroundColor: '#EE3C8270',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
    MuiInput: {
      root: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
          margin: 80,
        },
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid ${secondaryColor}`,
        },
        fontSize: '1rem',
        [breakpoints.down('md')]: {
          fontSize: '0.9rem',
        },
        [breakpoints.down('sm')]: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1rem',
        [breakpoints.down('md')]: {
          fontSize: '0.9rem',
        },
        [breakpoints.down('sm')]: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiTab: {
      root: {
        backgroundColor: 'transparent',
        '&$selected': {
          backgroundColor: '#FFFFFF25',
        },
        '&:hover:not($selected)': {
          backgroundColor: '#FFFFFF10',
        },
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
    },
  },
});

export default theme;
