import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formatErrorMessage } from 'common/helpers';
import { setMessage } from './resourcesSlice';

const ADHERANCE_LABEL = (score = 9) => {
  switch (true) {
    case score <= 35:
      return 'LOW ADHERENCE';
    default:
      return 'HIGH ADHERENCE';
  }
};

export const getPreRegisteredUsers = createAsyncThunk(
  'patient/getPreRegisteredUsers',
  async (_, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.get(endpoints.PATIENT_PRE_REGISTRATION);
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue([]);
    }
  }
);

export const preRegisterUser = createAsyncThunk(
  'patient/preRegisterUser',
  async (formikFields, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    const payload = {
      ...formikFields,
      userType: 'patient',
    };
    try {
      const { data } = await authClient.post(
        endpoints.PATIENT_PRE_REGISTRATION,
        payload
      );
      await dispatch(getPreRegisteredUsers());
      dispatch(setMessage(data.message));
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

export const updatePreRegisteredUser = createAsyncThunk(
  'patient/updatePreRegisteredUser',
  async (payload, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.patch(
        `${endpoints.PATIENT_PRE_REGISTRATION}/${payload.id}`,
        payload
      );
      await dispatch(getPreRegisteredUsers());
      dispatch(setMessage(data.message));
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

export const deletePreRegisteredUser = createAsyncThunk(
  'patient/deletePreRegisteredUser',
  async (id, { extra, dispatch, rejectWithValue }) => {
    const { authClient, endpoints } = extra;
    try {
      const { data } = await authClient.delete(
        `${endpoints.PATIENT_PRE_REGISTRATION}/${id}`
      );
      await dispatch(getPreRegisteredUsers());
      dispatch(setMessage(data.message));
      return data;
    } catch (err) {
      dispatch(setMessage(formatErrorMessage(err)));
      return rejectWithValue(false);
    }
  }
);

const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    patientList: [],
    patientAnswers: {},
    selectedPatient: {},
    patientScore: 0,
    adheranceLabel: ADHERANCE_LABEL(),
    nextButtonEnabled: false,
    submitPatientEnabled: false,
    searchFilter: '',
    statusFilter: [],
    pending: false,
  },
  reducers: {
    setSelectedPatient: (state, { payload }) => {
      state.selectedPatient = payload;
    },
    setPatientAnswers: (state, { payload }) => {
      const keys = Object.keys(payload);
      keys.forEach((key) => {
        state.patientAnswers[key] = payload[key];
      });
      const values = Object.values(state.patientAnswers);
      let score = 0;
      values.forEach((value) => {
        score += +value;
      });
      state.nextButtonEnabled = values.length >= 6;
      state.submitPatientEnabled = values.length >= 9;
      state.patientScore = score;
      state.adheranceLabel = ADHERANCE_LABEL(score);
    },
    resetState: (state) => {
      state.patientAnswers = {};
      state.patientScore = 0;
      state.adheranceLabel = ADHERANCE_LABEL();
      state.submitPatientEnabled = false;
    },
    setSearchFilter: (state, { payload }) => {
      state.searchFilter = payload;
    },
    setStatusFilter: (state, { payload }) => {
      state.statusFilter = payload;
    },
  },
  extraReducers: {
    [getPreRegisteredUsers.fulfilled]: (state, { payload }) => {
      state.patientList = payload.result;
      state.pending = false;
    },
    [getPreRegisteredUsers.pending]: (state) => {
      state.pending = true;
    },
    [getPreRegisteredUsers.rejected]: (state) => {
      state.pending = false;
      state.patientList = [];
    },
    [preRegisterUser.fulfilled]: (state) => {
      state.pending = false;
    },
    [preRegisterUser.pending]: (state) => {
      state.pending = true;
    },
    [preRegisterUser.rejected]: (state) => {
      state.pending = false;
    },
  },
});

export const {
  setSelectedPatient,
  setPatientAnswers,
  resetState,
  setSearchFilter,
  setStatusFilter,
} = patientSlice.actions;

export default patientSlice.reducer;
