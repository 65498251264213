import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { selectRegistrationValues } from 'selectors/resources';
import {
  selectHCPFields,
  selectHCPFieldValidationSchema,
} from 'selectors/userRegistration';
import { Field } from 'formik';
import { ConnectForm } from 'components/Form';
import { ConnectCheckboxField } from 'components/Fields';
import { login } from 'store/authSlice';
import { registerUser } from 'store/userSlice';
import { isRegistrationFormTouched, saveToken } from 'common/helpers';
import { Auth } from 'service/auth.service';
import RegistrationCheckbox from './RegistrationCheckbox';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
      marginTop: '2em',
      marginBottom: '5em',
    },
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  loginContainer: {
    maxWidth: '23em',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20em',
    },
  },
  formContainer: {
    maxWidth: '22em',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '18em',
    },
  },
  header: {
    color: '#828282',
  },
  subtitle: {
    fontSize: '0.8rem',
    color: '#666666',
    textAlign: 'center',
  },
  agreementText: {
    paddingTop: 16,
    paddingBottom: 16,
    lineHeight: 1.2,
    fontSize: '0.9rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
    textTransform: 'none',
    textAlign: 'left',
  },
  agreementButton: {
    padding: 0,
  },
  divider: {
    backgroundColor: '#CCCCCC',
    minWidth: '3.4em',
    width: 'auto',
    height: '0.1em',
    [theme.breakpoints.down('sm')]: {
      minWidth: '2.4em',
    },
  },
  button: {
    height: '3.5em',
    marginTop: '1em',
    marginBottom: '1em',
  },
}));

const RegistrationBlock = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const validationSchema = useSelector(selectHCPFieldValidationSchema);
  const userFields = useSelector(selectHCPFields);
  const registrationValues = useSelector(selectRegistrationValues);

  const [formVisible, setFormVisible] = useState(false);
  const [loginLabel, setLoginLabel] = useState('New to One Platform Directory');
  const [enableSubmit] = useState(false);

  const handleLogin = () => {
    dispatch(login());
  };

  const handleSubmit = (values, actions) => {
    if (!formVisible) {
      setLoginLabel('New to In-Control Program');
      setFormVisible(true);
      return;
    }
    if (
      !values.agreementCheckTermsAndCondition ||
      !values.agreementCheckPrivacyNotice ||
      !values.agreementCheckPhysicianConsent
    ) {
      actions.setSubmitting(false);
      return;
    }
    dispatch(registerUser(values)).then((data) => {
      actions.setSubmitting(false);
      if (data.payload) {
        actions.resetForm();
        saveToken(data.payload.result);
        Auth.instance.loadTokenFromStorage();
        history.replace('/verification');
      }
    });
  };

  useEffect(() => {
    if (isRegistrationFormTouched(registrationValues)) {
      setLoginLabel('New to In-Control Program');
      setFormVisible(true);
    }
  }, [registrationValues]);

  return (
    <Grid
      item
      container
      direction="column"
      className={styles.mainContainer}
      justify="center"
      alignItems="center"
      sm={12}
      spacing={2}
      md
      lg
    >
      <Grid
        item
        container
        direction="column"
        className={styles.loginContainer}
        spacing={2}
      >
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disableElevation
            className={styles.button}
            onClick={handleLogin}
          >
            Login
          </Button>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-evenly"
        >
          <Grid item>
            <div className={styles.divider} />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={styles.subtitle}>
              {loginLabel}
            </Typography>
          </Grid>
          <Grid item>
            <div className={styles.divider} />
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={formVisible}>
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h5" className={styles.header} align="center">
              Register for your In-Control Account
            </Typography>
          </Grid>

          <Grid item className={styles.formContainer}>
            <ConnectForm
              initialValues={registrationValues}
              onSubmitHandler={handleSubmit}
              validationSchema={validationSchema}
              fields={userFields}
              enabledSubmitButton={enableSubmit}
              saveRegistration
              disableReinitialize
            >
              <Grid
                item
                container
                spacing={1}
                style={{ marginTop: '1.5em', marginBottom: '0.5em' }}
              >
                <Grid item>
                  <Field
                    name="enableBpSharing"
                    component={ConnectCheckboxField}
                    label={
                      <Typography
                        variant="subtitle1"
                        className={styles.agreementText}
                      >
                        I agree to have my patients email me their blood
                        pressure results (optional)
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <RegistrationCheckbox
                    name="agreementCheckTermsAndCondition"
                    label="Terms of Use"
                    to="/public/terms-and-conditions"
                    required
                  />
                </Grid>
                <Grid item>
                  <RegistrationCheckbox
                    name="agreementCheckPrivacyNotice"
                    label="Personal Data Protection Notice"
                    to="/public/privacy-notice"
                    required
                  />
                </Grid>
                <Grid item>
                  <RegistrationCheckbox
                    name="agreementCheckPhysicianConsent"
                    label="Physician Consent Form"
                    to="/public/physician-consent-form"
                    required
                  />
                </Grid>
              </Grid>
            </ConnectForm>
          </Grid>
        </Grid>
      </Collapse>
      {!formVisible ? (
        <Grid
          item
          container
          direction="column"
          className={styles.loginContainer}
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              fullWidth
              disableElevation
              onClick={handleSubmit}
              className={styles.button}
            >
              Register
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
export default RegistrationBlock;
