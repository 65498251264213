import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PhoneNumberText from 'components/PhoneNumberText';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '10em',
    marginRight: '10em',
    [theme.breakpoints.only('md')]: {
      marginLeft: '6em',
      marginRight: '6em',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1.5em',
      marginRight: '1.5em',
    },
  },
}));

const Content = ({ isLoggedIn }) => {
  const styles = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={isLoggedIn ? undefined : styles.root}
      spacing={4}
    >
      <Grid item>
        <Grid item>
          <Typography variant="h6">PHYSICIAN CONSENT FORM</Typography>
        </Grid>
        <Grid item>
          <Typography paragraph variant="body2" component="div">
            <h3>INTRODUCTION</h3>
            This Program is sponsored by Sanofi-Aventis (Malaysia) Sdn. Bhd. (
            <i>“Sanofi”</i>). Only patients who are prescribed to use any of the
            following product (collectively referred to as <em>“Products”</em>
            &nbsp;and each, a <em>“Product”</em>) by their treating physician
            may participate in this Program:
            <ul>
              <li>Aprovasc (Irbesartan/amlodipine) 150 mg/5 mg</li>
              <li>Aprovasc (Irbesartan/amlodipine) 150 mg/10 mg</li>
              <li>Aprovasc (Irbesartan/amlodipine) 300 mg/5 mg</li>
              <li>Aprovasc (Irbesartan/amlodipine) 300 mg/10 mg</li>
            </ul>
            The purpose of this Program is to improve patient adherence to
            Sanofi treatment, (individually “Product”) which have been
            prescribed by their treating physicians by providing patient
            assistance in the form of complimentary (free) Product subject to
            and detailed in the terms herein. Participation in this Program is
            voluntary.
          </Typography>
        </Grid>
        <Typography paragraph variant="body2" component="div">
          <h3>PROGRAM</h3>
          <h4>Eligibility of Patients</h4>
          To qualify for the Program, your patient must meet <u>ALL</u> the
          eligibility criteria below:
          <ul>
            <li>
              Patients require treatment and are prescribed with any of the
              Product by the treating physician;
            </li>
            <li>
              Patients are screened and qualified by the treating physician for
              non-adherence based on H-MAI-9 medication adherence score of &lt;
              80%{' '}
            </li>
            <li>Patients do not own a home blood pressure monitoring device</li>
          </ul>
          <h4>How to participate</h4>
          If your patients fulfil all the above eligibility criteria, you and
          your patients may apply to participate in this Program by accepting
          the terms and conditions in this form. Your acceptance will be
          retained digitally by mClinica Pte.Ltd. (<em>“PROVIDER”</em>), the
          independent third-party service provider engaged by Sanofi to manage
          this Program. All data provided by you in this dashboard will only be
          accessible by you and the PROVIDER and will not be disclosed to
          Sanofi.
          <h4>How can patient stay active in the program</h4>
          <ul>
            <li>
              Patient must download the In-Control app upon being pre-qualified
              by the treating physician within 30 days from the treating
              physician&lsquo;s assessment{' '}
            </li>
            <li>
              Once downloaded, patient must perform any transactions of purchase
              and redemption of Aprovasc (Irbesartan/amlodipine) within the
              duration (less than 30 days after last transaction for 1 box
              purchase or less than 60 days after last transaction for
              concurrent 2 box purchases or less than 120 days after last
              transaction for concurrent 3 box purchase)
            </li>
            <li>
              Patient must perform blood pressure recording of at least average
              3 times/week in 30 days
            </li>
          </ul>
          <h4>What can the patient redeem</h4>
          Upon your enrolment in the Program and your authorization to recruit
          the patient, you can entitle the patient to:
          <ol type="a">
            <li>
              redeem one (1) box of Aprovasc (Irbesartan/amlodipine) with every
              purchase of up to three (3) boxes from your clinic{' '}
            </li>
            <li>a maximum of 3 boxes can be purchased per transaction. </li>
            <li>
              a maximum of 3 boxes can be purchased per cycle, of which it is
              defined as period of 30 days per cycle inclusive of date of
              purchase
            </li>
            <li>
              One or two boxes of Aprovasc (Irbesartan/amlodipine) can be
              purchased at a single transaction and accumulated to 3 boxes for
              the redemption of one (1) box.
            </li>
            <li>
              Redeem one (1) home blood pressure monitor (Device) upon program
              enrolment. Blood pressure monitor (Device) will be delivered to
              the patient address registered in the In-Control app. No changes
              of delivery location can be made once Device has been sent out to
              the patient’s registered address.
            </li>
          </ol>
          For the avoidance of doubt, the prescribed dosage strength of the
          redeemed complimentary Product, must be the same as the prescribed
          dosage strength of the same Product purchased.
          <h4>How to redeem</h4>
          <ul>
            <li>
              In order to redeem the complimentary Product, the patient must
              upload the itemized receipt(s) evidencing the purchase of the
              relevant Product from your clinic (“Proof of Purchase”) into the
              In-Control App.{' '}
            </li>
            <li>
              The purchase of the Product may be in a single transaction three
              (3) boxes, in two (2) separate transactions of one (1) box and two
              (2) boxes or in three (3) separate transactions of one (1) box
              each.
            </li>
            <li>
              If the purchases are separate transactions, the relevant Proof of
              Purchase evidencing all transactions shall be provided by you to
              the patient.{' '}
            </li>
            <li>
              Proof of Purchase must be provided by you and submitted to the
              patient and Sanofi reserves the right to forfeit any redemption
              that is not made within the program duration.{' '}
            </li>
            <li>
              Redemption of the complimentary Product under the Program is
              subject to PROVIDER prior approval and upon approval by PROVIDER,
              the complimentary Product will be collected at your clinic by the
              patient.{' '}
            </li>
          </ul>
          <h4>Duration</h4>
          This Program will run from{' '}
          <span>
            <u>30</u>
            <sup
              style={{ paddingBottom: '4px', borderBottom: '1px solid black' }}
            >
              th
            </sup>
            <u>April 2021 till 30</u>
            <sup
              style={{ paddingBottom: '4px', borderBottom: '1px solid black' }}
            >
              th
            </sup>
            <u>October 2022</u>
          </span>{' '}
          (both dates inclusive) (“Term”). Notwithstanding the foregoing, Sanofi
          reserves the right to vary the terms and conditions of this Program
          and/or extend, modify, suspend or terminate this Program at any time,
          without giving any reason, prior notice and/or assuming any liability
          to you or any party. Sanofi will inform you of any updates to the
          Program. If at any time you wish to withdraw from this Program, please
          indicate through the In-Control app of your notice to leave.
        </Typography>
        <Grid item>
          <Typography paragraph variant="body2" component="div">
            <h3>PHARMACOVIGILANCE </h3>
            You should immediately report any health concerns, adverse events or
            product technical complaints by your patients to Sanofi Malaysia via
            email to:pv-malaysia@sanofi.com
          </Typography>
        </Grid>
        <Grid item>
          <Typography paragraph variant="body2" component="div">
            <h3>PERSONAL DATA: PRIVACY NOTICE</h3>
            Your personal data which Sanofi may collect, use, disclose or
            otherwise process in this Program, includes your name, email
            address, clinic/hospital name, DKSH account numbers, details of the
            pre-enrolled patients (name, mobile number, &amp; email address) and
            details of Product purchased from you, payment information and other
            personal data of yours depending on the nature of your other
            interaction with Sanofi (only where necessary for the purposes for
            which it was collected) (collectively, &quot;Personal Data&quot;)..
            Sanofi obtains your Personal Data from the information you have
            provided in this form, any other personal data of yours which you
            may communicate to Sanofi, its affiliates, their service providers
            or agents, and/or public sources available in accordance with
            applicable law.
          </Typography>
          <Typography paragraph variant="body2">
            Your Personal Data will only be used for purposes of administering
            this Program and maintaining the quality of the Program, including
            but not limited to compliance checks, audit review and accounting
            purposes; in relation to any adverse event reported by you or which
            you may otherwise be involved or concerned with; to respond to your
            enquiries and complaints and to resolve disputes; and/or for any
            purpose allowed or required by law or regulations (collectively, the
            “Purpose”) and will not be used for commercial and marketing
            purposes.
          </Typography>
          <Typography paragraph variant="body2">
            Sanofi will store your Personal Data in a database of this Program
            maintained by Sanofi, its affiliates, their service provider or
            agents (including its lawyers/law firms). Any of your Personal Data
            is retained for as long as the purposes for which the Personal Data
            was collected continues; your Personal Data is then destroyed or
            anonymized in the event your Personal Data is no longer required for
            the said Purpose unless its further retention is required to satisfy
            a longer retention period to meet operational, legal, regulatory,
            tax or accounting requirements. If required for any of the foregoing
            Purposes, your Personal Data may be transferred and/or disclosed to
            Sanofi or Sanofi’s affiliates, their service providers or agents
            (including its lawyers/law firms), who may be located outside
            Malaysia.
          </Typography>
          <Typography paragraph variant="body2">
            You have the right to withdraw your consent for the processing of
            your Personal Data or to request for access or correction of or
            limit the processing of your Personal Data or to make inquiries or
            complaints in respect of the Personal Data by contacting PROVIDER
            at: Privacy Officer <u>Mr Ten Yee Fong</u>, Tel number:
            <PhoneNumberText />, email:{' '}
            <a href="mailto:incontrol.support@mclinica.com">
              incontrol.support@mclinica.com
            </a>
          </Typography>
          <Typography paragraph variant="body2">
            Please note that you are required to provide your Personal Data in
            order to participate in the Program and it will be necessary for
            Sanofi and/or its Affiliates to process your Personal Data for the
            Purpose, without which you will not be able to participate in the
            Program. In the event of any inconsistency between the English
            language version of the Privacy Notice and their corresponding
            Bahasa Malaysia version, the terms in the English language version
            shall prevail.
          </Typography>
        </Grid>
        <Grid item>
          <Typography paragraph variant="body2" component="div">
            <h3>DATA PERIBADI: NOTIS PRIVASI</h3>

            <em>
              Data peribadi anda yang Sanofi akan mengumpul, mengguna,
              menzahirkan atau mungkin sebaliknya memproses dalam Program ini
              termasuk nama, alamat emel, nama hospital/klinik, nombor akaun
              DKSH, butiran pesakit yang pra-daftar (name, telefon dan alamat
              emel) , atau data peribadi anda yang lain yang bergantung kepada
              sifat interaksi lain anda dengan Sanofi (di mana diperlukan untuk
              tujuan pengumpulan tersebut) (&quot;Data Peribadi&quot;, secara
              kolektif). PROVIDER memperolehi Data Peribadi anda daripada
              maklumat yang telah anda mengemukkanan di dalam borang ini, atau
              daripada anda melalui komunikasi anda dengan Sanofi,
              syarikat-syarikat bersekutu Sanofi, pembekal perkhidmatan atau
              agen mereka, dan/atau sumber awam yang boleh didapati mengikuti
              undang-undang terpakai.
            </em>
          </Typography>
          <Typography paragraph variant="body2">
            <em>
              Data Peribadi anda hanya akan diguna untuk tujuan menadbir dan
              mengekalkan kualiti Program termasuk tetapi tidak terhad kepada
              semakan pematuah, semakan audit dan tujuan perakaunan; berkaitan
              dengan mana-mana acara yang buruk yang telah dilaporkan oleh anda;
              untuk menjawab pertanyaan atau aduan anda atau menyelesai masalah;
              dan/or untuk tujuan yang dikehendaki dibawah undang-undang atau
              peraturan (“Tujuan”, secara kolektif) dan tidak akan digunakan
              untuk tujuan komersil atau pemasaran.{' '}
            </em>
          </Typography>
          <Typography paragraph variant="body2">
            <em>
              Sanofi akan menyimpan Data Peribadi anda di dalam pangkalan data
              Program ini yang dikekalkan oleh Sanofi, syarikat-syarikat
              bersekutunya, pembekal perhidmatan mereka dan/atau agen mereka
              (termasuk peguam/firm peguaman). Mana-mana Data Peribadi anda yang
              diberikan kepada kami akan disimpan selagi tujuan yang mana Data
              Peribadi dikumpulkan berterusan; Data Peribadi anda kemudiannya
              dimusnahkan atau dianonimasiskan sekiranya Data Peribadi anda
              tidak lagi diperlukan untuk Tujuan tersebut kecuali penyimpanan
              selanjutnya diperlukan untuk memenuhi tempoh penyimpanan yang
              lebih panjang untuk memenuhi keperluan operasi, undang-undang,
              pengawalseliaan, cukai atau perakaunan. Sekiranya diperlukan untuk
              mana-mana tujuan di atas, Sanofi mungkin memindah dan/atau
              mendedahkan Data Peribadi anda kepada syarikat-syarikat sekutunya,
              pembekal-pembekal perkhidmatan mereka dan/atau agen mereka
              (termasuk peguam/firm peguaman), yang mungkin terletak di luar
              Malaysia.{' '}
            </em>
          </Typography>
          <Typography paragraph variant="body2">
            <em>
              Anda menpunyai hak untuk menarikan balik persetujuan untuk
              memproses Data Peribadi anda atau meminta akses kepada dan untuk
              meminta pembetulan terhadap atau mengehadkan pemprosesan Data
              Peribadi anda atau untuk membuat apa-apa pertanyaan atau aduan
              berkenaan tentang Data Peribadi itu, dengan menghubungi PROVIDER
              di Orang Hubungan: <u>Mr Ten Yee Fong;</u> nombor telefon:{' '}
              <PhoneNumberText />, (toll free); alamat emel:
              <a href="mailto:incontrol.support@mclinica.com">
                incontrol.support@mclinica.com
              </a>{' '}
            </em>
          </Typography>
          <Typography paragraph variant="body2">
            <em>
              Sila ambil perhatian bahawa ianya adalah wajib bagi anda untuk
              memberikan Data Peribadi anda untuk menyertai Program ini dan
              Sanofi dan/atau syarikat-syarikat bersekutu perlu memproses Data
              Peribadi anda untuk Tujuan tersebut, di mana tanpanya anda tidak
              dapat menyertai Program ini. Notis ini dikeluarkan dalam kedua-dua
              Bahasa Malaysia dan Inggeris. Di dalam keadaan mana terdapat
              ketidakseragaman, versi Bahasa Inggeris Notis ini akan digunapakai
              sebagai dokumen pertama.{' '}
            </em>
          </Typography>
        </Grid>
        <Grid item>
          <Typography paragraph variant="body2" component="div">
            <h3>CONSENT</h3>
            By signing this consent form, I am declaring that that I have read,
            understood and agreed to the terms above and I further agree,
            represent and warrant that:
            <ul>
              <li>
                I agree to participate in this Program and I understand that
                participation in this Program is voluntary and I may withdraw
                from this Program at any time without affecting my patient’s
                healthcare treatment.
              </li>
              <li>
                I will explain the purpose and terms of this Program to my
                participating patients and I have answered all their questions
                to his/her satisfaction.
              </li>
              <li>
                I agree that I will only refer patients who have been prescribed
                the Product by me, and who fulfil all the eligibility criteria
                of this Program as detailed above. This Program has not and will
                not influence and is not intended to influence my decision to
                treat my patients.
              </li>
              <li>
                I agree that I will not be compensated and will not seek
                compensation for participating in this Program.
              </li>
              <li>
                I agree to comply strictly with the redemption and
                pharmacovigilance processes provided above.{' '}
              </li>
              <li>
                I confirm that all Personal Data that I have provided are all
                true, up-to-date and accurate. Should there be any changes to
                any of my Personal Data, I shall notify Sanofi immediately.
              </li>
              <li>
                I understand the terms of the Privacy Notice and consent to the
                processing of my Personal Data in the manner as described above
                and the transfer of my personal data as may be necessary
                relating to any of the above stated Purposes to the maximum
                extent required. Saya memahami terma-terma Notis Privasi dan
                membenarkan pemprosesan Data Peribadi saya secara mana yang
                dinyatakan di atas dan pemindahan Data Peribadi yang mungkin
                diperlu berkaitan dengan Tujuan dinyatakan di atas ke tahap
                maksimum diperlukan.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Content.defaultProps = {
  isLoggedIn: false,
};

Content.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default Content;
