import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { settingsConfig } from 'common/constants';

export const getSettingsField = createAsyncThunk(
  'settings/getSettingsField',
  async (_, { getState }) => {
    const settings = getState();
    return settings;
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    settings: settingsConfig,
  },
  reducers: {},
  extraReducers: {},
});

export default settingsSlice.reducer;
