import { useLocation } from 'react-router-dom';
import { Auth } from 'service/auth.service';
import { isNull } from 'lodash';

const useDrawer = () => {
  const { pathname } = useLocation();
  switch (pathname) {
    case '/patient-screening':
    case '/program-information':
    case '/public/terms-and-conditions':
    case '/public/privacy-notice':
    case '/public/physician-consent-form':
    case '/verification':
    case '/login-redirect':
    case '/end-redirect':
    case '/login':
      return false;
    default:
      return !isNull(Auth.accessToken);
  }
};

export default useDrawer;
