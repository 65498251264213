import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const AboutApplicationBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        2. Mengenai Laman web ini
      </Typography>
      <Typography paragraph className={styles.content}>
        Laman web ini bertujuan untuk membolehkan anda membuat pendaftaran
        Program ini bagi pesakit yang memenuhi syarat dan memantau
        aktiviti-aktiviti pesakit anda yang menyertai Program
        (&quot;Pesakit&quot;), termasuk menerima bacaan tekanan darah Pesakit
        anda.
      </Typography>
    </Grid>
  );
};

export default AboutApplicationBlockBahasa;
