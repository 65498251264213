import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import logo from 'assets/png/popover-logo-2.png';
import { useStyles } from './styles';

const PopOverItem2 = () => {
  const styles = useStyles();
  return (
    <div>
      <Grid item className={styles.titleContainer}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <Typography className={styles.title}>
              {'WHO IS'}
              <br />
              {'ELIGIBLE?'}
            </Typography>
          </Grid>
          <Grid item>
            <img src={logo} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.titleContainer}>
        <Grid item>
          <Typography className={styles.content}>
            Patients who are currently prescribed with Aprovasc
            (Irbesartan/amlodipine) for hypertension and assessed by you, as
            their treating physician, to be suitable to enter this program to
            improve hypertension control and do not own home blood pressure
            monitoring device.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PopOverItem2;
