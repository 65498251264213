import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const ThirdPartyBlockBahasa = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        11. Laman dan Perkhidmatan Pihak Ketiga
      </Typography>
      <Typography paragraph className={styles.content}>
        Laman web ini mungkin mengandungi pautan ke laman web, aplikasi dan
        perkhidmatan dalam talian lain yang dikendalikan oleh pihak ketiga yang
        berada di luar kawalan kami. Kami tidak bertanggungjawab untuk
        pengumpulan, penggunaan, dan pendedahan Data Peribadi anda di laman web
        tersebut dan perkhidmatan dalam talian lain oleh pihak ketiga tersebut.
        Kami menggalakkan anda untuk mengkaji semula dasar privasi setiap laman
        web dan perkhidmatan dalam talian lain yang anda lawati.
      </Typography>
    </Grid>
  );
};

export default ThirdPartyBlockBahasa;
