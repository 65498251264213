import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';
import { useDarkThemeStyles } from 'components/StaticPage/stylesDarkTheme';
import { useSelector } from 'react-redux';
import { selectConfig } from 'selectors/resources';
import moment from 'moment';

const ProgramBlock = ({ darkTheme }) => {
  const styles = useStyles();
  const stylesDarkTheme = useDarkThemeStyles();
  const { startDate, endDate } = useSelector(selectConfig);
  return (
    <Grid container direction="column">
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.title : styles.title}
      >
        PROGRAM
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.subtitle : styles.subtitle}
      >
        <i>Patient eligibility</i>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        To qualify for the Program, patient must meet ALL the eligibility
        criteria below:
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
        component="span"
      >
        <ul>
          <li className={styles.list}>
            Patients require treatment and are prescribed with the Product by
            the treating physician
          </li>
          <li className={styles.list}>
            Patients are screened and qualified by the treating physician for
            non-adherence based on H-MAI-9 medication adherence score of &lt;
            80%
          </li>
          <li className={styles.list}>
            Patients do not own a home blood pressure monitoring device
          </li>
        </ul>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.subtitle : styles.subtitle}
      >
        <i>How patients can participate</i>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        If your patients fulfil all the above eligibility criteria, you and your
        patients may apply to participate in this Program by accepting the terms
        and conditions in this form. Your acceptance will be retained digitally
        by mClinica Pte.Ltd. (“PROVIDER”), the independent third-party service
        provider engaged by Sanofi to manage this Program.
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.subtitle : styles.subtitle}
      >
        <i>How the patients can stay active in the program</i>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
        component="span"
      >
        <ol type="a">
          <li className={styles.list}>
            Patient must download the app upon being pre-qualified by the
            treating physician within 30 days from the treating physician&apos;s
            assessment
          </li>
          <li className={styles.list}>
            Once downloaded, patient must perform any transactions of purchase
            and redemption of Aprovasc (Irbesartan/amlodipine) within the
            duration (less than 30 days after last transaction for 1 box
            purchase or less than 60 days after last transaction for concurrent
            2 box purchases or less than 120 days after last transaction for
            concurrent 3 box purchase)
          </li>
          <li className={styles.list}>
            Patient must perform blood pressure recording of at least average 3
            times/week in 30 days
          </li>
        </ol>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.subtitle : styles.subtitle}
      >
        <i>What can the patient redeem</i>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        Upon your enrolment in the Program and your authorization to recruit the
        patient, you can entitle the patient to:
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
        component="span"
      >
        <ol type="a">
          <li className={styles.list}>
            redeem one (1) box of Aprovasc (Irbesartan/amlodipine) with every
            purchase of up to three (3) boxes from your clinic
          </li>
          <li className={styles.list}>
            a maximum of 3 boxes can be purchased per transaction.
          </li>
          <li className={styles.list}>
            a maximum of 3 boxes can be purchased per cycle, of which it is
            defined as period of 30 days per cycle inclusive of date of purchase
          </li>
          <li className={styles.list}>
            One or two boxes of Aprovasc (Irbesartan/amlodipine) can be
            purchased at a single transaction and accumulated to 3 boxes for the
            redemption of one (1) box.
          </li>
          <li className={styles.list}>
            Redeem one (1) home blood pressure monitor (Device) upon program
            enrolment. Blood pressure monitor (Device) will be delivered to the
            patient address registered in the app. No changes of delivery
            location can be made once Device has been sent out to the patient’s
            registered address.
          </li>
        </ol>
      </Typography>

      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        For the avoidance of doubt, the prescribed dosage strength of the
        redeemed complimentary Product, must be the same as the prescribed
        dosage strength of the same Product purchased.
      </Typography>

      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.subtitle : styles.subtitle}
      >
        <i>How to redeem</i>
      </Typography>

      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
        component="span"
      >
        <ul>
          <li className={styles.list}>
            In order to redeem the complimentary Product, the patient must
            upload the itemized receipt(s) evidencing the purchase of the
            relevant Product from your clinic (“Proof of Purchase”) into the
            InControl App.
          </li>
          <li className={styles.list}>
            The purchase of the Product may be in a single transaction three (3)
            boxes, in two (2) separate transactions of one (1) box and two (2)
            boxes or in three (3) separate transactions of one (1) box each.
          </li>
          <li className={styles.list}>
            If the purchases are separate transactions, the relevant Proof of
            Purchase evidencing all transactions shall be provided by you to the
            patient.
          </li>
          <li className={styles.list}>
            Proof of Purchase must be provided by you and submitted to the
            patient and Sanofi reserves the right to forfeit any redemption that
            is not made within the program duration.
          </li>
          <li className={styles.list}>
            Redemption of the complimentary Product under the Program is subject
            to PROVIDER prior approval and upon approval by PROVIDER, the
            complimentary Product will be collected at your clinic by the
            patient.
          </li>
        </ul>
      </Typography>

      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.subtitle : styles.subtitle}
      >
        <i>Duration</i>
      </Typography>
      <Typography
        paragraph
        className={darkTheme ? stylesDarkTheme.content : styles.content}
      >
        This Program will run from {moment(startDate).format('Do MMMM YYYY')}{' '}
        till {moment(endDate).format('Do MMMM YYYY')} (both dates inclusive)
        (“Term”). Notwithstanding the foregoing, Sanofi reserves the right to
        vary the terms and conditions of this Program and/or extend, modify,
        suspend or terminate this Program at any time, without giving any
        reason, prior notice and/or assuming any liability to you or any party.
        Sanofi will inform you of any updates to the Program. If at any time you
        wish to withdraw from this Program, please indicate through the app of
        your notice to leave.
      </Typography>
    </Grid>
  );
};

ProgramBlock.defaultProps = {
  darkTheme: false,
};

ProgramBlock.propTypes = {
  darkTheme: PropTypes.bool,
};

export default ProgramBlock;
