import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import logo from 'assets/png/popover-logo-1.png';
import { useStyles } from './styles';

const PopOverItem1 = () => {
  const styles = useStyles();
  return (
    <div>
      <Grid item className={styles.titleContainer}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <Typography className={styles.title}>
              {'WHAT IS'}
              <br />
              {'IN-CONTROL?'}
            </Typography>
          </Grid>
          <Grid item>
            <img src={logo} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.titleContainer}>
        <Grid item>
          <Typography className={styles.content}>
            In-Control Aprovasc® (Irbesartan/amlodipine) Patient Program is
            sponsored by Sanofi-Aventis (Malaysia) Sdn. Bhd for only patients in
            Malaysia who are prescribed to use Aprovasc® (Irbesartan/amlodipine)
            (150 mg/5 mg, 150 mg/10 mg, 300 mg/5 mg and 300 mg/10 mg) by their
            treating physician to ensure blood pressure is well controlled.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PopOverItem1;
