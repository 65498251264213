import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/authSlice';
import { Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import PageLayout from 'components/PageLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VerificationImage from './components/VerificationImage';
import VerificationForm from './components/VerificationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    paddingLeft: '4em',
    paddingRight: '4em',
    paddingTop: '2em',
    paddingBottom: '4em',
    [theme.breakpoints.only('xs')]: {
      padding: '1em',
    },
  },
}));

const Verification = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBack = () => {
    dispatch(logout());
  };
  return (
    <PageLayout>
      <Grid container direction="column" className={styles.root}>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <IconButton aria-label="back" onClick={handleBack}>
              <ArrowBackIcon color="secondary" />
            </IconButton>
            <Typography color="secondary">Back</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={smDown ? 'column' : 'row'}
          alignItems="center"
          justify="center"
          xs
          sm
          md
          lg
          xl
        >
          <VerificationImage />
          <VerificationForm />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Verification;
