import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import logo from 'assets/png/popover-logo-4.png';
import { useStyles } from './styles';

const PopOverItem4 = () => {
  const styles = useStyles();
  return (
    <div>
      <Grid item className={styles.titleContainer}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <Typography className={styles.title}>
              {'HOW CAN PATIENTS'}
              <br />
              {'STAY ACTIVE IN'}
              <br />
              {'THE PROGRAM?'}
            </Typography>
          </Grid>
          <Grid item>
            <img src={logo} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.titleContainer}>
        <Grid item>
          <Typography className={styles.content}>
            Patients must record BP (blood pressure) monitoring for at least 3
            times per week AND purchase Aprovasc® (Irbesartan/amlodipine) 3
            boxes in single or separate receipts within 3 months.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PopOverItem4;
