import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { verifyUser, resendVerification } from 'store/userSlice';

import PinInput from 'react-pin-input';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '4em',
    },
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },
  resendText: {
    fontWeight: 700,
    fontSize: '0.7rem',
    color: theme.palette.primary.dark,
  },
  resendButton: {
    paddingLeft: '5em',
    paddingRight: '5em',
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  verifyButton: {
    paddingLeft: '5em',
    paddingRight: '5em',
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3em',
      paddingRight: '3em',
      paddingTop: '0.75em',
      paddingBottom: '0.75em',
    },
  },
  thin: {
    fontWeight: 300,
  },
}));

const VerificationForm = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState('');
  const [verifyDisabled, setVerifyDisabled] = useState(true);

  const onVerifyHandler = () => {
    dispatch(verifyUser({ verificationCode })).then((data) => {
      if (data.payload) {
        history.replace('/home');
      }
    });
  };

  const onResendHandler = () => {
    dispatch(resendVerification());
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      spacing={2}
      className={styles.container}
      md
      lg
      xl
    >
      <Grid item>
        <Typography variant="h6" className={styles.title}>
          Register with In-Control
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={styles.thin}>
          Please check your email for verification code
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        spacing={2}
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item>
          <Typography variant="caption" className={styles.thin}>
            Enter Code
          </Typography>
        </Grid>
        <Grid item>
          <PinInput
            initialValue={verificationCode}
            onChange={(value) => {
              setVerifyDisabled(!verificationCode < 8);
              setVerificationCode(value);
            }}
            onComplete={(value) => {
              setVerificationCode(value);
              setVerifyDisabled(false);
            }}
            length={8}
            type="alphanumeric"
            regexCriteria={/^[a-zA-Z]+$/}
            style={{
              display: 'table',
              margin: '0 auto',
            }}
            inputStyle={{
              width: '26px',
              border: 'none',
              borderBottom: '1px solid #6F2B90',
              backgroundColor: '#E5E8F0',
            }}
          />
        </Grid>
        <Grid item>
          <Button
            className={styles.resendButton}
            disableRipple
            onClick={onResendHandler}
          >
            <Typography variant="subtitle2" className={styles.resendText}>
              Resend Code
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          className={styles.verifyButton}
          disabled={verifyDisabled}
          onClick={onVerifyHandler}
        >
          Verify Email Address
        </Button>
      </Grid>
    </Grid>
  );
};

export default VerificationForm;
