import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFilter } from 'store/patientSlice';
import { selectSearchFilter } from 'selectors/patient';
import PropTypes from 'prop-types';
import {
  fade,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 240,
    height: 40,
    backgroundColor: 'transparent',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  search: {
    display: 'flex',
    flexShrink: 0,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 'auto',
    width: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginRight: '0.5em',
      marginLeft: 'auto',
    },
  },
  searchIcon: {
    padding: 10,
  },
  clearIcon: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  searchIconContained: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '12ch',
    '&:focus': {
      width: '20ch',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

const SearchBar = ({ contained }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const searchFilter = useSelector(selectSearchFilter);
  const [value, setValue] = useState('');

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    dispatch(setSearchFilter(e.target.value));
  };

  const onClearHandler = () => {
    setValue('');
    dispatch(setSearchFilter(''));
  };

  useEffect(() => {
    setValue(searchFilter);
  }, [searchFilter]);

  return contained ? (
    <Paper className={styles.searchContainer}>
      <Grid item container direction="row">
        <IconButton className={styles.searchIcon} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          placeholder="Search…"
          value={value}
          onChange={onChangeHandler}
          className={styles.searchInput}
        />
        <IconButton
          className={styles.clearIcon}
          aria-label="clear"
          onClick={onClearHandler}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Paper>
  ) : (
    <div className={styles.search}>
      <Hidden xsDown>
        <div className={styles.searchIconContained}>
          <SearchIcon />
        </div>
      </Hidden>
      <InputBase
        placeholder="Search…"
        value={value}
        onChange={onChangeHandler}
        classes={{
          root: styles.inputRoot,
          input: styles.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
      <IconButton
        className={styles.clearIcon}
        aria-label="clear"
        onClick={onClearHandler}
      >
        <CloseIcon style={{ fill: 'white' }} />
      </IconButton>
    </div>
  );
};

SearchBar.defaultProps = {
  contained: false,
};

SearchBar.propTypes = {
  contained: PropTypes.bool,
};

export default SearchBar;
