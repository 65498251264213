import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ErrorMessage } from 'formik';
import { get } from 'lodash';

const ConnectSelectField = ({ fieldOptions, setFieldValue, field }) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const { label, name, hint, isRequired, fieldTypeOptions } = fieldOptions;

  const getValue = () => {
    if (isOtherSelected) {
      return { label: 'Others', value: 'others' };
    }

    const value = get(field, 'value', null);
    if (value) {
      return { label: value, value };
    }

    return null;
  };

  const handleChange = (event, newValue) => {
    setFieldValue(field.name, newValue && newValue.value);
    if (newValue && newValue.value.toLowerCase() !== 'others') {
      setIsOtherSelected(false);
    } else {
      setIsOtherSelected(true);
    }
  };

  const handleSpecificText = (e) => {
    setFieldValue(`${field.name}_specific`, e.target.value);
  };

  return (
    <Grid container spacing={4} key={name}>
      <Grid item sm={12} xs={12}>
        <Autocomplete
          value={getValue()}
          options={get(fieldTypeOptions, 'selectDropDownOptions', [])}
          getOptionLabel={(option) => option && option.label}
          onChange={handleChange}
          disableClearable
          disablePortal
          disabled={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${label}${isRequired ? ' *' : '(optional)'}`}
              name={isOtherSelected ? 'select-field' : name}
              onBlur={field.onBlur}
            />
          )}
        />

        {isOtherSelected && (
          <TextField
            type="text"
            label={`Enter ${label}`}
            onChange={handleSpecificText}
            disabled={false}
            helperText={hint}
          />
        )}

        {hint && (
          <Typography variant="caption" display="block" gutterBottom>
            {hint}
          </Typography>
        )}

        <ErrorMessage
          name={name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="error"
              >
                {message}
              </Typography>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

ConnectSelectField.propTypes = {
  fieldOptions: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    hint: PropTypes.string,
    isRequired: PropTypes.bool,
    fieldTypeOptions: PropTypes.object,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

export default ConnectSelectField;
