import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import logo from 'assets/png/popover-logo-3.png';
import { useStyles } from './styles';

const PopOverItem3 = () => {
  const styles = useStyles();
  return (
    <div>
      <Grid item className={styles.titleContainer}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <Typography className={styles.title}>
              {'WHAT ARE PATIENTS'}
              <br />
              {'ENTITLED?'}
            </Typography>
          </Grid>
          <Grid item>
            <img src={logo} alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.titleContainer}>
        <Grid item>
          <Typography className={styles.content}>
            Patient support consisting of BP (blood pressure) recoding calendar
            and educational material and patient assistance in the form of:
          </Typography>
          <Typography className={styles.content}>
            <ol type="i">
              <li>Home blood pressure monitoring device</li>
              <li>
                Complimentary Aprovasc (Irbesartan/amlodipine) with term &amp;
                conditions applied
              </li>
            </ol>
          </Typography>
        </Grid>
        <Grid container item justify="flex-end">
          <Typography className={styles.content}>*T&amp;C</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PopOverItem3;
