import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from 'components/StaticPage/styles';

const PersonalDataBlock = () => {
  const styles = useStyles();
  return (
    <Grid container direction="column">
      <Typography paragraph className={styles.title}>
        3. What Personal Data may be Processed
      </Typography>
      <Typography paragraph className={styles.content}>
        Depending on your interaction with us, we may Process the following
        categories of Personal Data:
      </Typography>
      <Typography paragraph className={styles.content} component="span">
        <ol type="a">
          <li className={styles.list}>
            Identification and contact information: before you can start using
            the Site, you will be asked to include your email address, name,
            clinic/hospital name, DKSH account number, create a password and
            details of Product purchased from you.
          </li>
          <li className={styles.list}>
            Messages: any information or data you may send to mClinica and/or
            your treating physician in your requests, enquiries and/or
            correspondences by using this Site, by email, phone, fax, post or by
            any other means.
          </li>
        </ol>
      </Typography>
      <Typography paragraph className={styles.content}>
        Where indicated, it is obligatory to provide your Personal Data to us to
        enable us to process your data in relation to specific Purposes (defined
        hereunder). Should you decline to provide such obligatory Personal Data,
        we may not be able to engage with you, including to communicate with you
        or provide you with the information you require. If you delete or choose
        not to allow the use of cookies, some areas of the Site may not function
        properly or be accessible. Refusal to provide the obligatory Personal
        Data may also disqualify you from participating in the Program and/or
        adversely affect your ability to enjoy and/or participate fully in,
        amongst others, the activities hosted on the Site.
      </Typography>
    </Grid>
  );
};

export default PersonalDataBlock;
