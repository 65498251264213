import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Hidden,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { setSelectedPatient } from 'store/patientSlice';
import { getPatientInfo } from 'pages/Home/components/helper';

import AddIcon from '@material-ui/icons/Add';
import updateIcon from 'assets/svg/ic-patient-update.svg';
import personAddIcon from 'assets/svg/patient-add-24px.svg';
import AddPatientForm from './components/AddPatientForm';

const useStyles = makeStyles((theme) => ({
  addPatientButton: {
    paddingRight: '1.7em',
    marginTop: '1.2em',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  formContainer: {
    height: 'min-content',
    [theme.breakpoints.only('sm')]: {
      width: '20em',
    },
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
    },
  },
  actionsContainer: {
    marginRight: '2em',
    marginBottom: '1em',
    marginTop: '1em',
  },
  iconAction: {
    width: '24px',
    height: 'auto',
    padding: 0,
    marginRight: '8px',
  },
}));

const AddPatient = ({ editMode, patientId, patientList }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (editMode) {
      dispatch(setSelectedPatient(getPatientInfo(patientId, patientList)));
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {editMode ? (
        <IconButton
          component="span"
          className={styles.iconAction}
          onClick={handleClickOpen}
        >
          <img src={updateIcon} alt="" />
        </IconButton>
      ) : (
        <React.Fragment>
          <Hidden xsDown>
            <Button
              variant="contained"
              color="secondary"
              className={styles.addPatientButton}
              onClick={handleClickOpen}
            >
              <AddIcon fontSize="small" style={{ marginRight: '0.25em' }} />
              Add Patient
            </Button>
          </Hidden>
          <Hidden smUp>
            <IconButton component="span" onClick={handleClickOpen}>
              <img src={personAddIcon} alt="Add" />
            </IconButton>
          </Hidden>
        </React.Fragment>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">
          {editMode ? 'Edit Patient Details' : 'Add Patient Details'}
        </DialogTitle>
        <Divider />
        <DialogContent className={styles.formContainer}>
          <AddPatientForm handleClose={handleClose} editMode={editMode} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddPatient.defaultProps = {
  editMode: false,
  patientId: '',
  patientList: {},
};

AddPatient.propTypes = {
  editMode: PropTypes.bool,
  patientId: PropTypes.string,
  patientList: PropTypes.any,
};

export default AddPatient;
