import React, { useState } from 'react';
// import NumberFormat from 'react-number-format';
import { get } from 'lodash';
import {
  Grid,
  TextField as TextInput,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { removeEmojis } from 'common/helpers';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    formControl: {
      width: '100%',
      fontSize: 14,
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
      fontStyle: 'italic',
    },
    notchedOutline: {
      borderColor: palette.primary.main,
    },
    label: {
      color: palette.primary.main,
    },
    labelFit: {
      color: palette.primary.main,
      fontSize: 11,
      paddingRight: 20,
      transform: 'translate(14px, 18px) scale(1)',
    },
  })
);

const ConnectNumberField = ({ fieldOptions, setFieldValue, field }) => {
  const style = useStyles();

  const {
    name,
    label,
    placeholder,
    isRequired,
    hint,
    fieldTypeOptions,
  } = fieldOptions;

  const [textValue, setTextValue] = useState('');
  const [prefix] = useState(fieldOptions.prefix);

  const handleChange = (input) => {
    const value = removeEmojis(
      input
        .replace(/\D/, '')
        .substring(0, fieldTypeOptions.characterLimit)
        .trimStart()
    );
    setTextValue(value);
    setFieldValue(field.name, value);
  };

  return (
    <Grid container spacing={4} key={name}>
      <Grid item sm={12} xs={12}>
        <TextInput
          id={name}
          name={name}
          type="text"
          value={textValue && !textValue.length ? prefix : textValue}
          label={
            label ? `${label}${isRequired ? ' *' : ' (optional)'}` : placeholder
          }
          onChange={(e) => handleChange(e.target.value)}
          onBlur={field.onBlur}
          disabled={false}
          style={{ notchedOutline: style.notchedOutline }}
          onKeyDown={(e) => e.key === 69 || e.key === 190}
          onInput={
            get(fieldOptions, 'shouldUppercase', false)
              ? (e) => {
                  const start = e.target.selectionStart;
                  const end = e.target.selectionEnd;
                  e.target.value = e.target.value.toUpperCase();
                  e.target.setSelectionRange(start, end);
                }
              : null
          }
          InputProps={{
            style: { notchedOutline: style.notchedOutline },
            autoComplete: 'off',
            maxLength: fieldTypeOptions?.characterLimit,
          }}
          InputLabelProps={{
            className: label && label.length > 50 ? style.labelFit : 'inherit',
          }}
          helperText={hint}
          labelwidth={label ? label.length * 10 : placeholder.length * 10}
          fullWidth
        />
        <ErrorMessage
          name={name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="primary"
              >
                {message}
              </Typography>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

ConnectNumberField.propTypes = {
  fieldOptions: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    fieldTypeOptions: PropTypes.object,
    commaSeparator: PropTypes.bool,
    decimalScale: PropTypes.number,
    characterLimit: PropTypes.number,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

export default ConnectNumberField;
