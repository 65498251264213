import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPatient } from 'store/patientSlice';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  Grid,
} from '@material-ui/core';
import { selectPatientList } from 'selectors/patient';
import { useHistory } from 'react-router';

import AddPatient from 'components/AddPatient';
import rescreenIcon from 'assets/svg/ic-patient-rescreen.svg';
import { rescreenTooltipMessage } from 'common/constants';
import { getPatientInfo, getStatus, isAbleToScreen } from './helper';
import LightTooltip from './LightTooltip';

const useStyles = makeStyles((theme) => ({
  screen: {
    textTransform: 'none',
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconAction: {
    width: '24px',
    height: 'auto',
    padding: 0,
    marginRight: '8px',
  },
  placeHolder: {
    color: '#33333375',
    fontWeight: 500,
    textAlign: 'center',
  },
}));

const PatientTableBody = ({ rows, rowsPerPage, page }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const tableCellHeight = 57;
  const patientList = useSelector(selectPatientList);
  const showTable = Object.keys(patientList).length > 0;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleScreenButton = (patientId) => {
    dispatch(setSelectedPatient(getPatientInfo(patientId, patientList)));
    history.push('/patient-screening');
  };

  return (
    <React.Fragment>
      {showTable ? (
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow key={row.id} hover>
              <TableCell>{row.id.substr(0, 5)}</TableCell>
              <TableCell>{row.firstName}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.mobileNumber}</TableCell>
              <TableCell>{getStatus(row.status)}</TableCell>
              <TableCell>
                {row.status === 'pending' ? (
                  <Button
                    size="medium"
                    disableRipple
                    className={styles.screen}
                    onClick={() => handleScreenButton(row.id)}
                  >
                    Screen
                  </Button>
                ) : (
                  <Grid container direction="row">
                    <AddPatient
                      editMode
                      patientId={row.id}
                      patientList={patientList}
                    />
                    {row.metadata?.hasBpDevice ||
                    !isAbleToScreen(row.status) ? null : (
                      <LightTooltip
                        title={rescreenTooltipMessage}
                        placement="bottom-end"
                      >
                        <IconButton
                          component="span"
                          className={styles.iconAction}
                          onClick={() => handleScreenButton(row.id)}
                        >
                          <img src={rescreenIcon} alt="" />
                        </IconButton>
                      </LightTooltip>
                    )}
                  </Grid>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow
            style={{
              height: emptyRows
                ? tableCellHeight * emptyRows
                : tableCellHeight * 10,
            }}
          >
            <TableCell colSpan={6}>
              <Typography variant="h6" className={styles.placeHolder}>
                No Entries
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </React.Fragment>
  );
};

PatientTableBody.propTypes = {
  rows: PropTypes.any.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default PatientTableBody;
